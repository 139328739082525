import { get } from "./axios";

const SupportLocationUrls = {
  cities: (uf: string) => `/support_points/uf/${uf}`,
  supportPoints: (uf: string, cityCode: string) =>
    `/support_points/city/${uf}/${cityCode}`
};

const SupportLocationService = {
  listCities(uf: string) {
    return get(SupportLocationUrls.cities(uf));
  },
  supportPoints(uf: string, cityCode: string) {
    return get(SupportLocationUrls.supportPoints(uf, cityCode));
  }
};

export default SupportLocationService;
