import clsx from "clsx";

import ContainerMainProps from "./types";

import "./styles.scss";

const ContainerMain = (props: ContainerMainProps): JSX.Element => {
  const { children, full, className, as: _as, withContainer } = props;
  const As = _as ?? "div";
  return (
    <As
      className={clsx(`container-main ${className}`, {
        full
      })}
    >
      {withContainer ? (
        <div className="main-children-container">{children}</div>
      ) : (
        children
      )}
    </As>
  );
};

export default ContainerMain;
