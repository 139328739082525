import Locale from "locale";
import notify from "components/Toast";

const ErrorHelper = {
  parseErrorFromSerializer(data: any) {
    let message = "";
    Object.values(data).forEach((serializerMessages: any) => {
      if (serializerMessages) {
        Object.keys(serializerMessages).forEach((errorName: any) => {
          message += `${errorName}: ${serializerMessages[errorName]}\n`;
        });
      }
    });
    return message ?? Locale.errors.default;
  },

  getErrorMessage(error: { response: any }) {
    const { response } = error;
    if (!response) {
      return Locale.errors.default;
    }
    if (response.status === 0) {
      return Locale.errors.connection;
    }
    if (response.data?.message) {
      return response.data.message;
    }
    if (response.data?.detail) {
      return response.data.detail;
    }
    if (response.data && typeof response.data === "string") {
      return response.data;
    }
    if (response.status === 400 && Object.values(response.data)) {
      return ErrorHelper.parseErrorFromSerializer(response.data);
    }
    return Locale.errors.default;
  },

  notifyError(error: { response: any }) {
    const errorMessage = ErrorHelper.getErrorMessage(error);
    notify(errorMessage);
  }
};

export default ErrorHelper;
