import { Link, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";

import { Image, Button, Summary } from "components";

import Locale from "locale";
import { AuthContext } from "contexts/auth";

import LogoClinica from "assets/logo-clinica.png";

import "./styles.scss";
import HeaderProps from "./types";

const Header = (props: HeaderProps) => {
  const { cart, notification, formik } = props;
  const navigate = useNavigate();
  const { user, authenticated, logout } = useContext(AuthContext);
  const [showSummary, setShowSummary] = useState(false);

  const handleLogout = () => {
    logout();
  };

  return (
    <>
      {showSummary ? (
        <Summary
          isHeader
          formik={formik}
          onCloseSummary={() => setShowSummary(false)}
        />
      ) : (
        <header className="header">
          <div className="header-left">
            <Link to="/">
              <Image src={LogoClinica} alt="Logo" />
            </Link>
          </div>

          <div className="header-right">
            {cart && (
              <>
                <Button
                  icon="cart"
                  variant="tertiary"
                  onClick={() => {
                    setShowSummary(true);
                  }}
                />
                {notification && <div className="cart-notification" />}
              </>
            )}
            {authenticated ? (
              <div className="container-menu-dropdown">
                <Button icon="user" variant="tertiary" />
                <div className="menu-dropdown">
                  <Button
                    type="button"
                    title="Sair"
                    variant="tertiary"
                    onClick={handleLogout}
                  />
                </div>
                <div className="user-name">{user?.name}</div>
              </div>
            ) : (
              <Button
                title={Locale.actions.login}
                size="small"
                onClick={() => {
                  navigate("/login");
                }}
              />
            )}
          </div>
        </header>
      )}
    </>
  );
};

export default Header;
