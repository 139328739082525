import { useEffect } from "react";
import Locale from "locale";

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export const outsideClick = (
  ref: React.RefObject<HTMLInputElement>,
  callback: () => void
) => {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

export const formatCurrency = (money: number) =>
  Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
  }).format(money);

export const formatCurrencyIfNotSponsored = (
  money: number,
  isSponsored: boolean
) => (isSponsored ? formatCurrency(0) : formatCurrency(money));

export const getCartAmount = (formik: any) => {
  let amount = formik.values.summary.items.reduce(
    (ac: any, obj: { value: any }) => ac + obj.value,
    0
  );
  amount += formik.values.summary.shipping;
  return amount;
};

export const cartSum = (formik: any) => {
  let amount = formik.values.summary.items.reduce(
    (ac: any, obj: { value: any }) => ac + obj.value,
    0
  );
  amount += formik.values.summary.shipping;
  formik.setValues((values: any) => ({
    ...values,
    billing: {
      ...values.billing
    },
    summary: {
      ...values.summary,
      amount
    }
  }));
};

const getCartItemCount = (
  animalCount: string,
  tankCount: string,
  analyses: object
) => {
  const distinctTankAnalyses = Object.values(analyses).filter(
    v => v === true
  ).length;
  const animal = animalCount ? parseInt(animalCount, 10) : 0;
  const tank = tankCount ? parseInt(tankCount, 10) * distinctTankAnalyses : 0;
  return animal + tank;
};

export const addItemsCart = (formik: any) => {
  const totalCount = getCartItemCount(
    formik.values.analysis.animalCount,
    formik.values.analysis.tankCount,
    formik.values.analysis.analyses
  );

  const labels = [];
  const subitems = [];
  if (formik.values.analysis.animalCount > 0) {
    labels.push(
      `${formik.values.analysis.animalCount} frasco(s) para análise de vacas`
    );
    subitems.push(
      `- ${formik.values.analysis.animalCount} frasco(s) CCS, Composição, NU, P-Check ou PCR`
    );
  }
  if (formik.values.analysis.tankCount > 0) {
    if (formik.values.analysis.analyses.cbt) {
      labels.push(
        `${formik.values.analysis.tankCount} frasco(s) para análise de tanque (CBT)`
      );
      subitems.push(`- ${formik.values.analysis.tankCount} frasco(s) CBT`);
    }
    if (formik.values.analysis.analyses.atb) {
      labels.push(
        `${formik.values.analysis.tankCount} frasco(s) para análise de tanque (ATB)`
      );
      subitems.push(`- ${formik.values.analysis.tankCount} frasco(s) ATB`);
    }
  }
  const label = labels.join(", ");
  const { summary } = formik.values;
  summary.items = [];
  summary.items.push({
    label,
    subitems,
    value: getAnalysesPrice(totalCount)
  });
  summary.amount = getCartAmount(formik);

  formik.setValues((values: any) => ({
    ...values,
    summary
  }));
};

export const getAnalysesPrice = (amount: number) => {
  if (amount === 0) {
    return 0;
  }
  if (amount < 35) {
    return 50;
  }
  if (amount > 80) {
    return 200;
  }
  return 100;
};

export const getAnalyses = (analyses: any) => {
  const arr: string[] = [];
  Object.keys(analyses)
    .filter(k => analyses[k])
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .map((item: string) => arr.push(Locale.analyses[item].title.toUpperCase()));
  return arr.join(", ");
};

export const getAnalysesFromContext = (analyses: any) => {
  const arr: { analysis_type: string }[] = [];
  Object.keys(analyses)
    .filter(k => analyses[k])
    .map(item => arr.push({ analysis_type: String(item).toUpperCase() }));
  return arr;
};

export const formatAnalysisLabelRequest = (analysisLabel: string) =>
  Locale.actions.requestVialsFor.replace("{}", analysisLabel);

export const shouldShowShipping = (formik: any) =>
  !!formik.values?.delivery?.shippingMethod;

export const getAssociationObjectByValue = (
  value: string,
  associationOptions: any[]
) => associationOptions.filter(item => item.value === value)[0];
