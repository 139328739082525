const ObscureHelper = {
  obscureEmail(email: string) {
    const [name, domain] = email.split("@");
    return `${name[0]}${new Array(name.length).join("*")}@${domain}`;
  },
  obscurePhone(phone: string) {
    if (phone.length === 15) {
      const first = phone.slice(0, 6);
      const last = phone.slice(13);
      return `${first}${new Array(5).join("*")}-${new Array(3).join(
        "*"
      )}${last}`;
    }
    return phone;
  }
};

export default ObscureHelper;
