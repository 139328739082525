import {
  Button,
  Input,
  PasswordValidationChecklist,
  Template,
  WrapperForm
} from "components";
import { useFormik } from "formik";
import Locale from "locale";
import * as Yup from "yup";
import Service from "services";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import notify from "components/Toast";
import { ErrorHelper, PasswordHelper } from "helpers";
import "./styles.scss";

const RegisterPassword = () => {
  const navigate = useNavigate();

  const initialValues = {
    password: "",
    confirmPassword: ""
  };

  const { uidb64, token } = useParams();

  const validationSchema = Yup.object().shape({
    password: PasswordHelper.passwordValidationSchema,
    confirmPassword: PasswordHelper.confirmPasswordValidationSchema
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: values => {
      Service.changePassword({
        password: values.password,
        password_confirm: values.confirmPassword,
        uidb64,
        token
      })
        .then(() => {
          navigate("/");
          notify(Locale.notifications.passwordSaved);
        })
        .catch((error: any) => ErrorHelper.notifyError(error));
    }
  });

  useEffect(() => {
    (() => formik.validateForm())();
  }, []);

  return (
    <Template>
      <WrapperForm full className="flex-justify--center">
        <div className="stack--xl register-password-container">
          <form className="stack--md" onSubmit={formik.handleSubmit}>
            <div className="heading-1">
              {Locale.pages.registerPassword.title}
            </div>
            <Input
              label={Locale.fields.user.password}
              name="password"
              type="password"
              value={formik.values.password}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.touched.password && formik.errors.password}
            />
            <Input
              label={Locale.fields.user.confirmPassword}
              name="confirmPassword"
              type="password"
              value={formik.values.confirmPassword}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
            />
            <Button
              type="submit"
              title={Locale.actions.send}
              disabled={!formik.isValid}
            />
          </form>
          <PasswordValidationChecklist textValue={formik.values.password} />
        </div>
      </WrapperForm>
    </Template>
  );
};

export default RegisterPassword;
