import { WrapperForm, Select, TextInfo, Input, Dropdown } from "components";

import { MaskHelper } from "helpers";
import { ufMock } from "consts";
import cep from "cep-promise";
import Locale from "locale";
import AddressProps from "./types";

const Address = (props: any): JSX.Element => {
  const { formik } = props;

  const initialAddress = {
    address: "",
    number: "",
    complement: "",
    neighborhood: "",
    zipcode: "",
    mailbox: "",
    city: "",
    state: ""
  };

  const getAddressBilling = () => ({
    zipcode: formik.values.delivery.zipcode,
    address: formik.values.delivery.address,
    number: formik.values.delivery.number,
    neighborhood: formik.values.delivery.neighborhood,
    complement: formik.values.delivery.complement,
    city: formik.values.delivery.city,
    state: formik.values.delivery.state,
    mailbox: formik.values.delivery.postalBox
  });

  const handleBillingAddressChange = (e: any) => {
    const { checked } = e.currentTarget;
    const data: AddressProps = checked ? getAddressBilling() : initialAddress;

    formik.setValues((values: any) => ({
      ...values,
      billing: {
        ...values.billing,
        useDeliveryAsBillingAddress: e.currentTarget.checked,
        ...data
      }
    }));
  };

  const handleZipCodeChange = (zipCode: string) => {
    cep(zipCode)
      .then((response: any) => {
        formik.setValues((values: any) => ({
          ...values,
          billing: {
            ...values.billing,
            address: response.street,
            neighborhood: response.neighborhood,
            city: response.city,
            state: response.state,
            number: ""
          }
        }));
      })
      .catch(err => console.log(err));
  };
  return (
    <WrapperForm full className="margin--t-md">
      <h4 className="body-2 color--brand-primary">
        {Locale.pages.newMaterial.payment.billingAddress}
      </h4>
      <div className="container-form margin--t-md">
        <div className="row">
          <div className="col-12">
            {formik.values.delivery.shippingMethod !== "1" && (
              <Select
                type="checkbox"
                name="useDeliveryAsBillingAddress"
                id="useDeliveryAsBillingAddress"
                label={Locale.fields.address.addressData}
                checked={formik.values.billing.useDeliveryAsBillingAddress}
                onChange={handleBillingAddressChange}
              />
            )}
            {formik.values.billing.useDeliveryAsBillingAddress && (
              <>
                <div className="container-details-billing padding--t-md background-color--bw-gray-5">
                  <div className="row">
                    <div className="col-12 padding--x-md">
                      <TextInfo
                        title={Locale.fields.address.zipcode}
                        description={formik.values.billing.zipcode}
                        className="padding--x-md"
                      />
                    </div>
                    <div className="col-12 padding--x-md">
                      <TextInfo
                        title={Locale.fields.address.city}
                        description={formik.values.billing.city}
                        className="padding--x-md"
                      />
                    </div>
                    <div className="col-12 padding--x-md">
                      <TextInfo
                        title={Locale.fields.address.state}
                        description={formik.values.billing.state}
                        className="padding--x-md"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            {!formik.values.billing.useDeliveryAsBillingAddress && (
              <div className="margin--t-md">
                <div className="row">
                  <div className="col-12">
                    <Input
                      type="tel"
                      name="billing.zipcode"
                      label={Locale.fields.address.zipcode}
                      value={MaskHelper.cep(formik.values.billing.zipcode)}
                      onChange={e => {
                        formik.setValues((values: any) => ({
                          ...values,
                          billing: {
                            ...values.billing,
                            zipcode: e.currentTarget.value
                          }
                        }));
                        if (
                          MaskHelper.removeMask(e.currentTarget.value)
                            .length === 8
                        ) {
                          handleZipCodeChange(
                            MaskHelper.removeMask(e.currentTarget.value)
                          );
                        }
                      }}
                      onBlur={formik.handleBlur}
                      error={
                        formik?.touched?.billing?.zipcode &&
                        formik?.errors?.billing?.zipcode
                      }
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <Input
                      type="text"
                      name="billing.city"
                      label={Locale.fields.address.city}
                      value={formik.values.billing.city}
                      onChange={e =>
                        formik.setValues((values: any) => ({
                          ...values,
                          billing: {
                            ...values.billing,
                            city: e.currentTarget.value
                          }
                        }))
                      }
                      onBlur={formik.handleBlur}
                      error={
                        formik?.touched?.billing?.city &&
                        formik?.errors?.billing?.city
                      }
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <Dropdown
                      label={Locale.fields.address.state}
                      name="billing.state"
                      value={formik.values.billing.state}
                      onChange={e => {
                        formik.setValues((values: any) => ({
                          ...values,
                          billing: {
                            ...values.billing,
                            state: e
                          }
                        }));
                      }}
                      options={ufMock}
                      error={
                        formik?.touched?.billing?.state &&
                        formik?.errors?.billing?.state
                      }
                      disabled={formik.isSubmitting}
                    />
                  </div>
                </div>
              </div>
            )}

            {(!formik.values.billing.useDeliveryAsBillingAddress ||
              formik.values.delivery.usePostalBox) && (
              <div className="margin--t-md">
                <div className="row">
                  <div className="col-12 col-md-9">
                    <Input
                      type="text"
                      name="billing.address"
                      label={Locale.fields.address.street}
                      value={formik.values.billing.address}
                      onChange={e =>
                        formik.setValues((values: any) => ({
                          ...values,
                          billing: {
                            ...values.billing,
                            address: e.currentTarget.value
                          }
                        }))
                      }
                      onBlur={formik.handleBlur}
                      error={
                        formik?.touched?.billing?.address &&
                        formik?.errors?.billing?.address
                      }
                      required
                    />
                  </div>
                  <div className="col-12 col-md-3">
                    <Input
                      type="tel"
                      name="billing.number"
                      label={Locale.fields.address.number}
                      value={formik.values.billing.number}
                      onChange={e =>
                        formik.setValues((values: any) => ({
                          ...values,
                          billing: {
                            ...values.billing,
                            number: e.currentTarget.value
                          }
                        }))
                      }
                      onBlur={formik.handleBlur}
                      error={
                        formik?.touched?.billing?.number &&
                        formik?.errors?.billing?.number
                      }
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <Input
                      type="text"
                      name="billing.complement"
                      label={Locale.fields.address.complement}
                      value={formik.values.billing.complement}
                      onChange={e =>
                        formik.setValues((values: any) => ({
                          ...values,
                          billing: {
                            ...values.billing,
                            complement: e.currentTarget.value
                          }
                        }))
                      }
                      onBlur={formik.handleBlur}
                      error={
                        formik?.touched?.billing?.complement &&
                        formik?.errors?.billing?.complement
                      }
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <Input
                      type="text"
                      name="billing.neighborhood"
                      label={Locale.fields.address.neighborhood}
                      value={formik.values.billing.neighborhood}
                      onChange={e =>
                        formik.setValues((values: any) => ({
                          ...values,
                          billing: {
                            ...values.billing,
                            neighborhood: e.currentTarget.value
                          }
                        }))
                      }
                      onBlur={formik.handleBlur}
                      error={
                        formik?.touched?.billing?.neighborhood &&
                        formik?.errors?.billing?.neighborhood
                      }
                      required
                    />
                  </div>
                </div>
              </div>
            )}
            {!(
              !formik.values.billing.useDeliveryAsBillingAddress ||
              formik.values.delivery.usePostalBox
            ) && (
              <>
                <div className="container-details-billing padding--t-md background-color--bw-gray-5">
                  <div className="row">
                    <div className="col-12 padding--x-md">
                      <TextInfo
                        title={Locale.fields.address.street}
                        description={formik.values.billing.address}
                        className="padding--x-md"
                      />
                    </div>
                    <div className="col-12 padding--x-md">
                      <TextInfo
                        title={Locale.fields.address.number}
                        description={formik.values.billing.number}
                        className="padding--x-md"
                      />
                    </div>
                    <div className="col-12 padding--x-md">
                      <TextInfo
                        title={Locale.fields.address.complement}
                        description={formik.values.billing.complement}
                        className="padding--x-md"
                      />
                    </div>
                    <div className="col-12 padding--x-md">
                      <TextInfo
                        title={Locale.fields.address.neighborhood}
                        description={formik.values.billing.neighborhood}
                        className="padding--x-md"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </WrapperForm>
  );
};

export default Address;
