import { Icon } from "components";
import ItemListProps from "./types";
import "./styles.scss";

const ItemList = (props: ItemListProps): JSX.Element => {
  const { title, detail, icon, hasSufix, ...rest }: ItemListProps = props;

  return (
    <div className="item-list" {...rest}>
      {icon && <Icon className="item-list-prefix" name={icon} />}
      <div className="item-list-content">
        <div className="item-list-title">
          <span>{title}</span>
        </div>
        {detail && (
          <div className="item-list-detail">
            <span>{detail}</span>
          </div>
        )}
      </div>
      {hasSufix && <Icon className="item-list-sufix" name="chevronRight" />}
    </div>
  );
};

export default ItemList;
