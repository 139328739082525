import { NewMaterialContextProps, UserProps } from "../contexts/types";

const LocalStorageService = {
  getUser(): UserProps | undefined {
    const data = localStorage.getItem("CDL_USER_DATA");
    if (data) {
      return JSON.parse(data);
    }
    return undefined;
  },

  setUser(user: UserProps) {
    localStorage.setItem("CDL_USER_DATA", JSON.stringify(user));
  },

  getAccessToken(): string | null {
    return localStorage.getItem("CDL_TOKEN_ACCESS");
  },

  setAccessToken(token: string) {
    localStorage.setItem("CDL_TOKEN_ACCESS", token);
  },

  getRefreshToken(): string | null {
    return localStorage.getItem("CDL_TOKEN_REFRESH");
  },

  setRefreshToken(token: string) {
    localStorage.setItem("CDL_TOKEN_REFRESH", token);
  },

  getMaterialFormData(): NewMaterialContextProps | null {
    const data = localStorage.getItem("CDL_MATERIAL_FORM_DATA");
    if (data) {
      return JSON.parse(data);
    }
    return null;
  },

  setMaterialFormData(formData: NewMaterialContextProps) {
    localStorage.setItem("CDL_MATERIAL_FORM_DATA", JSON.stringify(formData));
  },

  clearMaterialFormData() {
    localStorage.removeItem("CDL_MATERIAL_FORM_DATA");
  },

  removeAll() {
    localStorage.clear();
  }
};

export default LocalStorageService;
