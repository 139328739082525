import clsx from "clsx";
import "./styles.scss";
import CardProps from "./types";

const Card = (props: CardProps): JSX.Element => {
  const { children, className, ...rest }: CardProps = props;

  const classes = clsx("card", className);
  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};

export default Card;
