import React, { useState, useRef } from "react";
import clsx from "clsx";
import { Icon } from "components";
import SearchProps from "./types";
import "./styles.scss";

const Search = (props: SearchProps): JSX.Element => {
  const { placeholder, value, id, iconPosition, ...rest }: SearchProps = props;
  const inputReference = useRef<HTMLInputElement>(null);

  return (
    <label className="input-wrapper" htmlFor={id}>
      {(!iconPosition || iconPosition === "left") && <Icon name="search" />}
      <input
        id={id}
        ref={inputReference}
        value={value}
        placeholder={placeholder}
        {...rest}
      />
      {iconPosition === "right" && <Icon name="search" />}
    </label>
  );
};

Search.defaultProps = {
  variant: "neutral"
};

export default Search;
