import React, { useState, useRef } from "react";
import clsx from "clsx";

import ChipProps from "./types";

import "./styles.scss";

const Code: React.FC<ChipProps> = ({
  name,
  id,
  value,
  setValue,
  error,
  className,
  ...props
}) => {
  const [code1, setCode1] = useState(value ? value.slice(0, 1) : "");
  const [code2, setCode2] = useState(value ? value.slice(1, 2) : "");
  const [code3, setCode3] = useState(value ? value.slice(2, 3) : "");
  const [code4, setCode4] = useState(value ? value.slice(3, 4) : "");
  const [code5, setCode5] = useState(value ? value.slice(4, 5) : "");
  const [code6, setCode6] = useState(value ? value.slice(5, 6) : "");

  const refInput1 = useRef<HTMLInputElement>(null);
  const refInput2 = useRef<HTMLInputElement>(null);
  const refInput3 = useRef<HTMLInputElement>(null);
  const refInput4 = useRef<HTMLInputElement>(null);
  const refInput5 = useRef<HTMLInputElement>(null);
  const refInput6 = useRef<HTMLInputElement>(null);

  function isfilled() {
    if (setValue) {
      if (code1 && code2 && code3 && code4 && code5 && code6) {
        setValue(`${code1}${code2}${code3}${code4}${code5}${code6}`);
      }
    }
  }

  return (
    <div
      className={clsx("container-code", {
        error,
        className
      })}
      {...props}
    >
      <input type="hidden" name={name} id={id} value={value} />
      <div className="wrapper">
        <input
          type="number"
          onChange={e => {
            if (e.currentTarget.value.length > 0) {
              setCode1(e.currentTarget.value.slice(0, 1));
            }
            refInput1.current?.blur();
            setTimeout(() => refInput2.current?.focus(), 0);
          }}
          onBlur={() => isfilled()}
          value={code1}
        />
        <input
          type="number"
          ref={refInput2}
          onChange={e => {
            if (e.currentTarget.value.length > 0) {
              setCode2(e.currentTarget.value.slice(0, 1));
            }
            refInput2.current?.blur();
            setTimeout(() => refInput3.current?.focus(), 100);
          }}
          onBlur={() => isfilled()}
          value={code2}
        />
        <input
          type="number"
          ref={refInput3}
          onChange={e => {
            if (e.currentTarget.value.length > 0) {
              setCode3(e.currentTarget.value.slice(0, 1));
              setTimeout(() => refInput4.current?.focus(), 100);
            }
            refInput3.current?.blur();
          }}
          onBlur={() => isfilled()}
          value={code3}
        />
        <input
          type="number"
          ref={refInput4}
          onChange={e => {
            if (e.currentTarget.value.length > 0) {
              setCode4(e.currentTarget.value.slice(0, 1));
              setTimeout(() => refInput5.current?.focus(), 100);
            }
            refInput4.current?.blur();
          }}
          onBlur={() => isfilled()}
          value={code4}
        />
        <input
          type="number"
          ref={refInput5}
          onChange={e => {
            if (e.currentTarget.value.length > 0) {
              setCode5(e.currentTarget.value.slice(0, 1));
              setTimeout(() => refInput6.current?.focus(), 100);
            }
          }}
          onBlur={() => isfilled()}
          value={code5}
        />
        <input
          type="number"
          ref={refInput6}
          onChange={e => {
            if (e.currentTarget.value.length > 0) {
              setCode6(e.currentTarget.value.slice(0, 1));
            }
            setTimeout(() => refInput6.current?.blur(), 100);
          }}
          onBlur={() => isfilled()}
          value={code6}
        />
      </div>
      {error && <p>{error}</p>}
    </div>
  );
};

export default Code;
