import { useEffect, useContext } from "react";
import { useFormik, FormikProps } from "formik";
import * as Yup from "yup";
import isEqual from "lodash/isEqual";
import { cpf as validateCPF, cnpj as validateCNPJ } from "cpf-cnpj-validator";
import { useNavigate } from "react-router-dom";

import { Template, HeaderPage, Button, Summary } from "components";

import Service from "services";
import Locale from "locale";
import { MaskHelper, ErrorHelper } from "helpers";
import notify from "components/Toast";
import { FieldStrings } from "locale/LocaleStrings";
import { getMaxErrorValidation } from "utils/yup";
import LocalStorageService from "services/localstorage";
import NewMaterialContext, {
  initialContextValues
} from "../NewMaterialContext";

import {
  Address,
  Billing,
  FormPayment,
  FinancialResponsible
} from "./Partials";
import { PaymentProps } from "./type";

const Payment = (): JSX.Element => {
  const navigate = useNavigate();
  const contextData = useContext(NewMaterialContext);

  const initialValues: PaymentProps = {
    analysis: contextData.analysis,
    billing: contextData.billing,
    summary: contextData.summary,
    delivery: contextData.delivery,
    financialResponsible: contextData.financialResponsible
  };

  const PaymentSchema = Yup.object().shape({
    billing: Yup.object().shape({
      formPayment: Yup.string().required(Locale.errors.chooseOne),
      // VALIDACAO CARTAO DE CREDITO
      billingType: Yup.string().required(Locale.errors.chooseOne),
      // VALIDACAO FATURAMENTO
      document: Yup.string()
        .when("billingType", {
          is: "pf",
          then: Yup.string()
            .required(Locale.errors.required)
            .test("is-cpf", "CPF inválido", (value: string | undefined) =>
              validateCPF.isValid(value || "")
            )
        })
        .when("billingType", {
          is: "pj",
          then: Yup.string()
            .required(Locale.errors.required)
            .test("is-cnpj", "CNPJ inválido", (value: string | undefined) =>
              validateCNPJ.isValid(value || "")
            )
        }),
      name: Yup.string().required(Locale.errors.required),
      email: Yup.string().email().required(Locale.errors.required),
      ruralProducerSubscription: Yup.string(),
      stateSubscription: Yup.string(),
      phoneNumber: Yup.string()
        .required(Locale.errors.required)
        .test("11-digits", "Quantidade de dígitos inválida", value =>
          value ? MaskHelper.removeMask(value).length === 11 : false
        ),
      // VALIDACAO ENDERECO DE COBRANCA
      address: Yup.string()
        .required(Locale.errors.required)
        .max(...getMaxErrorValidation(255)),
      number: Yup.string()
        .required(Locale.errors.required)
        .max(...getMaxErrorValidation(20)),
      complement: Yup.string().max(...getMaxErrorValidation(30)),
      neighborhood: Yup.string()
        .required(Locale.errors.required)
        .max(...getMaxErrorValidation(30)),
      zipcode: Yup.string().required(Locale.errors.required),
      city: Yup.string()
        .required(Locale.errors.required)
        .max(...getMaxErrorValidation(30)),
      state: Yup.string()
        .required(Locale.errors.required)
        .max(...getMaxErrorValidation(2))
    }),
    // VALIDACAO RESPONSAVEL FINANCEIRO
    financialResponsible: Yup.object().shape({
      name: Yup.string()
        .required(Locale.errors.required)
        .max(...getMaxErrorValidation(255)),
      email: Yup.string()
        .email(Locale.errors.email)
        .required(Locale.errors.required)
        .max(...getMaxErrorValidation(255)),
      mobilePhone: Yup.string()
        .required(Locale.errors.required)
        .test("11-digits", "Quantidade de dígitos inválida", value =>
          value ? MaskHelper.removeMask(value).length === 11 : false
        )
    })
  });

  const clearMaterialSolicitationContextData = () => {
    contextData.analysis = initialContextValues.analysis;
    contextData.delivery = initialContextValues.delivery;
    contextData.billing = initialContextValues.billing;
    contextData.summary = initialContextValues.summary;
    LocalStorageService.clearMaterialFormData();
  };

  const getDocumentType = (type: string) => {
    if (type === "pf") return "cpf";
    if (type === "pj") return "cnpj";
    return null;
  };

  const getCustomerType = (type: string) => {
    if (type === "pf") return "individual";
    if (type === "pj") return "corporation";
    return null;
  };

  const getMethodPayment = (method: string) => {
    switch (method) {
      case "boleto":
        return 2;
      case "pix":
        return 3;
      default:
        return 1;
    }
  };

  const formatPhone = (phone: string) => {
    const value = phone?.replace(/\D/g, "");
    return `+55${value}`;
  };

  const formatCEP = (cep: string) => cep?.replace(/\D/g, "");

  const getItems = (items: any) =>
    items?.map((item: any, key: number) => ({
      id: String(key),
      title: item.label,
      unit_price: Math.floor(
        item.value /
          (Number(formik.values.analysis.animalCount) +
            Number(formik.values.analysis.tankCount))
      ),
      quantity:
        Number(formik.values.analysis.animalCount) +
        Number(formik.values.analysis.tankCount),
      tangible: false
    }));

  const sendForm = (contextData: any) => {
    const data = {
      address: contextData.delivery.zipcode
        ? {
            postcode: contextData.delivery.zipcode.replace(/\D/g, ""),
            street_name: !contextData.delivery.usePostalBox
              ? contextData.delivery.address
              : undefined,
            number: !contextData.delivery.usePostalBox
              ? contextData.delivery.number
              : undefined,
            complement: !contextData.delivery.usePostalBox
              ? contextData.delivery.complement
              : undefined,
            neighborhood: !contextData.delivery.usePostalBox
              ? contextData.delivery.neighborhood
              : undefined,
            city: contextData.delivery.city,
            state: contextData.delivery.state,
            mailbox: contextData.delivery.postalBox
          }
        : undefined,
      billing_address:
        formik.values.billing.useDeliveryAsBillingAddress &&
        !formik.values.delivery.usePostalBox
          ? undefined
          : {
              postcode: contextData.billing.zipcode.replace(/\D/g, ""),
              street_name: contextData.billing.address,
              number: contextData.billing.number,
              complement: contextData.billing.complement,
              neighborhood: contextData.billing.neighborhood,
              city: contextData.billing.city,
              state: contextData.billing.state
            },
      billing_data: {
        name: contextData.billing.name,
        cpf:
          contextData.billing.billingType === "pf"
            ? contextData.billing.document.replace(/\D/g, "")
            : undefined,
        cnpj:
          contextData.billing.billingType === "pj"
            ? contextData.billing.document.replace(/\D/g, "")
            : undefined,
        email: contextData.billing.email,
        state_subscription: contextData.billing.stateSubscription,
        rural_producer_subscription:
          contextData.billing.ruralProducerSubscription
      },
      payment: {
        amount: contextData.summary.amount,
        payment_method: getMethodPayment(contextData.billing.formPayment),
        financial_responsible: {
          name: contextData.financialResponsible.name,
          email: contextData.financialResponsible.email,
          mobile: formatPhone(contextData.financialResponsible.mobilePhone)
        }
      },
      analysis_items: getAnalyses(contextData.analysis.analyses),
      lactation_animals_amount: Number(contextData.analysis.animalCount),
      tank_amount: Number(contextData.analysis.tankCount),
      shipping_method: contextData.delivery.shippingMethod,
      association: contextData.analysis.association.value,
      support_point: contextData.delivery.supportLocationName
    };
    Service.registerMaterial(data)
      .then((responseData: any) => {
        sendTransaction(contextData, responseData.material_order_id);
      })
      .catch(err => {
        ErrorHelper.notifyError(err);
        formik.setSubmitting(false);
      });
  };

  const sendTransaction = (data: PaymentProps, materialOrderId: number) => {
    const checkoutData = {
      amount: data.summary.amount * 100,
      customer: {
        external_id: data.billing.email,
        type: getCustomerType(data.billing.billingType),
        name: data.billing.name,
        country: data.billing.countryCode.toLowerCase(),
        email: data.billing.email,
        documents: [
          {
            type: getDocumentType(data.billing.billingType),
            number: data.billing.document
          }
        ],
        phone_numbers: [formatPhone(data.billing.phoneNumber)]
      },
      billing: {
        name: data.billing.name,
        address: {
          country: data.billing.countryCode.toLowerCase(),
          state: data.billing.state,
          city: data.billing.city,
          neighborhood: data.billing.neighborhood,
          street: data.billing.address,
          street_number: data.billing.number,
          zipcode: formatCEP(data.billing.zipcode)
        }
      },
      items: getItems(data.summary.items)
    };
    if (data.billing.formPayment === "boleto") {
      Service.getBoleto({
        ...checkoutData,
        payment_method: "boleto",
        materialOrderId
      })
        .then(responseData => {
          registerTransaction(contextData, responseData, materialOrderId);
        })
        .catch(err => {
          Service.registerTransactionError({
            pagarme_response: err,
            material_order_id: materialOrderId
          });
          ErrorHelper.notifyError(err);
          formik.setSubmitting(false);
        });
    } else {
      const checkout = new window.PagarMeCheckout.Checkout({
        encryption_key: `${process.env.REACT_APP_PAGARME_TOKEN}`,
        success: (responseData: any) => {
          registerTransaction(contextData, responseData, materialOrderId);
        },
        error: (err: any) => {
          Service.registerTransactionError({
            pagarme_response: err,
            material_order_id: materialOrderId
          });
          ErrorHelper.notifyError(err);
          formik.setSubmitting(false);
        },
        close: () => {
          // TODO: Se usuário fechar cartão e submeter SM de novo, deve editar SM criada, e não criar uma nova
          formik.setSubmitting(false);
        }
      });

      checkout.open({
        ...checkoutData,
        maxInstallments: 1,
        createToken: "true",
        customerData: "false",
        paymentMethods: data.billing.formPayment
      });
    }
  };

  const registerTransaction = (
    contextData: any,
    pagarme: any,
    materialOrderId: number
  ) => {
    const data = {
      transaction_token: pagarme.token,
      transaction_id: pagarme.transaction_id,
      boleto_url: pagarme.boleto_url,
      boleto_barcode: pagarme.boleto_barcode,
      acquirer_id: pagarme.acquirer_id,
      date_created: pagarme.date_created,
      material_order_id: materialOrderId,
      payment_method: contextData.billing.formPayment
    };

    Service.registerTransaction(data).finally(() => {
      clearMaterialSolicitationContextData();
      navigate("/material/new/success", {
        state: {
          paymentMethod: contextData.billing.formPayment,
          boletoUrl: pagarme?.boleto_url
        },
        replace: true
      });
    });
  };

  const getAnalyses = (analyses: any) => {
    const arr: { analysis_type: string }[] = [];
    Object.keys(analyses)
      .filter(k => analyses[k])
      .map(item => arr.push({ analysis_type: String(item).toUpperCase() }));
    return arr;
  };

  const formik: FormikProps<PaymentProps> = useFormik<PaymentProps>({
    initialValues,
    validationSchema: PaymentSchema,
    onSubmit: data => {
      contextData.billing = data.billing;
      if (contextData.billing.city && contextData.billing.state) {
        Service.verifyCity(contextData.billing.city, contextData.billing.state)
          .then(() => {
            sendForm(data);
          })
          .catch(err => {
            ErrorHelper.notifyError(err);
            formik.setSubmitting(false);
          });
      } else {
        sendForm(data);
      }
    }
  });

  useEffect(() => {
    let notifyMessage = "";
    const labels: string[] = [];

    if (formik?.errors?.billing) {
      (
        Object.keys(formik.errors.billing) as (keyof FieldStrings["payment"])[]
      ).forEach(key => {
        if (Locale?.fields?.payment?.[key]) {
          labels.push(Locale.fields.payment[key]);
        }
      });

      notifyMessage = labels.join(", ");
      if (notifyMessage) {
        notify(`Existe um erro nos seguintes campos: ${notifyMessage}`);
      }
    }
  }, [formik.submitCount]);

  useEffect(() => {
    const previousPagesAreEmpty =
      isEqual(contextData.analysis, initialContextValues.analysis) ||
      isEqual(contextData.delivery, initialContextValues.delivery);

    if (previousPagesAreEmpty) {
      navigate("/");
    }
  });

  return (
    <NewMaterialContext.Provider value={contextData}>
      <Template formik={formik} cart notification className="margin-y">
        <form onSubmit={formik.handleSubmit}>
          <HeaderPage
            title={Locale.pages.newMaterial.payment.title}
            description={Locale.pages.newMaterial.payment.description}
            goBack={() => {
              contextData.billing = formik?.values?.billing;
              navigate("/material/new/address");
            }}
          />
          <fieldset disabled={formik.isSubmitting}>
            <FormPayment formik={formik} />
            <Billing formik={formik} autofill />
            <Address formik={formik} />
            <FinancialResponsible formik={formik} />
          </fieldset>
          <Summary formik={formik}>
            <Button
              type="submit"
              title={Locale.actions.payAndFinish}
              fullWidth
              isLoading={formik.isSubmitting}
            />
          </Summary>
        </form>
      </Template>
    </NewMaterialContext.Provider>
  );
};

export default Payment;
