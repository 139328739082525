import Locale from "locale";
import * as Yup from "yup";

interface ValidateFunction {
  (value: string): boolean;
}

interface PasswordHelperType {
  validateLength: ValidateFunction;
  validateDigit: ValidateFunction;
  validateUppercase: ValidateFunction;
  validateSpecialChar: ValidateFunction;
  passwordValidationSchema: Yup.StringSchema;
  confirmPasswordValidationSchema: Yup.StringSchema;
}

const PasswordHelper: PasswordHelperType = {
  validateLength(value: string) {
    return value.length >= 8;
  },

  validateDigit(value: string) {
    const matchNumber = value.match(/\d/);
    return !!(matchNumber && matchNumber.length > 0);
  },

  validateUppercase(value: string) {
    const matchUppercase = value.match(/[A-Z]/);
    return !!(matchUppercase && matchUppercase.length > 0);
  },

  validateSpecialChar(value: string) {
    const matchSpecial = value.match(/[!@#$%^&*]/);
    return !!(matchSpecial && matchSpecial.length > 0);
  },

  passwordValidationSchema: Yup.string()
    .required(Locale.errors.required)
    .min(8, Locale.errors.password.length)
    .test("at least 1 digit", Locale.errors.password.number, value =>
      PasswordHelper.validateDigit(value || "")
    )
    .test("at least 1 uppercase", Locale.errors.password.uppercase, value =>
      PasswordHelper.validateUppercase(value || "")
    )
    .test(
      "at least 1 special char",
      Locale.errors.password.specialChar,
      value => PasswordHelper.validateSpecialChar(value || "")
    ),

  confirmPasswordValidationSchema: Yup.string()
    .required(Locale.errors.required)
    .oneOf([Yup.ref("password"), null], Locale.errors.password.match)
};

export default PasswordHelper;
