import { useContext, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useSearchParams, useLocation } from "react-router-dom";

import { TwoColumns, Input, Button, Card, Icon, WrapperForm } from "components";
import { AuthContext } from "contexts/auth";

import notify from "components/Toast";

import "./styles.scss";
import Locale from "locale";

import { EmailData } from "services/onboarding";

const Login = () => {
  const { login } = useContext(AuthContext);

  const [searchParams] = useSearchParams();
  const accValidated = searchParams.get("validated");
  const errorMessage = searchParams.get("error");

  useEffect(() => {
    if (errorMessage) notify(`${errorMessage}`);
    else if (accValidated) notify(Locale.notifications.accountValidated);
  }, []);

  const location = useLocation();
  const email = (location?.state as EmailData)?.email;

  useEffect(() => {
    if (email) notify("Este e-mail já foi cadastrado! Digite sua senha");
  }, []);

  const values = {
    email: email ?? "",
    password: ""
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(Locale.errors.email)
      .required(Locale.errors.required),
    password: Yup.string().required(Locale.errors.required)
  });

  const formik = useFormik({
    initialValues: values,
    validationSchema: LoginSchema,
    onSubmit: data => {
      login(data).finally(() => {
        formik.setSubmitting(false);
      });
    }
  });

  return (
    <TwoColumns
      title={Locale.pages.login.title}
      description={Locale.pages.login.description}
    >
      <WrapperForm>
        <h1 className="heading-1">{Locale.pages.login.form.title}</h1>
        <div className="margin--t-lg">
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-12">
                <Input
                  type="email"
                  name="email"
                  label={Locale.fields.user.email}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && formik.errors.email}
                />
              </div>
              <div className="col-12">
                <Input
                  type="password"
                  name="password"
                  label={Locale.fields.user.password}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.touched.password && formik.errors.password}
                />
              </div>
              <div className="col-12">
                <Link to="/login/password-reset">
                  <Button
                    fullWidth
                    title={Locale.actions.forgotPassword}
                    variant="tertiary"
                  />
                </Link>
              </div>
              <div className="col-12">
                <Button
                  fullWidth
                  type="submit"
                  title={Locale.actions.login}
                  isLoading={formik.isSubmitting}
                />
              </div>
            </div>
          </form>
        </div>
        <hr className="margin--y-lg" />
        <h1 className="heading-1">{Locale.actions.createAccount}</h1>
        <p className="body-1 margin--t-sm">
          {Locale.pages.login.newUser.description}
        </p>
        <div className="margin--t-lg flex--row flex-justify--between flex-children--1 card-login">
          <Link to="/register/user">
            <Card>
              <Icon name="farmer" />
              <h3 className="button-1 color--brand-primary margin--t-sm">
                {Locale.general.farm}
              </h3>
            </Card>
          </Link>
          {/* <Link to="/register/user">
            <Card>
              <Icon name="industry" />
              <h3 className="button-1 color--brand-primary margin--t-sm">
                Indústria
              </h3>
            </Card>
          </Link> */}
        </div>
      </WrapperForm>
    </TwoColumns>
  );
};

export default Login;
