import clsx from "clsx";
import { Icon } from "components";
import ButtonProps from "./types";
import "./styles.scss";

const Button = (props: ButtonProps): JSX.Element => {
  const {
    title,
    children,
    size,
    variant,
    className,
    icon,
    fullWidth,
    isLoading,
    disabled,
    ...rest
  }: ButtonProps = props;

  const classes = clsx("btn", className, {
    "btn-regular": size === "regular",
    "btn-small": size === "small",
    "btn-regular-icon": icon && !title && size === "regular",
    "btn-small-icon": icon && !title && size === "small",
    "btn-icon": icon && !title,
    "btn-primary": variant === "primary",
    "btn-secondary": variant === "secondary",
    "btn-tertiary": variant === "tertiary",
    "btn-full-width": fullWidth
  });

  return (
    <button
      type="button"
      className={classes}
      {...rest}
      disabled={isLoading ?? disabled}
    >
      {isLoading && <div className="loader" />}
      {icon ? <Icon name={icon} /> : <></>}
      {children}
      {title ? <span>{title}</span> : null}
    </button>
  );
};

Button.defaultProps = {
  variant: "primary",
  type: "button",
  disabled: false,
  size: "regular"
};

export default Button;
