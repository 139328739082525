import { Navigate, Route } from "react-router-dom";

interface AuthGuardProps {
  path: string;
  element: React.ReactNode;
  isUserAuthenticated?: boolean;
  navigateTo?: string;
}

const defaultProps: AuthGuardProps = {
  isUserAuthenticated: false,
  navigateTo: "/",
  path: "",
  element: undefined
};

const AuthGuard: React.FC<AuthGuardProps> = ({
  path,
  element,
  isUserAuthenticated,
  navigateTo,
  ...args
}) => {
  const navigateDefault = "/login";

  return isUserAuthenticated === false ? (
    <Route
      path={path}
      {...args}
      element={<Navigate to={navigateTo || navigateDefault} />}
    />
  ) : (
    <Route path={path} {...args} element={element} />
  );
};

AuthGuard.defaultProps = defaultProps;

export default AuthGuard;
