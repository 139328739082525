import { useEffect, useContext, useRef, useState, ChangeEvent } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import cep from "cep-promise";
import { cpf as validateCPF, cnpj as validateCNPJ } from "cpf-cnpj-validator";

import Service from "services";
import {
  Dropdown,
  Input,
  Select,
  Template,
  Button,
  HeaderPage,
  WrapperForm,
  TextInfo,
  StickyFooter
} from "components";

import { MaskHelper, ErrorHelper } from "helpers";

import { ufMock } from "consts";

import notify from "components/Toast";
import Locale from "locale";
import { getMaxErrorValidation } from "utils/yup";
import { getAssociationObjectByValue } from "utils/functions";
import { AssociationType } from "pages/Material/New/Association/types";
import { BillingDataProps } from "pages/Material/New/Payment/Partials/Billing/type";
import { AuthContext } from "contexts/auth";
import AddressProps from "pages/Material/New/Payment/Partials/Address/types";
import { FieldStrings } from "locale/LocaleStrings";
import ClientDataContext from "./ClientDataContext";

const validationSchema = Yup.object().shape({
  association: Yup.object().shape({
    value: Yup.string().required(Locale.errors.required),
    sponsor_pays: Yup.string()
  }),
  delivery: Yup.object().shape({
    zipcode: Yup.string().required(Locale.errors.required),
    address: Yup.string()
      .when("usePostalBox", {
        is: false,
        then: Yup.string().required(Locale.errors.required)
      })
      .max(...getMaxErrorValidation(255)),
    number: Yup.string()
      .when("usePostalBox", {
        is: false,
        then: Yup.string().required(Locale.errors.required)
      })
      .max(...getMaxErrorValidation(20)),
    postalBox: Yup.string().when("usePostalBox", {
      is: true,
      then: Yup.string().required(Locale.errors.required)
    }),
    complement: Yup.string().max(...getMaxErrorValidation(30)),
    neighborhood: Yup.string()
      .when("usePostalBox", {
        is: false,
        then: Yup.string().required(Locale.errors.required)
      })
      .max(...getMaxErrorValidation(30)),
    city: Yup.string()
      .required(Locale.errors.required)
      .max(...getMaxErrorValidation(30)),
    state: Yup.string()
      .required("Campo obrigatório")
      .max(...getMaxErrorValidation(2)),
    usePostalBox: Yup.boolean()
  }),
  billing: Yup.object().shape({
    billingType: Yup.string().required(Locale.errors.chooseOne),
    // VALIDACAO FATURAMENTO
    document: Yup.string()
      .when("billingType", {
        is: "pf",
        then: Yup.string()
          .required(Locale.errors.required)
          .test("is-cpf", "CPF inválido", (value: string | undefined) =>
            validateCPF.isValid(value || "")
          )
      })
      .when("billingType", {
        is: "pj",
        then: Yup.string()
          .required(Locale.errors.required)
          .test("is-cnpj", "CNPJ inválido", (value: string | undefined) =>
            validateCNPJ.isValid(value || "")
          )
      }),
    name: Yup.string().required(Locale.errors.required),
    email: Yup.string().email().required(Locale.errors.required),
    ruralProducerSubscription: Yup.string(),
    stateSubscription: Yup.string(),
    phoneNumber: Yup.string()
      .required(Locale.errors.required)
      .test("11-digits", "Quantidade de dígitos inválida", value =>
        value ? MaskHelper.removeMask(value).length === 11 : false
      ),
    // VALIDACAO ENDERECO DE COBRANCA
    address: Yup.string()
      .required(Locale.errors.required)
      .max(...getMaxErrorValidation(255)),
    number: Yup.string()
      .required(Locale.errors.required)
      .max(...getMaxErrorValidation(20)),
    complement: Yup.string().max(...getMaxErrorValidation(30)),
    neighborhood: Yup.string()
      .required(Locale.errors.required)
      .max(...getMaxErrorValidation(30)),
    zipcode: Yup.string().required(Locale.errors.required),
    city: Yup.string()
      .required(Locale.errors.required)
      .max(...getMaxErrorValidation(30)),
    state: Yup.string()
      .required(Locale.errors.required)
      .max(...getMaxErrorValidation(2))
  })
});

const Profile = () => {
  const navigate = useNavigate();
  const contextData = useContext(ClientDataContext);
  const [associationOptions, setAssociationOptions] = useState<
    AssociationType[]
  >([]);
  const { user, getUserClientData, userClientData } = useContext(AuthContext);
  useEffect(() => {
    getUserClientData();
  }, [user]);

  const formik = useFormik({
    initialValues: contextData,
    validationSchema,
    onSubmit: data => {
      const clientData = {
        address: {
          postcode: data.delivery.zipcode.replace(/\D/g, ""),
          street_name: !data.delivery.usePostalBox
            ? data.delivery.address
            : undefined,
          number: !data.delivery.usePostalBox
            ? data.delivery.number
            : undefined,
          complement: !data.delivery.usePostalBox
            ? data.delivery.complement
            : undefined,
          neighborhood: !data.delivery.usePostalBox
            ? data.delivery.neighborhood
            : undefined,
          city: data.delivery.city,
          state: data.delivery.state,
          mailbox: data.delivery.postalBox
        },
        billing_address:
          formik.values.billing.useDeliveryAsBillingAddress &&
          !formik.values.delivery.usePostalBox
            ? undefined
            : {
                postcode: data.billing.zipcode.replace(/\D/g, ""),
                street_name: data.billing.address,
                number: data.billing.number,
                complement: data.billing.complement,
                neighborhood: data.billing.neighborhood,
                city: data.billing.city,
                state: data.billing.state
              },
        billing_data: {
          name: data.billing.name,
          cpf:
            data.billing.billingType === "pf"
              ? data.billing.document.replace(/\D/g, "")
              : undefined,
          cnpj:
            data.billing.billingType === "pj"
              ? data.billing.document.replace(/\D/g, "")
              : undefined,
          email: data.billing.email,
          state_subscription: data.billing.stateSubscription,
          rural_producer_subscription: data.billing.ruralProducerSubscription
        },
        association: data.association.value
      };

      Service.registerClientData(clientData)
        .then(() => {
          notify("Dados do cliente foram atualizados com sucesso!");
          navigate("/");
        })
        .catch(err => {
          ErrorHelper.notifyError(err);
          formik.setSubmitting(false);
        });
    }
  });

  const handleZipCodeChange = (zipCode: string, addressType: string) => {
    cep(zipCode)
      .then((response: any) => {
        formik.setValues((values: any) => ({
          ...values,
          [addressType]: {
            ...values[addressType],
            address: response.street,
            neighborhood: response.neighborhood,
            city: response.city,
            state: response.state,
            number: ""
          }
        }));
      })
      .catch(err => console.log(err));
  };

  const inputDeliveryNumber = useRef<HTMLInputElement>(null);

  const dataInitial: BillingDataProps = {
    document: "",
    name: "",
    email: "",
    stateSubscription: "",
    ruralProducerSubscription: "",
    billingType: "",
    phoneNumber: ""
  };

  const [pfBillingData, setPfBillingData] = useState(dataInitial);
  const [pjBillingData, setPjBillingData] = useState(dataInitial);

  const handleChangeBillingType = (e: ChangeEvent<HTMLInputElement>) => {
    switch (formik.values.billing.billingType) {
      case "pf":
        setPjBillingData(formik.values.billing);
        formik.setValues((values: any) => ({
          ...values,
          billing: {
            ...values.billing,
            ...pfBillingData,
            billingType: e.currentTarget?.value
          }
        }));
        break;
      case "pj":
        setPfBillingData(formik.values.billing);
        formik.setValues((values: any) => ({
          ...values,
          billing: {
            ...values.billing,
            ...pjBillingData,
            billingType: e.currentTarget?.value
          }
        }));
        break;
      default:
        formik.setValues((values: any) => ({
          ...values,
          billing: {
            ...values.billing,
            ...dataInitial,
            billingType: e.currentTarget?.value
          }
        }));
    }
  };

  const getBillingData = () => ({
    ...dataInitial,
    document: userClientData?.cpf || userClientData?.cnpj,
    name: user?.name,
    email: user?.email,
    phoneNumber: user?.mobile
  });

  const handleUseBillingDataChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;
    let data = dataInitial;

    if (checked) {
      data = getBillingData();
    }

    formik.setValues((values: any) => ({
      ...values,
      billing: {
        ...values.billing,
        useBillingData: e.currentTarget.checked,
        ...data
      }
    }));
  };

  const [isLegalEntity, setIsLegalEntity] = useState(false);
  useEffect(() => {
    if (MaskHelper.removeMask(formik.values.billing.document).length === 14) {
      setIsLegalEntity(true);
      formik.values.billing.billingType = "pj";
    } else if (
      MaskHelper.removeMask(formik.values.billing.document).length === 11
    ) {
      setIsLegalEntity(false);
      formik.values.billing.billingType = "pf";
    }
  }, [formik.values.billing.document]);

  useEffect(() => {
    let notifyMessage = "";
    const labels: string[] = [];
    if (formik?.errors?.delivery) {
      (
        Object.keys(formik.errors.delivery) as (keyof FieldStrings["address"])[]
      ).forEach(key => {
        if (Locale.fields.address[key]) {
          labels.push(Locale.fields.address[key]);
        }
      });

      notifyMessage = labels.join(", ");
      if (notifyMessage) {
        notify(`Existe um erro nos seguintes campos: ${notifyMessage}`);
      }
    }
  }, [formik.submitCount]);

  useEffect(() => {
    Service.listAssociationOptions().then(response => {
      const options: AssociationType[] = [];
      response.forEach((item: any) => {
        if (item.name === "Sem Convênio") {
          options.unshift({
            label: item.name,
            value: item.rowid,
            sponsor_pays: item.sponsor_pays
          });
        } else {
          options.push({
            label: item.name,
            value: item.rowid,
            sponsor_pays: item.sponsor_pays
          });
        }
      });
      setAssociationOptions(options);
    });
  }, []);

  const initialAddress = {
    address: "",
    number: "",
    complement: "",
    neighborhood: "",
    zipcode: "",
    mailbox: "",
    city: "",
    state: ""
  };

  const getAddressBilling = () => ({
    zipcode: formik.values.delivery.zipcode,
    address: formik.values.delivery.address,
    number: formik.values.delivery.number,
    neighborhood: formik.values.delivery.neighborhood,
    complement: formik.values.delivery.complement,
    city: formik.values.delivery.city,
    state: formik.values.delivery.state,
    mailbox: formik.values.delivery.postalBox
  });

  const handleBillingAddressChange = (e: any) => {
    const { checked } = e.currentTarget;
    const data: AddressProps = checked ? getAddressBilling() : initialAddress;

    formik.setValues((values: any) => ({
      ...values,
      billing: {
        ...values.billing,
        useDeliveryAsBillingAddress: e.currentTarget.checked,
        ...data
      }
    }));
  };

  const hasDeliveryAddressData = () =>
    !!formik.values.delivery.zipcode &&
    !!formik.values.delivery.city &&
    !!formik.values.delivery.state &&
    (formik.values.delivery.usePostalBox ||
      (!!formik.values.delivery.address &&
        !!formik.values.delivery.neighborhood &&
        !!formik.values.delivery.number));

  // Garante que checkbox de utilizar endereço de entrega não trava deixando campos vazios e bloqueados
  useEffect(() => {
    if (
      !hasDeliveryAddressData() &&
      formik.values.billing.useDeliveryAsBillingAddress
    ) {
      formik.setValues((values: any) => ({
        ...values,
        billing: {
          ...values.billing,
          useDeliveryAsBillingAddress: false
        }
      }));
    }
  }, [formik.values.delivery]);

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="address-form">
        <Template scrollable formik={formik} className="margin-y">
          <HeaderPage
            goBack={() => {
              navigate("/");
            }}
            title={Locale.pages.profile.title}
            description={Locale.pages.profile.description}
          />
          <WrapperForm full className="margin--t-md">
            <h4 className="body-2 color--brand-primary">
              {Locale.pages.newMaterial.association.title}
            </h4>
            <Dropdown
              className="dropdown-wrapper dropdown-association"
              id="association"
              name="association"
              label={Locale.actions.select}
              options={associationOptions}
              value={formik.values.association.value}
              error={formik.errors.association?.value}
              onChange={e => {
                const newAssociation = getAssociationObjectByValue(
                  e,
                  associationOptions
                );
                formik.setValues((values: any) => ({
                  ...values,

                  association: {
                    value: newAssociation.value,
                    sponsor_pays: newAssociation.sponsor_pays
                  }
                }));
              }}
              autocomplete
            />
          </WrapperForm>
          <WrapperForm full className="margin--t-md">
            <h4 className="body-2 color--brand-primary">
              {Locale.pages.newMaterial.address.deliveryDestination}
            </h4>
            <div className="container-form margin--t-md">
              <div className="row">
                <div className="col-12">
                  <Input
                    type="tel"
                    name="delivery.zipcode"
                    value={MaskHelper.cep(formik.values.delivery.zipcode)}
                    onChange={e => {
                      formik.setValues((values: any) => ({
                        ...values,
                        delivery: {
                          ...values.delivery,
                          zipcode: e.currentTarget.value
                        }
                      }));
                      if (
                        MaskHelper.removeMask(e.currentTarget.value).length ===
                        8
                      ) {
                        handleZipCodeChange(
                          MaskHelper.removeMask(e.currentTarget.value),
                          "delivery"
                        );
                        inputDeliveryNumber.current?.focus();
                      }
                    }}
                    error={
                      formik.touched.delivery?.zipcode &&
                      formik.errors.delivery?.zipcode
                    }
                    onBlur={formik.handleBlur}
                    label={Locale.fields.address.zipcode}
                    search
                    maxLength={9}
                    required
                  />
                </div>

                <div className="col-12">
                  <Select
                    id="delivery.usePostalBox"
                    name="delivery.usePostalBox"
                    checked={formik.values.delivery.usePostalBox}
                    label={Locale.fields.address.useMailbox}
                    onChange={e => {
                      formik.setValues((values: any) => ({
                        ...values,
                        delivery: {
                          ...values.delivery,
                          usePostalBox: e.currentTarget.checked
                        }
                      }));
                    }}
                    type="checkbox"
                  />
                </div>
                {!formik.values.delivery.usePostalBox ? (
                  <>
                    <div className="col-12 col-md-9">
                      <Input
                        type="text"
                        label={Locale.fields.address.street}
                        name="delivery.address"
                        value={formik.values.delivery.address}
                        onChange={e => {
                          formik.setValues((values: any) => ({
                            ...values,
                            delivery: {
                              ...values.delivery,
                              address: e.currentTarget.value
                            }
                          }));
                        }}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.delivery?.address &&
                          formik.errors.delivery?.address
                        }
                        required
                      />
                    </div>
                    <div className="col-12 col-md-3">
                      <Input
                        ref={inputDeliveryNumber}
                        type="text"
                        name="delivery.number"
                        value={formik.values.delivery.number}
                        onChange={e => {
                          formik.setValues((values: any) => ({
                            ...values,
                            delivery: {
                              ...values.delivery,
                              number: e.currentTarget.value
                            }
                          }));
                        }}
                        label={Locale.fields.address.number}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.delivery?.number &&
                          formik.errors.delivery?.number
                        }
                        required
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <Input
                        type="text"
                        name="delivery.complement"
                        value={formik.values.delivery.complement}
                        onChange={e => {
                          formik.setValues((values: any) => ({
                            ...values,
                            delivery: {
                              ...values.delivery,
                              complement: e.currentTarget.value
                            }
                          }));
                        }}
                        label={Locale.fields.address.complement}
                        error={
                          formik.touched.delivery?.complement &&
                          formik.errors.delivery?.complement
                        }
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <Input
                        type="text"
                        name="delivery.neighborhood"
                        value={formik.values.delivery.neighborhood}
                        onChange={e => {
                          formik.setValues((values: any) => ({
                            ...values,
                            delivery: {
                              ...values.delivery,
                              neighborhood: e.currentTarget.value
                            }
                          }));
                        }}
                        label={Locale.fields.address.neighborhood}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.delivery?.neighborhood &&
                          formik.errors.delivery?.neighborhood
                        }
                        required
                      />
                    </div>
                  </>
                ) : (
                  <div className="col-12">
                    <Input
                      type="text"
                      name="delivery.postalBox"
                      value={formik.values.delivery.postalBox}
                      onChange={e => {
                        formik.setValues((values: any) => ({
                          ...values,
                          delivery: {
                            ...values.delivery,
                            postalBox: e.currentTarget.value
                          }
                        }));
                      }}
                      label={Locale.fields.address.mailbox}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.delivery?.postalBox &&
                        formik.errors.delivery?.postalBox
                      }
                      required
                    />
                  </div>
                )}
                <div className="col-12 col-md-6">
                  <Input
                    type="text"
                    name="delivery.city"
                    value={formik.values.delivery.city}
                    onChange={e => {
                      formik.setValues((values: any) => ({
                        ...values,
                        delivery: {
                          ...values.delivery,
                          city: e.currentTarget.value
                        }
                      }));
                    }}
                    label={Locale.fields.address.city}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.delivery?.city &&
                      formik.errors.delivery?.city
                    }
                    required
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Dropdown
                    name="delivery.state"
                    value={formik.values.delivery.state}
                    onChange={e => {
                      formik.setValues((values: any) => ({
                        ...values,
                        delivery: {
                          ...values.delivery,
                          state: e
                        }
                      }));
                    }}
                    options={ufMock}
                    label={Locale.fields.address.state}
                    autocomplete
                    error={
                      formik.touched.delivery?.state &&
                      formik.errors.delivery?.state
                    }
                    dropup
                  />
                </div>
              </div>
            </div>
          </WrapperForm>
          <WrapperForm full className="margin--t-md">
            <h4 className="body-2 color--brand-primary">
              {Locale.pages.newMaterial.payment.billing}
            </h4>
            <div className="container-form margin--t-md">
              <div className="row">
                <div className="col-12">
                  <Select
                    type="checkbox"
                    name="useBillingData"
                    id="useBillingData"
                    label={Locale.fields.payment.useBillingData}
                    checked={formik.values.billing.useBillingData}
                    onChange={handleUseBillingDataChange}
                  />
                  {formik.values.billing.useBillingData && (
                    <>
                      <div className="container-details-billing padding--t-md background-color--bw-gray-5">
                        <div className="row">
                          {isLegalEntity ? (
                            <>
                              <div className="col-12 padding--x-md">
                                <TextInfo
                                  title={Locale.fields.user.ownersCNPJ}
                                  description={MaskHelper.cnpj(
                                    formik.values.billing.document
                                  )}
                                  className="padding--x-md"
                                />
                              </div>
                              <div className="col-12">
                                <TextInfo
                                  title={Locale.fields.user.companyName}
                                  description={formik.values.billing.name}
                                  className="padding--x-md"
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="col-12 padding--x-md">
                                <TextInfo
                                  title={Locale.fields.user.CPF}
                                  description={MaskHelper.cpf(
                                    formik.values.billing.document
                                  )}
                                  className="padding--x-md"
                                />
                              </div>
                              <div className="col-12">
                                <TextInfo
                                  title="Nome completo"
                                  description={formik.values.billing.name}
                                  className="padding--x-md"
                                />
                              </div>
                            </>
                          )}

                          <div className="col-12">
                            <TextInfo
                              title={Locale.fields.user.invoiceEmail}
                              description={formik.values.billing.email}
                              className="padding--x-md"
                            />
                          </div>
                          <div className="col-12">
                            <TextInfo
                              title={Locale.fields.user.mobilePhone}
                              description={MaskHelper.phone(
                                formik.values.billing.phoneNumber
                              )}
                              className="padding--x-md"
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="margin--t-md">
                    {!formik.values.billing.useBillingData && (
                      <>
                        <div className="margin--t-md">
                          <div className="row">
                            <div className="col-12">
                              <Select
                                type="radio"
                                name="billingType"
                                id="billingPF"
                                label={Locale.fields.user.pf}
                                value="pf"
                                checked={
                                  formik.values.billing.billingType === "pf"
                                }
                                onChange={e => {
                                  handleChangeBillingType(e);
                                }}
                                error={
                                  formik?.touched?.billing?.billingType &&
                                  formik?.errors?.billing?.billingType
                                }
                              />
                            </div>
                            <div className="col-12">
                              <Select
                                type="radio"
                                name="billingType"
                                id="billingPJ"
                                label={Locale.fields.user.pj}
                                value="pj"
                                checked={
                                  formik.values.billing.billingType === "pj"
                                }
                                onChange={e => {
                                  handleChangeBillingType(e);
                                }}
                                error={
                                  formik?.touched?.billing?.billingType &&
                                  formik?.errors?.billing?.billingType
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {formik.values.billing.billingType && (
                      <div className="margin--t-xs">
                        <div className="row">
                          {!formik.values.billing.useBillingData && (
                            <>
                              <div className="col-12">
                                <Input
                                  type="tel"
                                  name="billing.document"
                                  label={
                                    formik.values.billing.billingType === "pj"
                                      ? Locale.fields.user.ownersCNPJ
                                      : Locale.fields.user.CPF
                                  }
                                  value={
                                    formik.values.billing.billingType === "pj"
                                      ? MaskHelper.cnpj(
                                          formik.values.billing.document
                                        )
                                      : MaskHelper.cpf(
                                          formik.values.billing.document
                                        )
                                  }
                                  onChange={e => {
                                    formik.setValues((values: any) => ({
                                      ...values,
                                      billing: {
                                        ...values.billing,
                                        document: e.currentTarget.value
                                      }
                                    }));
                                  }}
                                  onBlur={formik.handleBlur}
                                  error={
                                    formik?.touched?.billing?.document &&
                                    formik?.errors?.billing?.document
                                  }
                                  required
                                />
                              </div>
                              <div className="col-12">
                                <Input
                                  type="text"
                                  name="billing.name"
                                  label={
                                    formik.values.billing.billingType === "pj"
                                      ? Locale.fields.user.companyName
                                      : Locale.fields.user.fullName
                                  }
                                  value={formik.values.billing.name}
                                  onChange={e =>
                                    formik.setValues((values: any) => ({
                                      ...values,
                                      billing: {
                                        ...values.billing,
                                        name: e.currentTarget.value
                                      }
                                    }))
                                  }
                                  onBlur={formik.handleBlur}
                                  error={
                                    formik?.touched?.billing?.name &&
                                    formik?.errors?.billing?.name
                                  }
                                  required
                                />
                              </div>
                              <div className="col-12">
                                <Input
                                  type="email"
                                  name="billing.email"
                                  label={Locale.fields.user.invoiceEmail}
                                  value={formik.values.billing.email}
                                  onChange={e =>
                                    formik.setValues((values: any) => ({
                                      ...values,
                                      billing: {
                                        ...values.billing,
                                        email: e.currentTarget.value
                                      }
                                    }))
                                  }
                                  onBlur={formik.handleBlur}
                                  error={
                                    formik?.touched?.billing?.email &&
                                    formik?.errors?.billing?.email
                                  }
                                  required
                                />
                              </div>
                              <div className="col-12">
                                <Input
                                  type="tel"
                                  name="billing.phoneNumber"
                                  label={Locale.fields.user.mobilePhone}
                                  value={MaskHelper.phone(
                                    formik.values.billing.phoneNumber
                                  )}
                                  onChange={e =>
                                    formik.setValues((values: any) => ({
                                      ...values,
                                      billing: {
                                        ...values.billing,
                                        phoneNumber: e.currentTarget.value
                                      }
                                    }))
                                  }
                                  onBlur={formik.handleBlur}
                                  error={
                                    formik?.touched?.billing?.phoneNumber &&
                                    formik?.errors?.billing?.phoneNumber
                                  }
                                />
                              </div>
                            </>
                          )}
                          <div className="col-12">
                            <Input
                              type="text"
                              name="ruralProducerSubscription"
                              label={
                                Locale.fields.user.ruralProducerSubscription
                              }
                              value={
                                formik.values.billing.ruralProducerSubscription
                              }
                              onChange={e =>
                                formik.setValues((values: any) => ({
                                  ...values,
                                  billing: {
                                    ...values.billing,
                                    ruralProducerSubscription:
                                      e.currentTarget.value
                                  }
                                }))
                              }
                              onBlur={formik.handleBlur}
                              error={
                                formik?.touched?.billing
                                  ?.ruralProducerSubscription &&
                                formik?.errors?.billing
                                  ?.ruralProducerSubscription
                              }
                            />
                          </div>
                          {formik.values.billing.billingType === "pj" && (
                            <>
                              <div className="col-12">
                                <Input
                                  type="text"
                                  name="stateSubscription"
                                  label={Locale.fields.user.stateSubscription}
                                  value={
                                    formik.values.billing.stateSubscription
                                  }
                                  onChange={e =>
                                    formik.setValues((values: any) => ({
                                      ...values,
                                      billing: {
                                        ...values.billing,
                                        stateSubscription: e.currentTarget.value
                                      }
                                    }))
                                  }
                                  onBlur={formik.handleBlur}
                                  error={
                                    formik?.touched?.billing
                                      ?.stateSubscription &&
                                    formik?.errors?.billing?.stateSubscription
                                  }
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </WrapperForm>
          <WrapperForm full className="margin--t-md">
            <h4 className="body-2 color--brand-primary">
              {Locale.pages.newMaterial.payment.billingAddress}
            </h4>
            <div className="container-form margin--t-md">
              <div className="row">
                <div className="col-12">
                  {formik.values.delivery.shippingMethod !== "1" && (
                    <Select
                      type="checkbox"
                      name="useDeliveryAsBillingAddress"
                      id="useDeliveryAsBillingAddress"
                      label={Locale.fields.address.addressData}
                      checked={
                        formik.values.billing.useDeliveryAsBillingAddress
                      }
                      onChange={handleBillingAddressChange}
                      disabled={!hasDeliveryAddressData()}
                    />
                  )}
                  {formik.values.billing.useDeliveryAsBillingAddress && (
                    <>
                      <div className="container-details-billing padding--t-md background-color--bw-gray-5">
                        <div className="row">
                          <div className="col-12 padding--x-md">
                            <TextInfo
                              title={Locale.fields.address.zipcode}
                              description={formik.values.billing.zipcode}
                              className="padding--x-md"
                            />
                          </div>
                          <div className="col-12 padding--x-md">
                            <TextInfo
                              title={Locale.fields.address.city}
                              description={formik.values.billing.city}
                              className="padding--x-md"
                            />
                          </div>
                          <div className="col-12 padding--x-md">
                            <TextInfo
                              title={Locale.fields.address.state}
                              description={formik.values.billing.state}
                              className="padding--x-md"
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {!formik.values.billing.useDeliveryAsBillingAddress && (
                    <div className="margin--t-md">
                      <div className="row">
                        <div className="col-12">
                          <Input
                            type="tel"
                            name="billing.zipcode"
                            label={Locale.fields.address.zipcode}
                            value={MaskHelper.cep(
                              formik.values.billing.zipcode
                            )}
                            onChange={e => {
                              formik.setValues((values: any) => ({
                                ...values,
                                billing: {
                                  ...values.billing,
                                  zipcode: e.currentTarget.value
                                }
                              }));
                              if (
                                MaskHelper.removeMask(e.currentTarget.value)
                                  .length === 8
                              ) {
                                handleZipCodeChange(
                                  MaskHelper.removeMask(e.currentTarget.value),
                                  "billing"
                                );
                              }
                            }}
                            onBlur={formik.handleBlur}
                            error={
                              formik?.touched?.billing?.zipcode &&
                              formik?.errors?.billing?.zipcode
                            }
                            required
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <Input
                            type="text"
                            name="billing.city"
                            label={Locale.fields.address.city}
                            value={formik.values.billing.city}
                            onChange={e =>
                              formik.setValues((values: any) => ({
                                ...values,
                                billing: {
                                  ...values.billing,
                                  city: e.currentTarget.value
                                }
                              }))
                            }
                            onBlur={formik.handleBlur}
                            error={
                              formik?.touched?.billing?.city &&
                              formik?.errors?.billing?.city
                            }
                            required
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <Dropdown
                            label={Locale.fields.address.state}
                            name="billing.state"
                            value={formik.values.billing.state}
                            onChange={e => {
                              formik.setValues((values: any) => ({
                                ...values,
                                billing: {
                                  ...values.billing,
                                  state: e
                                }
                              }));
                            }}
                            options={ufMock}
                            error={
                              formik?.touched?.billing?.state &&
                              formik?.errors?.billing?.state
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {(!formik.values.billing.useDeliveryAsBillingAddress ||
                    formik.values.delivery.usePostalBox) && (
                    <div className="margin--t-md">
                      <div className="row">
                        <div className="col-12 col-md-9">
                          <Input
                            type="text"
                            name="billing.address"
                            label={Locale.fields.address.street}
                            value={formik.values.billing.address}
                            onChange={e =>
                              formik.setValues((values: any) => ({
                                ...values,
                                billing: {
                                  ...values.billing,
                                  address: e.currentTarget.value
                                }
                              }))
                            }
                            onBlur={formik.handleBlur}
                            error={
                              formik?.touched?.billing?.address &&
                              formik?.errors?.billing?.address
                            }
                            required
                          />
                        </div>
                        <div className="col-12 col-md-3">
                          <Input
                            type="tel"
                            name="billing.number"
                            label={Locale.fields.address.number}
                            value={formik.values.billing.number}
                            onChange={e =>
                              formik.setValues((values: any) => ({
                                ...values,
                                billing: {
                                  ...values.billing,
                                  number: e.currentTarget.value
                                }
                              }))
                            }
                            onBlur={formik.handleBlur}
                            error={
                              formik?.touched?.billing?.number &&
                              formik?.errors?.billing?.number
                            }
                            required
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <Input
                            type="text"
                            name="billing.complement"
                            label={Locale.fields.address.complement}
                            value={formik.values.billing.complement}
                            onChange={e =>
                              formik.setValues((values: any) => ({
                                ...values,
                                billing: {
                                  ...values.billing,
                                  complement: e.currentTarget.value
                                }
                              }))
                            }
                            onBlur={formik.handleBlur}
                            error={
                              formik?.touched?.billing?.complement &&
                              formik?.errors?.billing?.complement
                            }
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <Input
                            type="text"
                            name="billing.neighborhood"
                            label={Locale.fields.address.neighborhood}
                            value={formik.values.billing.neighborhood}
                            onChange={e =>
                              formik.setValues((values: any) => ({
                                ...values,
                                billing: {
                                  ...values.billing,
                                  neighborhood: e.currentTarget.value
                                }
                              }))
                            }
                            onBlur={formik.handleBlur}
                            error={
                              formik?.touched?.billing?.neighborhood &&
                              formik?.errors?.billing?.neighborhood
                            }
                            required
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {!(
                    !formik.values.billing.useDeliveryAsBillingAddress ||
                    formik.values.delivery.usePostalBox
                  ) && (
                    <>
                      <div className="container-details-billing padding--t-md background-color--bw-gray-5">
                        <div className="row">
                          <div className="col-12 padding--x-md">
                            <TextInfo
                              title={Locale.fields.address.street}
                              description={formik.values.billing.address}
                              className="padding--x-md"
                            />
                          </div>
                          <div className="col-12 padding--x-md">
                            <TextInfo
                              title={Locale.fields.address.number}
                              description={formik.values.billing.number}
                              className="padding--x-md"
                            />
                          </div>
                          <div className="col-12 padding--x-md">
                            <TextInfo
                              title={Locale.fields.address.complement}
                              description={formik.values.billing.complement}
                              className="padding--x-md"
                            />
                          </div>
                          <div className="col-12 padding--x-md">
                            <TextInfo
                              title={Locale.fields.address.neighborhood}
                              description={formik.values.billing.neighborhood}
                              className="padding--x-md"
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </WrapperForm>
        </Template>
        <StickyFooter>
          <div className="flex-justify--center">
            <div className="col-12 col-md-8">
              <Button
                title="Enviar"
                fullWidth
                type="submit"
                isLoading={formik.isSubmitting}
              />
            </div>
          </div>
        </StickyFooter>
      </form>
    </>
  );
};

export default Profile;
