const associationOptions = [
  "Nenhum convênio",
  "Associação ES",
  "Girolando",
  "ACGHMG",
  "Clube de Compras Nestlé",
  "Embaré",
  "MSD",
  "Danone",
  "CCPR",
  "Tirolez",
  "Zoetis",
  "Aplicc",
  "Piracanjuba",
  "Proleite",
  "Aproleite",
  "VR Veterinaria - Orizona GO",
  "Scala",
  "Lat Verde Campo",
  "ACPGLES - Santa Teresa",
  "Cooprata",
  "Procria",
  "Cooperita",
  "LA VACA - Supra Saúde Animal",
  "De Heus",
  "Ind Lat Vale dos Buritis",
  "Usina Paiolzinho",
  "CARGIL",
  "Lala",
  "Coronata",
  "Nova Mix - Vazante",
  "COPERLEITE",
  "Comevap",
  "Coopersete",
  "Abraleite",
  "COOPA",
  "ACPGLES - Cachoeiro",
  "Lat.Alhambra",
  "Polenghi",
  "Recria",
  "Capal",
  "Agroman",
  "CEMIL PIS / Cofins",
  "Coopatos",
  "Damare",
  "DPA",
  "Via Verde Consultoria",
  "Vetsell",
  "Provet",
  "Coop Serramar",
  "FRUTAP",
  "PROSYNC",
  "Lat PJ",
  "Nughoman",
  "Coopervap",
  "Italac",
  "Coocafé",
  "Yoguedes",
  "Coop Serrania",
  "ConsuLeite",
  "Lat.Minas Colonial",
  "QCONZ",
  "NATUVET",
  "Foco Consultoria",
  "GEPEC",
  "Agromilk",
  "APROCAN",
  "Sg Lac",
  "ARC",
  "DAVACA",
  "Laticinios Vitória"
].map((label, index) => ({ label, value: index === 0 ? "0" : label })); // TODO: Alterar para passar valor nulo
// TODO: Adicionar chamada de API quando tiver API
export type analyses = {
  cbt: boolean;
  atb: boolean;
};
const analysisTypes: Array<keyof analyses> = ["cbt", "atb"];
export { associationOptions, analysisTypes };
