import { useState } from "react";
import { Button, Card, ContainerMain, Input, Template } from "components";
import { useFormik } from "formik";
import Service from "services";
import { ErrorHelper, MaskHelper, ObscureHelper } from "helpers";
import * as Yup from "yup";
import Locale from "locale";
import { cpf as validateCPF, cnpj as validateCNPJ } from "cpf-cnpj-validator";
import { FormProps } from "./types";

import "./styles.scss";

const PasswordReset = () => {
  const [validateIdentity, setValidateIdentity] = useState<string | null>(null);

  type RequestPasswordResetEmailParams = {
    cpf?: string;
    cnpj?: string;
    email: string;
  };

  const handleSubmit = async (data: FormProps) => {
    const document = MaskHelper.removeMask(data.document);
    const requestParams = {
      email: data.email
    } as RequestPasswordResetEmailParams;
    if (document.length === 11) {
      requestParams.cpf = document;
    } else if (document.length === 14) {
      requestParams.cnpj = document;
    }

    Service.sendResetPasswordEmail(requestParams)
      .then(() => {
        setValidateIdentity(requestParams.email);
      })
      .catch(e => {
        ErrorHelper.notifyError(e);
      });
  };
  const formik = useFormik({
    initialValues: { document: "", email: "" },
    validationSchema: Yup.object().shape({
      document: Yup.string()
        .required(Locale.errors.required)
        .test(
          "is-cpf-or-cnpj",
          Locale.errors.document,
          (value: string | undefined) =>
            validateCPF.isValid(value ?? "") ||
            validateCNPJ.isValid(value ?? "")
        )
    }),
    onSubmit: data => {
      handleSubmit(data);
    }
  });
  const banner = (
    <div className="background">
      <ContainerMain withContainer>
        <Card>
          <div className="container-content">
            {validateIdentity ? (
              <>
                <div className="heading-1">
                  {Locale.pages.passwordReset.validationCard.title}
                </div>
                <div className=" card-description">
                  {Locale.pages.passwordReset.validationCard.description}
                </div>
                <div className=" card-description">
                  {ObscureHelper.obscureEmail(validateIdentity)}
                </div>
              </>
            ) : (
              <>
                <div className="heading-1">{Locale.actions.resetPassword}</div>
                <div className=" card-description">
                  {Locale.pages.passwordReset.description}
                </div>
                <form className="form-document" onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <Input
                      type="tel"
                      name="document"
                      label={Locale.fields.user.document}
                      value={MaskHelper.cpfCnpj(formik.values.document)}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.document && formik.errors.document}
                    />
                    <Input
                      type="email"
                      name="email"
                      label={Locale.fields.user.email}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.email && formik.errors.email}
                    />
                  </div>
                  <Button type="submit" title={Locale.actions.send} />
                </form>
              </>
            )}
          </div>
        </Card>
      </ContainerMain>
    </div>
  );

  return (
    <Template banner={banner}>
      <></>
    </Template>
  );
};

export default PasswordReset;
