import { Button, Card, Image, Template } from "components";
import "./styles.scss";
import successImage from "assets/images/image-success.svg";
import { useNavigate, useLocation } from "react-router-dom";
import Locale from "locale";

interface PaymentMethodData {
  paymentMethod?: "boleto" | "credit_card";
  sponsorPaid?: boolean;
  boletoUrl?: string;
}

const Success = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const paymentMethod = (location?.state as PaymentMethodData)?.paymentMethod;
  const sponsorPaid = (location?.state as PaymentMethodData)?.sponsorPaid;
  const boletoUrl = (location?.state as PaymentMethodData)?.boletoUrl;

  const getDescription = () => {
    if (sponsorPaid) {
      return Locale.pages.success.descriptionCreditCard;
    }

    return paymentMethod && paymentMethod === "boleto"
      ? Locale.pages.success.descriptionBankSlip
      : Locale.pages.success.descriptionCreditCard;
  };
  return (
    <Template className="bg-banner-success margin-y">
      <Card className="card-success">
        <div className="stack--md card-content">
          <Image src={successImage} />
          <div className="heading-1">{Locale.pages.success.title}</div>
          <div className="body-2 color--bw-gray">{getDescription()}</div>
          {boletoUrl && <a href={boletoUrl}>Clique aqui para abrir o boleto</a>}
          <div>
            <Button
              title={Locale.actions.goToPortal}
              onClick={() => {
                window.location.href =
                  "https://www.clinicadoleite.com.br/portalclinica";
                navigate("/");
              }}
            />
          </div>
        </div>
      </Card>
    </Template>
  );
};

export default Success;
