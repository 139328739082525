import { resetPasswordPath } from "../router";
import { get, post } from "./axios";

const UserService = {
  passwordReset(paramData: any) {
    const fullData = {
      ...paramData,
      path: resetPasswordPath
    };
    return post("password-reset/", fullData);
  },

  passwordResetConfirm(data: any) {
    return post("password-reset-confirm/", data);
  },

  getUserCurrent() {
    return get("user/current/");
  },

  getUserClient(id: string) {
    return get(`user/${id}/client`);
  },

  getUserRedirect() {
    return get("user/should_redirect/");
  }
};

export default UserService;
