import clsx from "clsx";

import IconProps, { iconNames } from "./types";

const Icon = (props: IconProps): JSX.Element => {
  const { name, className, ...rest }: IconProps = props;

  const IconElement = iconNames[name];

  return <IconElement className={clsx("icon", className)} {...rest} />;
};

export default Icon;
