import { Button, Image, Input, Template, WrapperForm } from "components";
import { useFormik } from "formik";
import Locale from "locale";
import * as Yup from "yup";
import "./styles.scss";
import RequestPasswordImage from "assets/images/request-password.png";
import { cpf as validateCPF, cnpj as validateCNPJ } from "cpf-cnpj-validator";
import { useEffect } from "react";
import notify from "components/Toast";
import { ErrorHelper } from "helpers";

const RequestPassword = () => {
  const initialValues = {
    document: ""
  };

  const validationSchema = Yup.object().shape({
    document: Yup.string()
      .required(Locale.errors.required)
      .test(
        "is-cpf-or-cnpj",
        Locale.errors.document,
        (value: string | undefined) =>
          validateCPF.isValid(value ?? "") || validateCNPJ.isValid(value ?? "")
      )
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: values => {
      Promise.resolve() // TODO: fazer chamada pra API
        .then(() => {
          notify("Enviamos um link para a criação de senha");
        })
        .catch((error: any) => ErrorHelper.notifyError(error));
    }
  });

  useEffect(() => {
    (() => formik.validateForm())();
  }, []);

  return (
    <Template>
      <WrapperForm full className="flex-justify--center">
        <form
          className="stack--md request-password-container"
          onSubmit={formik.handleSubmit}
        >
          <Image src={RequestPasswordImage} alt="" />
          <div className="heading-1 text-align--center">
            {Locale.pages.requestPassword.title}
          </div>
          <div className="body-2 color--bw-gray-dark text-align--center">
            {Locale.pages.requestPassword.description}
          </div>
          <Input
            label="CPF ou CNPJ"
            type="tel"
            name="document"
            value={formik.values.document}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={formik.touched.document && formik.errors.document}
          />
          <Button
            type="submit"
            title={Locale.actions.send}
            disabled={!formik.isValid}
          />
        </form>
      </WrapperForm>
    </Template>
  );
};

export default RequestPassword;
