import { useContext, useEffect } from "react";
import { Navigate, Routes, useLocation } from "react-router-dom";

import {
  Login,
  UserRegister,
  Payment,
  Address,
  Analysis,
  RegisterPassword,
  RequestPassword,
  Success,
  Home,
  LandingPage,
  Verification,
  PasswordReset,
  Association,
  Profile
} from "pages";
import { AuthContext } from "contexts/auth";
import { clearAllToasts } from "components/Toast";
import AuthGuard from "./AuthGuard";

export const resetPasswordPath = "reset-password";

const Routers = () => {
  const { isAuthenticated, isVerified } = useContext(AuthContext);

  const location = useLocation();
  useEffect(() => {
    clearAllToasts();
  }, [location.key]);

  return (
    <Routes>
      {AuthGuard({
        path: "/",
        element: <Home />
      })}
      {AuthGuard({
        path: "/landing-page",
        element: <LandingPage />
      })}
      {AuthGuard({
        path: "/login",
        element: <Login />
      })}
      {AuthGuard({
        path: "/login/password-reset",
        element: <PasswordReset />
      })}
      {AuthGuard({
        path: "/register/user",
        element: <UserRegister />
      })}
      {AuthGuard({
        path: "/register/verification",
        element: <Verification />
      })}
      {AuthGuard({
        path: "/profile",
        element: <Profile />
      })}
      {AuthGuard({
        path: `/request-password`,
        element: <RequestPassword />
      })}
      {AuthGuard({
        path: `/password-reset/:uidb64/:token`,
        element: <RegisterPassword />
      })}
      {AuthGuard({
        path: "/material/new",
        element: <Navigate to="/material/new/analysis" />
      })}
      {AuthGuard({
        path: "/material/new/analysis",
        element: <Analysis />,
        isUserAuthenticated: isAuthenticated() && isVerified(),
        navigateTo: "/"
      })}
      {AuthGuard({
        path: "/material/new/association",
        element: <Association />,
        isUserAuthenticated: isAuthenticated() && isVerified(),
        navigateTo: "/"
      })}
      {AuthGuard({
        path: "/material/new/address",
        element: <Address />,
        isUserAuthenticated: isAuthenticated() && isVerified(),
        navigateTo: "/"
      })}
      {AuthGuard({
        path: "/material/new/payment",
        element: <Payment />,
        isUserAuthenticated: isAuthenticated() && isVerified(),
        navigateTo: "/"
      })}
      {AuthGuard({
        path: "/material/new/success",
        element: <Success />,
        isUserAuthenticated: isAuthenticated() && isVerified(),
        navigateTo: "/"
      })}
    </Routes>
  );
};

export default Routers;
