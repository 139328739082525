import clsx from "clsx";

import TextInfoProps from "./types";

import "./styles.scss";

const TextInfo = (props: TextInfoProps) => {
  const { title, description, className, ...args } = props;
  return (
    <div className={clsx(`container-text-label ${className}`)} {...args}>
      {title && <p className="caption-2 color--bw-gray-1">{title}</p>}
      {description && <p className="body-2">{description}</p>}
    </div>
  );
};

TextInfo.defaultProps = {
  label: "",
  value: ""
};

export default TextInfo;
