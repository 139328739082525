import { Banner, Button, Card, Icon, Template } from "components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import bgImage from "assets/images/bg-banner.png";
import bgImageMobile from "assets/images/bg-banner-mobile.png";

import "swiper/css";
import "swiper/css/pagination";

import "./styles.scss";
import Locale from "locale";

const Services = () => {
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleWindowResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const pagination = {
    clickable: true,
    renderBullet(index: number, className: string) {
      return `<span class="${className}"/></span>`;
    }
  };

  const cardData = (size: number) => (
    <Card>
      <Icon name="data" width={size} height={size} />
      <div className="card-description">
        {Locale.pages.services.howItWorks.step.data}
      </div>
    </Card>
  );
  const cardKit = (size: number) => (
    <Card>
      <Icon name="vehicle" width={size} height={size} />
      <div className="card-description">
        {Locale.pages.services.howItWorks.step.kit}
      </div>
    </Card>
  );
  const cardResults = (size: number) => (
    <Card>
      <Icon name="results" width={size} height={size} />
      <div className="card-description">
        {Locale.pages.services.howItWorks.step.results}
      </div>
    </Card>
  );

  return (
    <Template
      banner={
        <Banner
          title={Locale.pages.services.farm}
          description={Locale.pages.services.mission}
          image={windowWidth > 770 ? bgImage : bgImageMobile}
        />
      }
    >
      <div className="how-it-works">
        {Locale.pages.services.howItWorks.title}
      </div>
      {windowWidth > 770 ? (
        <div className="cards-container">
          {cardData(56)}
          {cardKit(56)}
          {cardResults(56)}
        </div>
      ) : (
        <Swiper
          slidesPerView={1}
          loop
          pagination={pagination}
          modules={[Pagination]}
          className="services-swiper"
        >
          <SwiperSlide>{cardData(48)}</SwiperSlide>
          <SwiperSlide>{cardKit(48)}</SwiperSlide>
          <SwiperSlide>{cardResults(48)}</SwiperSlide>
        </Swiper>
      )}
      <div className="button-div margin--b-xxl">
        <Button
          className="button-begin"
          title="Vamos começar"
          onClick={() => {
            navigate("/landing-page");
          }}
        />
      </div>
    </Template>
  );
};

export default Services;
