import Icon from "components/Icon";
import BottomTagProps from "./types";

import "./styles.scss";

const BottomTag = (props: BottomTagProps): JSX.Element => {
  const { title, detail, icon } = props;
  return (
    <a
      href="https://www.clinicadoleite.com.br/#contato"
      target="_blank"
      rel="noreferrer"
    >
      <div className="container-questions">
        {icon && <Icon name={icon} width={40} height={40} />}
        <div>
          <div className="heading-2 color--bw-white">{title}</div>
          <div className="body-2 color--bw-white">{detail}</div>
        </div>
      </div>
    </a>
  );
};

export default BottomTag;
