import LocalizedStrings from "react-localization";
// import { enUS } from "./enUS";
import { ptBR } from "./ptBR";

const Locale = new LocalizedStrings({
  // en: enUS,
  pt: ptBR
});

export default Locale;
