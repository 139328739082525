/* eslint-disable react/no-danger */
import clsx from "clsx";

import { ContainerMain } from "components";

import BannerProps from "./types";

import "./styles.scss";

const Banner = (props: BannerProps): JSX.Element => {
  const { title, description, theme, size, image, withContainer } = props;

  return (
    <div
      className={clsx("wrapper-banner", {
        "theme-light": theme === "light",
        "theme-dark": theme === "dark",
        regular: size === "regular",
        small: size === "small"
      })}
      style={{ backgroundImage: `url(${image})` }}
    >
      <ContainerMain
        className="flex--column flex-justify--center"
        withContainer={withContainer}
      >
        <h2>{title}</h2>
        <p
          dangerouslySetInnerHTML={{
            __html: String(description || "")
          }}
        />
      </ContainerMain>
    </div>
  );
};

Banner.defaultProps = {
  theme: "light",
  size: "regular",
  withContainer: true
};

export default Banner;
