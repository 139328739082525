import { WrapperForm, Input } from "components";

import Locale from "locale";
import { MaskHelper } from "helpers";

const FinancialResponsible = (props: any): JSX.Element => {
  const { formik } = props;

  return (
    <WrapperForm full className="margin--t-md">
      <h4 className="body-2 color--brand-primary">
        {Locale.pages.newMaterial.payment.financialResponsible}
      </h4>
      <div className="container-form margin--t-md">
        <div className="row">
          <div className="col-12">
            <div className="margin--t-md">
              <div className="row">
                <div className="col-12">
                  <Input
                    type="text"
                    name="financialResponsible.name"
                    label={Locale.fields.financialResponsible.name}
                    onChange={e =>
                      formik.setValues((values: any) => ({
                        ...values,
                        financialResponsible: {
                          ...values.financialResponsible,
                          name: e.currentTarget.value
                        }
                      }))
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik?.touched?.financialResponsible?.name &&
                      formik?.errors?.financialResponsible?.name
                    }
                    required
                  />
                </div>
                <div className="col-12">
                  <Input
                    type="text"
                    name="financialResponsible.email"
                    label={Locale.fields.financialResponsible.email}
                    onChange={e =>
                      formik.setValues((values: any) => ({
                        ...values,
                        financialResponsible: {
                          ...values.financialResponsible,
                          email: e.currentTarget.value
                        }
                      }))
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik?.touched?.financialResponsible?.email &&
                      formik?.errors?.financialResponsible?.email
                    }
                    required
                  />
                </div>
                <div className="col-12">
                  <Input
                    type="text"
                    name="financialResponsible.mobilePhone"
                    label={Locale.fields.financialResponsible.mobilePhone}
                    value={MaskHelper.phone(
                      formik.values.financialResponsible.mobilePhone
                    )}
                    onChange={e =>
                      formik.setValues((values: any) => ({
                        ...values,
                        financialResponsible: {
                          ...values.financialResponsible,
                          mobilePhone: e.currentTarget.value
                        }
                      }))
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik?.touched?.financialResponsible?.mobilePhone &&
                      formik?.errors?.financialResponsible?.mobilePhone
                    }
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WrapperForm>
  );
};

export default FinancialResponsible;
