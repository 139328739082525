import React, { useState, useRef } from "react";
import clsx from "clsx";

import { Icon, Search } from "components";
import { outsideClick } from "utils/functions";
import DropdownProps from "./types";

import "./styles.scss";

const Dropdown = (props: DropdownProps): JSX.Element => {
  const {
    id,
    name,
    label,
    open,
    options,
    autocomplete,
    value,
    onChange,
    error,
    dropup,
    disabled = false,
    ...rest
  }: DropdownProps = props;
  const wrapperDropdown = useRef(null);

  const selectedOption = options?.find(item => item.value === value);

  const [isOpen, setIsOpen] = useState(open);

  const handleOpenSelect = () => {
    if (!disabled) {
      setIsOpen(prev => !prev);
    }
  };

  const [autocompleteValue, setAutocompleteValue] = useState("");

  const onChangeAutocomplete = (e: React.FormEvent<HTMLInputElement>) => {
    setAutocompleteValue(e.currentTarget.value);
  };

  const filled = !!value;

  outsideClick(wrapperDropdown, () => setIsOpen(false));

  return (
    <div
      className={clsx("dropdown-wrapper", {
        "dropdown-wrapper-error": error
      })}
      ref={wrapperDropdown}
      {...rest}
    >
      <div
        className={clsx("dropdown-input", {
          "dropdown-input-open": isOpen,
          disabled
        })}
        onClick={handleOpenSelect}
      >
        <div>
          <span
            className={clsx("dropdown-label", {
              "dropdown-label-filled": filled
            })}
          >
            {label}
          </span>
          <div>
            <span className="dropdown-value">{selectedOption?.label}</span>
          </div>
        </div>
        <Icon
          className={clsx({ "dropdown-icon-open": isOpen })}
          name="arrowDown"
        />

        <input
          type="hidden"
          id={id}
          name={name}
          value={selectedOption?.value}
        />
      </div>
      {isOpen && (
        <div
          className={clsx("dropdown-container", {
            "dropup-container": dropup
          })}
        >
          {autocomplete && (
            <Search
              placeholder="Buscar"
              value={autocompleteValue}
              onChange={onChangeAutocomplete}
            />
          )}
          {options &&
            options
              .filter(item =>
                item.label
                  .toLowerCase()
                  .includes(autocompleteValue.toLowerCase())
              )
              .map(item => (
                <div
                  key={item.label}
                  className={clsx("dropdown-option", {
                    "selected-dropdown-option": selectedOption === item
                  })}
                  onClick={() => {
                    onChange && onChange(item.value);
                    setIsOpen(false);
                  }}
                >
                  {item.label}
                </div>
              ))}
        </div>
      )}
      {error && <p className="text error">{error}</p>}
      {/* {help && <p className="text helper">{help}</p>} */}
    </div>
  );
};

Dropdown.defaultProps = {
  variant: "neutral"
};

export default Dropdown;
