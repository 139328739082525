import { useEffect, useContext } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import {
  Button,
  HeaderPage,
  Input,
  Select,
  StickyFooter,
  Template,
  WrapperForm
} from "components";

import { analysisTypes } from "consts/analysis";
import Locale from "locale";
import notify from "components/Toast";
import { addItemsCart, formatAnalysisLabelRequest } from "utils/functions";
import { FieldStrings } from "locale/LocaleStrings";
import NewMaterialContext from "../NewMaterialContext";

import "./styles.scss";

const getKey = (idx: number) => `analysisTypes-${idx}`;

const Analysis = (): JSX.Element => {
  const navigate = useNavigate();
  const contextData = useContext(NewMaterialContext);

  const validationSchema = Yup.object().shape({
    analysis: Yup.object().shape(
      {
        animalCount: Yup.number()
          .transform((curr, orig) => (orig === "" ? null : curr))
          .typeError(Locale.errors.numeric)
          .integer(Locale.errors.integer)
          .moreThan(-1, Locale.errors.positive)
          .max(4000, Locale.errors.maxValue.replace("{}", "4000"))
          .when("tankCount", {
            is: (value: string) => !value || value === "0",
            then: Yup.number()
              .required(Locale.errors.required)
              // TODO evitar duplicação de código
              .transform((curr, orig) => (orig === "" ? null : curr))
              .typeError(Locale.errors.numeric)
              .moreThan(0, Locale.errors.positive)
              .max(4000, Locale.errors.maxValue.replace("{}", "4000"))
          }),
        tankCount: Yup.number()
          .transform((curr, orig) => (orig === "" ? null : curr))
          .typeError(Locale.errors.numeric)
          .integer(Locale.errors.integer)
          .moreThan(-1, Locale.errors.positive)
          .max(4000, Locale.errors.maxValue.replace("{}", "100"))
          .when("animalCount", {
            is: (value: string) => !value || value === "0",
            then: Yup.number()
              .required(Locale.errors.required)
              // TODO evitar duplicação de código
              .typeError(Locale.errors.numeric)
              .transform((curr, orig) => (orig === "" ? null : curr))
              .moreThan(0, Locale.errors.positive)
              .max(4000, Locale.errors.maxValue.replace("{}", "100"))
          })
          .when("analyses", {
            is: (value: any) => value.cbt || value.abt,
            then: Yup.number()
              .required(Locale.errors.required)
              // TODO evitar duplicação de código
              .typeError(Locale.errors.numeric)
              .transform((curr, orig) => (orig === "" ? null : curr))
              .moreThan(0, Locale.errors.positive)
              .max(4000, Locale.errors.maxValue.replace("{}", "100"))
          }),
        analyses: Yup.object()
          .shape({
            cbt: Yup.boolean(),
            atb: Yup.boolean()
          })
          .when("tankCount", {
            is: (value: string) => value,
            then: Yup.object().test(
              "one of the options",
              Locale.errors.chooseOne,
              values => Object.values(values).some(value => value)
            )
          })
      },
      [
        ["animalCount", "tankCount"],
        ["analyses", "tankCount"]
      ]
    )
  });

  const formik = useFormik({
    initialValues: contextData,
    validationSchema,
    onSubmit: data => {
      contextData.analysis = data.analysis;
      contextData.summary = data.summary;
      navigate("/material/new/association");
    }
  });

  useEffect(() => {
    addItemsCart(formik);
  }, [formik.isValidating]);

  useEffect(() => {
    let notifyMessage = "";
    const labels: string[] = [];
    if (formik.errors?.analysis) {
      (
        Object.keys(
          formik.errors?.analysis
        ) as (keyof FieldStrings["analysis"])[]
      ).forEach(key => {
        if (Locale.fields.analysis[key]) {
          labels.push(Locale.fields.analysis[key]);
        }
      });
    }

    notifyMessage = labels.join(", ");
    if (notifyMessage) {
      notify(`Existe um erro nos seguintes campos: ${notifyMessage}`);
    }
  }, [formik.submitCount]);

  return (
    <NewMaterialContext.Provider value={contextData}>
      <Template
        formik={formik}
        cart
        notification={formik.dirty}
        className="margin-y"
      >
        <HeaderPage
          title={Locale.pages.newMaterial.analysis.title}
          description={Locale.pages.newMaterial.analysis.description}
          goBack={() => {
            navigate("/");
          }}
        />
        <form onSubmit={formik.handleSubmit} className="width--full">
          <fieldset disabled={formik.isSubmitting}>
            <WrapperForm full className="stack--md width--full ">
              <div className="body-2 color--brand-primary">
                {Locale.fields.analysis.animalData}
              </div>
              <Input
                label={Locale.fields.analysis.animalCount}
                name="animalCount"
                type="tel"
                value={formik.values.analysis.animalCount}
                tooltip={{
                  title: "Exemplo",
                  body: "Para 30 animais e 2 tanques, preencher o campo com o valor 32"
                }}
                onBlur={formik.handleBlur}
                onChange={e => {
                  formik.setValues((values: any) => ({
                    ...values,
                    analysis: {
                      ...values.analysis,
                      animalCount: e.currentTarget.value
                    }
                  }));
                }}
                error={
                  formik.touched.analysis?.animalCount &&
                  formik.errors.analysis?.animalCount
                }
                required
              />
            </WrapperForm>
            <WrapperForm
              full
              className="stack--md width--full analysis-options-container margin--t-md"
            >
              <div className="body-2 color--brand-primary">
                {Locale.fields.analysis.tankData}
              </div>
              <Input
                label={Locale.fields.analysis.tankCount}
                name="tankCount"
                type="tel"
                value={formik.values.analysis.tankCount}
                onBlur={formik.handleBlur}
                onChange={e => {
                  formik.setValues((values: any) => ({
                    ...values,
                    analysis: {
                      ...values.analysis,
                      tankCount: e.currentTarget.value
                    }
                  }));
                }}
                error={
                  formik.touched.analysis?.tankCount &&
                  formik.errors.analysis?.tankCount
                }
                required
              />
              {analysisTypes.map((analysisType, idx) => (
                <Select
                  key={getKey(idx)}
                  type="checkbox"
                  id={analysisType}
                  name={analysisType}
                  checked={formik.values.analysis.analyses[analysisType]}
                  label={formatAnalysisLabelRequest(
                    Locale.analyses[analysisType].title
                  )}
                  detail={Locale.analyses[analysisType].subtitle}
                  onChange={e => {
                    formik.setValues((values: any) => ({
                      ...values,
                      analysis: {
                        ...values.analysis,
                        analyses: {
                          ...values.analysis.analyses,
                          [analysisType]: e.target.checked
                        }
                      }
                    }));
                  }}
                  error={
                    formik.touched.analysis?.analyses &&
                    formik.errors.analysis?.analyses &&
                    (formik.errors.analysis?.analyses as string)
                  }
                />
              ))}
            </WrapperForm>
          </fieldset>
          <StickyFooter>
            <div className="flex-justify--center">
              <div className="col-12 col-md-8">
                <Button
                  title={Locale.actions.nextStep}
                  fullWidth
                  type="submit"
                />
              </div>
            </div>
          </StickyFooter>
        </form>
      </Template>
    </NewMaterialContext.Provider>
  );
};

export default Analysis;
