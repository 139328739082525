import { useState } from "react";

import { useTimer } from "react-timer-hook";
import "./styles.scss";
import { BottomTag, Button, Card, Code, ItemList, Template } from "components";
import { ErrorHelper, MaskHelper, ObscureHelper } from "helpers";
import { useLocation } from "react-router-dom";
import { OnboardingData } from "services/onboarding";
import Service from "services";
import Locale from "locale";

type CommunicationProps = {
  option: string;
  content: string;
};

type StateProps = {
  onboardingData: OnboardingData;
};
const Verification = () => {
  const location = useLocation();
  const state = location.state as StateProps;
  const { onboardingData } = state;

  const time = new Date();
  time.setSeconds(time.getSeconds() + 90);
  const { seconds, minutes, isRunning, restart } = useTimer({
    expiryTimestamp: time
  });
  const [step, setStep] = useState("email"); // alterar para "first" quando os outros métodos forem implementados
  const [communication, setCommunication] = useState({
    // alterar para "" quando os outros métodos forem implementados
    option: "email",
    content: onboardingData.user.email
  });

  const handleFirstStepClick = (option: string, content: string) => {
    restart(time);
    setStep(option);
    setCommunication({ option, content });
  };

  const handleResend = (type: string) => {
    if (type === "email") {
      restart(time);
      Service.resendConfirmationEmail({
        email: onboardingData.user.email
      }).catch(error => ErrorHelper.notifyError(error));
    }
  };

  const firstStep = () => (
    <>
      <p className="body-2">{Locale.pages.verification.chooseOption}</p>
      <ItemList
        hasSufix
        icon="email"
        title="E-mail"
        detail={ObscureHelper.obscureEmail(onboardingData.user.email)}
        onClick={() => {
          handleFirstStepClick("email", onboardingData.user.email);
        }}
      />
      <ItemList
        hasSufix
        icon="whatsapp"
        title="Whatsapp"
        detail={ObscureHelper.obscurePhone(
          MaskHelper.phone(onboardingData.user.mobile)
        )}
        onClick={() => {
          handleFirstStepClick(
            "whatsapp",
            MaskHelper.phone(onboardingData.user.mobile)
          );
        }}
      />
      <ItemList
        hasSufix
        icon="sms"
        title="SMS"
        detail={ObscureHelper.obscurePhone(
          MaskHelper.phone(onboardingData.user.mobile)
        )}
        onClick={() => {
          handleFirstStepClick(
            "sms",
            MaskHelper.phone(onboardingData.user.mobile)
          );
        }}
      />
    </>
  );

  const emailValidadation = (email: string) => (
    <>
      <div className="body-2">{Locale.pages.verification.email}</div>
      <div className="button-2 email">{ObscureHelper.obscureEmail(email)}</div>
      <Button
        className="no-style resend"
        size="small"
        variant="tertiary"
        onClick={() => {
          handleResend("email");
        }}
        disabled={isRunning}
      >
        {isRunning
          ? Locale.pages.verification.resend.wait
              .replace("{option}", "email")
              .replace(
                "{minutes}",
                String(minutes >= 10 ? minutes : `0${minutes}`)
              )
              .replace(
                "{seconds}",
                String(seconds >= 10 ? seconds : `0${seconds}`)
              )
          : Locale.pages.verification.resend.ask.replace("{option}", "email")}
      </Button>
    </>
  );
  const mobileValidation = (communication: CommunicationProps) => (
    <>
      <p className="body-2">
        {Locale.pages.verification.mobile
          .replace("{option}", communication.option)
          .replace(
            "{mobile}",
            ObscureHelper.obscurePhone(communication.content)
          )}
      </p>
      <Code />
      <div className="container-buttons">
        <Button>{Locale.actions.continue}</Button>
        <Button
          className="no-style"
          size="small"
          variant="tertiary"
          disabled={isRunning}
        >
          {isRunning
            ? Locale.pages.verification.resend.wait
                .replace("{option}", "código")
                .replace(
                  "{minutes}",
                  String(minutes >= 10 ? minutes : `0${minutes}`)
                )
                .replace(
                  "{seconds}",
                  String(seconds >= 10 ? seconds : `0${seconds}`)
                )
            : Locale.pages.verification.resend.ask.replace(
                "{option}",
                "código"
              )}
        </Button>
      </div>
    </>
  );
  return (
    <>
      <Template className="verification-page">
        <Card>
          <h1 className="heading-1">{Locale.pages.verification.title}</h1>
          {
            {
              first: firstStep(),
              email: emailValidadation(communication.content),
              whatsapp: mobileValidation(communication),
              sms: mobileValidation(communication)
            }[step]
          }
        </Card>

        {step !== "first" && (
          <div className="container-outside-button">
            {/* <Button
              onClick={() => {
                setStep("first");
              }}
              className="no-style"
              size="small"
              variant="tertiary"
            >
              Enviar de outra forma
            </Button> */}
          </div>
        )}
      </Template>
      <BottomTag title="Dúvidas?" detail="Fale com a gente" icon="chat" />
    </>
  );
};

export default Verification;
