import clsx from "clsx";

import { Checkbox, Radio } from "components";
import SelectProps from "./types";

import "./styles.scss";

const Select = (props: SelectProps): JSX.Element => {
  const { type, checked, detail, disabled, id, error, ...args } = props;

  return (
    <>
      <label
        className={clsx("container-select", {
          "container-select-checked": checked,
          "container-select-detail": !!detail,
          "container-select-disabled": disabled,
          "container-select-error": error
        })}
        htmlFor={id}
      >
        {type === "radio" ? (
          <Radio
            {...args}
            checked={checked}
            error={error}
            disabled={disabled}
            id={id}
          />
        ) : (
          <Checkbox
            {...args}
            checked={checked}
            error={error}
            disabled={disabled}
            id={id}
          />
        )}
        {detail && <span className="select-detail">{detail}</span>}
      </label>
      {error && <p className="error-message">{error}</p>}
    </>
  );
};

export default Select;
