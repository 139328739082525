import clsx from "clsx";

import WrapperFormProps from "./types";

import "./styles.scss";

const WrapperForm = (props: WrapperFormProps): JSX.Element => {
  const { full, children, className } = props;

  return (
    <div
      className={clsx("wrapper-form", className, {
        full
      })}
    >
      {children}
    </div>
  );
};

export default WrapperForm;
