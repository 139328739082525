import { ReactComponent as arrowDown } from "assets/icons/arrow-down.svg";
import { ReactComponent as arrowUp } from "assets/icons/arrow-up.svg";
import { ReactComponent as book } from "assets/icons/book.svg";
import { ReactComponent as car } from "assets/icons/car.svg";
import { ReactComponent as card } from "assets/icons/card.svg";
import { ReactComponent as chat } from "assets/icons/chat.svg";
import { ReactComponent as check } from "assets/icons/check.svg";
import { ReactComponent as chevronDown } from "assets/icons/chevron-down.svg";
import { ReactComponent as chevronLeft } from "assets/icons/chevron-left.svg";
import { ReactComponent as chevronRight } from "assets/icons/chevron-right.svg";
import { ReactComponent as chevronUp } from "assets/icons/chevron-up.svg";
import { ReactComponent as email } from "assets/icons/email.svg";
import { ReactComponent as eye } from "assets/icons/eye.svg";
import { ReactComponent as eyeOff } from "assets/icons/eye-off.svg";
import { ReactComponent as farmer } from "assets/icons/farmer.svg";
import { ReactComponent as industry } from "assets/icons/industry.svg";
import { ReactComponent as menu } from "assets/icons/menu.svg";
import { ReactComponent as minus } from "assets/icons/minus.svg";
import { ReactComponent as phone } from "assets/icons/phone.svg";
import { ReactComponent as pin } from "assets/icons/pin.svg";
import { ReactComponent as question } from "assets/icons/question.svg";
import { ReactComponent as results } from "assets/icons/results.svg";
import { ReactComponent as search } from "assets/icons/search.svg";
import { ReactComponent as sms } from "assets/icons/sms.svg";
import { ReactComponent as user } from "assets/icons/user.svg";
import { ReactComponent as whatsapp } from "assets/icons/whatsapp.svg";
import { ReactComponent as data } from "assets/icons/data.svg";
import { ReactComponent as vehicle } from "assets/icons/vehicle.svg";
import { ReactComponent as chevLeft } from "assets/icons/chev-left.svg";
import { ReactComponent as cart } from "assets/icons/cart.svg";
import { ReactComponent as close } from "assets/icons/close.svg";

import { ReactComponent as paymentCard } from "assets/icons/payment-card.svg";

const iconNames = {
  arrowDown,
  arrowUp,
  book,
  car,
  card,
  chat,
  close,
  check,
  chevronDown,
  chevronLeft,
  chevronRight,
  chevronUp,
  email,
  eye,
  eyeOff,
  farmer,
  industry,
  menu,
  minus,
  phone,
  pin,
  question,
  results,
  search,
  sms,
  user,
  whatsapp,
  chevLeft,
  data,
  vehicle,
  paymentCard,
  cart
};

export interface IconProps {
  name: keyof typeof iconNames;
  className?: string;
  width?: number;
  height?: number;
  testId?: string | undefined;
}

export default IconProps;
export { iconNames };
