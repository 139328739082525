import { get, post } from "./axios";

const AuthService = {
  login(data: unknown) {
    const config = {
      headers: { "Content-Type": "multipart/form-data" }
    };
    return post("token/", data, config);
  },

  logout() {
    return get("logout/");
  },

  isLoggedIn() {
    return get("isloggedin/");
  },

  sendResetPasswordEmail(data: object) {
    return post("/password-reset/", data);
  },
  changePassword(data: object) {
    return post("password-reset-confirm/", data);
  }
};

export default AuthService;
