import React from "react";

import { Header, ContainerMain } from "components";

import "./styles.scss";
import clsx from "clsx";

interface TemplateProps {
  formik?: any;
  cart?: boolean;
  notification?: boolean;
  full?: boolean;
  banner?: React.ReactNode | null;
  children: React.ReactNode | null;
  className?: string;
  scrollable?: boolean;
  withContainer?: boolean;
}

const Template = (props: TemplateProps): JSX.Element => {
  const {
    formik,
    cart,
    notification,
    full,
    banner,
    children,
    withContainer,
    scrollable,
    className
  } = props;

  return (
    <>
      <Header formik={formik} cart={cart} notification={notification} />
      <div className={clsx("container-template", scrollable && "scrollable")}>
        {banner}
        <ContainerMain
          as="main"
          full={full}
          withContainer={withContainer}
          className={className}
        >
          {children}
        </ContainerMain>
      </div>
    </>
  );
};

Template.defaultProps = {
  formik: false,
  cart: false,
  notification: false,
  full: false,
  banner: undefined,
  className: undefined,
  scrollable: false,
  withContainer: true
};

export default Template;
