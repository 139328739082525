import { post, get } from "./axios";

const MaterialOrderService = {
  registerMaterial(data: any) {
    return post("materialorders/", data);
  },

  getBoleto(data: any) {
    return post("materialorders/boleto/", data);
  },

  registerTransaction(data: any) {
    return post("materialorders/transaction/", data);
  },

  registerSponsored(data: any) {
    return post("materialorders/sponsored/", data);
  },

  registerTransactionError(data: any) {
    return post("materialorders/transaction/error/", data);
  },

  listAssociationOptions() {
    return get("materialorders/associations/");
  }
};

export default MaterialOrderService;
