import { createContext } from "react";

const ClientDataContext = createContext({
  association: {
    value: undefined,
    sponsor_pays: undefined
  },
  delivery: {
    zipcode: "",
    postalBox: "",
    address: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "",
    state: "",
    shippingMethod: "",
    supportLocation: "",
    usePostalBox: false,
    supportLocationCity: "",
    supportLocationName: "",
    showShippingMethod: false
  },
  billing: {
    billingType: "",
    useBillingData: false,
    useDeliveryAsBillingAddress: false,
    // CARTAO DE CREDITO
    cardNumber: "",
    cardName: "",
    cardExpiring: "",
    cardCVC: "",
    parcelas: "",
    // FATURAMENTO
    document: "",
    name: "",
    email: "",
    stateSubscription: "",
    ruralProducerSubscription: "",
    phoneNumber: "",
    // ENDERECO COBRANCA
    zipcode: "",
    address: "",
    number: "",
    neighborhood: "",
    complement: "",
    city: "",
    state: "",
    countryCode: "BR"
  }
});

export default ClientDataContext;
