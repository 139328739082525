import axios from "axios";
import LocalStorageService from "./localstorage";

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_BFF_URL}/api/`,
  withCredentials: true
});

api.interceptors.request.use(
  config => {
    const access = LocalStorageService.getAccessToken();
    if (access) {
      // eslint-disable-next-line no-param-reassign
      config.headers = {
        ...config?.headers,
        Authorization: `Bearer ${access}`
      };
    }
    return config;
  },
  error => Promise.reject(error)
);

api.interceptors.response.use(
  res => res,
  async err => {
    const originalConfig = err.config;
    if (originalConfig.url === "/token/refresh/" && err.response) {
      // Access Token was expired
      LocalStorageService.removeAll();
      return Promise.reject(err);
    }
    if (originalConfig.url !== "token/" && err.response) {
      const refreshToken = LocalStorageService.getRefreshToken();
      if (
        refreshToken &&
        err.response.status === 401 &&
        !originalConfig.retry
      ) {
        originalConfig.retry = true;
        try {
          const rs = await api.post("/token/refresh/", {
            refresh: refreshToken
          });
          const accessToken = rs.data.access;
          LocalStorageService.setAccessToken(accessToken);
          return api(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  }
);

const get = (url: string, config?: any) =>
  api.get(url, config || {}).then(response => response.data);

const patch = (url: string, data: any) =>
  api.patch(url, data).then(response => response.data);

const post = (url: string, data: any, config?: any) =>
  api.post(url, data, config || {}).then(response => response.data);

const remove = (url: string, config?: any) =>
  api.delete(url, config || {}).then(response => response.data);

export { get, patch, post, remove };
