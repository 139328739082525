import TwoColumnsProps from "./types";

import "./styles.scss";

import Logo from "../../assets/logo-clinica-white.svg";

const TwoColumns = (props: TwoColumnsProps): JSX.Element => {
  const { title, description, children } = props;

  return (
    <div className="container-columns">
      <div className="sidebar-image">
        <img src={Logo} alt="Clínica do Leite" />
        <h2 className="heading-1 color--bw-white margin--t-lg margin--b-sm">
          {title}
        </h2>
        <p className="body-1 color--bw-white">{description}</p>
      </div>
      <div className="container-page">{children}</div>
    </div>
  );
};

export default TwoColumns;
