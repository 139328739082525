import { LocaleStrings } from "./LocaleStrings";

export const ptBR: LocaleStrings = {
  general: {
    title: "Clínica do Leite",
    analyses: "Análises",
    farm: "Fazenda"
  },
  errors: {
    default: "Algo deu errado. Tente novamente ou contate o suporte.",
    required: "Campo obrigatório",
    connection: "Erro de conexão",
    document: "Documento inválido",
    email: "E-mail inválido",
    integer: "O campo deve possuir um valor inteiro",
    mobile: "Quantidade de dígitos inválida",
    farmName:
      "Digite apenas números, letras, ou os seguintes caracteres especiais: &/-()",
    numeric: "Este campo deve conter um valor numérico",
    maxValue: "Valor maior que {}",
    password: {
      match: "As senhas devem ser iguais",
      length: "Usar pelo menos 8 caracteres (letras e números)",
      number: "Usar pelo menos um dígito numérico",
      uppercase: "Usar pelo menos 1 caractere maiúsculo",
      specialChar: "Usar pelo menos 1 caractere especial (!@#$%^&*)"
    },
    positive: "O número deve ser maior do que 0",
    termsOfUse: "Por favor, aceite os Termos de Uso",
    chooseOne: "Escolha uma opção",
    max: "Este campo deve ter no máximo {} caracteres"
  },
  notifications: {
    accountValidated: "Usuário validado com sucesso! Agora é só fazer o login!",
    passwordSaved: "Sua senha foi salva com sucesso"
  },
  fields: {
    user: {
      email: "E-mail",
      fullName: "Nome completo",
      name: "Nome",
      document: "CPF ou CNPJ",
      password: "Senha",
      confirmPassword: "Confirme a senha",
      farmsName: "Nome da fazenda",
      ownersDocument: "CPF ou CNPJ do proprietário",
      ownersName: "Nome do proprietário",
      ownersCNPJ: "CNPJ do proprietário",
      CPF: "CPF",
      mobilePhone: "Telefone celular",
      companyName: "Razão Social / Nome do Pagador",
      invoiceEmail: "E-mail para envio de nota fiscal",
      ruralProducerSubscription: "IP (Inscrição do Produtor Rural)",
      stateSubscription: "Inscrição Estadual",
      taxpayerType: "Tipo de contribuinte",
      pf: "Pessoa física",
      pj: "Pessoa jurídica"
    },
    analysis: {
      animalData:
        "Frascos para análise de CCS, Composição, NU, Perfil de ácidos graxos (De Novo, Pré-formados e outros), BHB, P-Check ou PCR",
      animalCount: "Número de vacas em lactação + tanques",
      analyses: "Análises",
      association: "Convênio com empresa ou associação",
      tankData: "Frascos para demais análises de tanque",
      tankCount: "Números de tanques"
    },
    address: {
      address: "Endereço",
      addressData: "Utilizar o endereço de entrega",
      zipcode: "CEP",
      useMailbox: "Utilizar caixa postal",
      mailbox: "Caixa postal",
      street: "Endereço",
      number: "Número",
      complement: "Complemento",
      neighborhood: "Bairro",
      city: "Cidade",
      shippingMethod: "Forma de envio",
      state: "Estado",
      supportLocation: "Ponto de Apoio"
    },
    payment: {
      creditCard: "Cartão de crédito",
      bankSlip: "Boleto",
      oneLumpSum: "à vista",
      useBillingData: "Utilizar mesmos dados do cadastro",
      formPayment: "Forma de pagamento",
      name: "Nome",
      email: "Email",
      phoneNumber: "Telefone",
      address: "Endereço",
      number: "Número",
      neighborhood: "Bairro",
      zipcode: "CEP",
      city: "Cidade",
      state: "Estado"
    },
    financialResponsible: {
      name: "Nome",
      email: "E-mail",
      mobilePhone: "Telefone celular"
    }
  },
  pages: {
    homeNotLoggedIn: {
      title: "Tem alguma dúvida sobre o nosso site?"
    },
    services: {
      farm: "Fazenda",
      mission:
        "Nossa missão é melhorar a produtividade do seu rebanho e a qualidade do seu leite.",
      howItWorks: {
        title: "Veja como funciona",
        step: {
          data: "Precisamos dos seus dados para enviar o kit de coleta de amostras. Fique tranquilo: é rápido e fácil",
          kit: "Você recebe o kit, coleta as amostras e leva até um dos nossos pontos de apoio - ClinicaLog",
          results:
            "Analisamos os resultados, sugerimos melhorias e já enviamos um novo kit para acompanhar seu rebanho"
        }
      },
      letsBegin: "Vamos começar"
    },
    portal: {
      hello: "Olá",
      welcome: "Boas vindas",
      awaitingConfirmation:
        "Aguardando confirmação de cadastro. Verifique a sua caixa de e-mail e clique no link enviado.",
      registeredEmail: "Email cadastrado",
      requestKit: {
        title: "Primeiro passo",
        description:
          "Precisamos de algumas informações para enviar seu material de coleta de amostras",
        button: "Solicitar material de coleta"
      },
      nextSteps: {
        title: "Veja os próximos passos",
        payment:
          "Após o pagamento você receberá o kit com mais informações para coletar as amostras",
        transport:
          "Colete as amostras e envie para um de nossos pontos de apoio ou via Correios",
        results:
          "Aguarde os resultados das análises e conte com o nosso suporte"
      },
      milkAnalysis: {
        title: "Conheça nossos tipos de análise",
        description:
          "Realizamos diversos tipos de análises que permitem obter informações sobre a qualidade do leite e as condições do rebanho em relação a sanidade, nutrição e reprodução.",
        button: "Saiba mais"
      },
      priceTable: {
        title: "Tabela de valores",
        attention: "Atenção",
        attentionItems: [
          "• O faturamento de análises ocorrerá somente após a liberação dos resultados.",
          "• Na primeira solicitação de material serão cobrados antecipadamente os frascos e o frete, dependendo do modo de envio selecionado. O valor dos frascos será abatido do faturamento das análises.",
          "• Para as demais solicitações de materiais será cobrado apenas o frete, de acordo com o modo de envio selecionado."
        ],
        notes: "Role a página para visualizar o comunicado e prosseguir"
      }
    },
    landingPage: {
      title: "Insira seu e-mail"
    },
    login: {
      title: "Bem vindo",
      description: "Realize seu login.",
      form: {
        title: "Acesso ao portal"
      },
      newUser: {
        description: "Escolha um perfil para iniciar"
      }
    },
    passwordReset: {
      description:
        "Confirme o seu documento para receber informações de acesso no seu email.",
      validationCard: {
        title: "Confirmar identidade",
        description: "Acesse o link enviado para o seu email de cadastro:"
      }
    },
    userRegister: {
      title: "Entre e solicite o seu kit de amostras",
      description: "Vamos precisar de alguns dados para a sua identificação",
      form: {
        title: "Crie o seu cadastro",
        termsOfUse: {
          first: "Estou de acordo com os ",
          last: " da Clínica do Leite."
        }
      },
      alreadyRegistered: "Já tem um cadastro?",
      logIn: "Entre na sua conta"
    },
    verification: {
      title: "Verifique sua conta",
      chooseOption: "Escolha uma das opções para validar o seu cadastro:",
      email: "Acesse o link enviado para o seu email de cadastro:",
      mobile: "Digite o código enviado via {option} para o número: {mobile}",
      resend: {
        ask: "Solicitar reenvio do {option} de confirmação",
        wait: "Você poderá solicitar o reenvio do {option} de confirmação em {minutes}:{seconds}"
      }
    },
    newMaterial: {
      analysis: {
        title: "Solicitação do material de coleta",
        description: "Etapa 1 de 4"
      },
      association: {
        title: "Você participa de algum convênio?",
        description: "Etapa 2 de 4"
      },
      address: {
        title: "Escolha como você quer receber seu material de coleta",
        description: "Etapa 3 de 4",
        descriptionWhenSponsored: "Etapa 3 de 3",
        deliveryDestination:
          "Endereço para o recebimento do seu material de coleta",
        standardAddressDescription:
          "Precisamos do endereço da sua propriedade para cadastro",
        shippingMethod: "Forma de envio",
        chooseSupportLocation:
          "Escolha o local para retirada do seu material de coleta"
      },
      payment: {
        title: "Preencha os dados para o pagamento e emissão da nota fiscal",
        description: "Etapa 4 de 4",
        formPayment: "Escolha a forma de pagamento",
        billing: "Dados para emissão da nota fiscal",
        billingData: "Dados do faturamento",
        billingAddress: "Endereço para emissão da nota fiscal",
        financialResponsible: "Dados do responsável financeiro"
      }
    },
    requestPassword: {
      title: "Criar senha",
      description:
        "Confirme o seu CPF para receber informações de acesso no seu email, SMS ou whatsapp."
    },
    registerPassword: {
      title: "Criar senha",
      securityTitle: "Dicas de segurança",
      securityLength: "Usar pelo menos 8 caracteres (letras e números)",
      securityNumber: "Usar pelo menos um dígito numérico",
      securityUppercase: "Usar pelo menos 1 caractere maiúsculo",
      securitySpecial: "Usar pelo menos 1 caractere especial (!@#$%^&*)"
    },
    success: {
      title: "Sua solicitação do material de coleta foi realizada com sucesso!",
      descriptionBankSlip:
        "Seu boleto foi enviado por e-mail. Após o pagamento seu material será despachado em até 3 dias úteis.",
      descriptionCreditCard:
        "O material será despachado em até 3 dias úteis. Acompanhe todas as atualizações sobre seu pedido via Portal Clínica."
    },
    profile: {
      title: "Dados do cliente",
      description: "Insira os dados do cliente"
    }
  },
  actions: {
    continue: "Continuar",
    help: "Ajuda",
    login: "Entrar",
    logout: "Sair",
    search: "Procurar",
    profile: "Perfil",
    save: "Salvar",
    add: "Adicionar",
    backToLogin: "Voltar ao login",
    changePassword: "Alterar a senha",
    forgotPassword: "Esqueci minha senha",
    resetPassword: "Recuperar senha",
    createAccount: "Crie uma conta",
    newReservation: "Nova reserva",
    myReservations: "Minhas reservas",
    termsAndConditions: "Termos de Uso",
    proceed: "Prosseguir",
    back: "Voltar",
    select: "Selecionar",
    nextStep: "Próxima etapa",
    send: "Enviar",
    payAndFinish: "Pagar e concluir",
    goToPortal: "Ir para o Portal Clínica",
    requestVialsFor: "Pedir frasco(s) para {}"
  },
  status: {
    done: "Concluída",
    approving: "Em aprovação",
    payment: "Pagamento"
  },
  analyses: {
    ccs: {
      title: "CCS",
      subtitle: "Contagem de células somáticas"
    },
    composition: {
      title: "Composição",
      subtitle: "Gordura, Proteína, Sólidos Totais"
    },
    nul: {
      title: "Ureia",
      subtitle: "Nitrogênio ureico no leite"
    },
    p_check: {
      title: "P-Check",
      subtitle: "Teste de prenhez"
    },
    cbt: {
      title: "CBT",
      subtitle: "Contagem bacteriana total"
    },
    atb: {
      title: "ATB",
      subtitle: "Análise para resíduos de inibidores"
    },
    pcr: {
      title: "PCR",
      subtitle: "Detecção de agentes de mastite"
    }
  },
  summary: {
    title: "Resumo de pedido do material",
    empty: "Seu carrinho está vazio",
    shipping: "Frete",
    total: "Total"
  }
};
