import { Icon } from "components";

import HeaderPageProps from "./types";

import "./styles.scss";

const HeaderPage = (props: HeaderPageProps): JSX.Element => {
  const { title, description, goBack } = props;

  return (
    <div className="container-header-page">
      <button type="button" onClick={goBack} className="button margin--r-sm">
        <Icon name="chevLeft" />
      </button>
      <div>
        <h2 className="heading-1 color--bw-black">{title}</h2>
        <p className="caption-2 color--bw-gray-dark">{description}</p>
      </div>
    </div>
  );
};

export default HeaderPage;
