import { useCallback, useContext, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { cpf as validateCPF, cnpj as validateCNPJ } from "cpf-cnpj-validator";
import { Link, useLocation } from "react-router-dom";
import Locale from "locale";
import { ErrorHelper, MaskHelper, PasswordHelper } from "helpers";
import { getMaxErrorValidation } from "utils/yup";

import {
  WrapperForm,
  Input,
  Button,
  Checkbox,
  TwoColumns,
  PasswordValidationChecklist
} from "components";
import { OnboardingData, EmailData } from "services/onboarding";
import { AuthContext } from "contexts/auth";

import { FormProps } from "./types";

import "./styles.scss";

const formPropsToOnboardingData = (formProps: FormProps): OnboardingData => {
  const documentField = validateCPF.isValid(formProps.document)
    ? "cpf"
    : "cnpj";
  return {
    user: {
      name: formProps.name,
      mobile: MaskHelper.removeMask(formProps.phone),
      email: formProps.email
    },
    client: {
      name: formProps.farm,
      [documentField]: MaskHelper.removeMask(formProps.document)
    },
    password: formProps.password
  };
};

const document = Yup.string()
  .required(Locale.errors.required)
  .test(
    "is-cpf-or-cnpj",
    Locale.errors.document,
    (value: string | undefined) =>
      validateCPF.isValid(value ?? "") || validateCNPJ.isValid(value ?? "")
  );

const UserSchema = Yup.object().shape({
  farm: Yup.string()
    .required(Locale.errors.required)
    .matches(
      /^([A-Za-z0-9\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff&()/-\s]*)$/gi,
      Locale.errors.farmName
    )
    .max(...getMaxErrorValidation(100)),
  document,
  name: Yup.string()
    .required(Locale.errors.required)
    .max(...getMaxErrorValidation(60)),
  phone: Yup.string()
    .required(Locale.errors.required)
    .test("11-digits", Locale.errors.mobile, value =>
      value ? MaskHelper.removeMask(value).length === 11 : false
    ),
  email: Yup.string()
    .email(Locale.errors.email)
    .required(Locale.errors.required)
    .max(...getMaxErrorValidation(255)),
  password: PasswordHelper.passwordValidationSchema,
  password_confirmation: PasswordHelper.confirmPasswordValidationSchema,
  agreedTermsOfUse: Yup.boolean()
    .required(Locale.errors.termsOfUse)
    .oneOf([true], Locale.errors.termsOfUse)
});

const User = (): JSX.Element => {
  const { createUser } = useContext(AuthContext);
  const handleSubmit = useCallback((data: FormProps) => {
    const onboardingData = formPropsToOnboardingData(data);
    createUser(onboardingData).finally(() => {
      formik.setSubmitting(false);
    });
  }, []);

  const location = useLocation();
  const email = (location?.state as EmailData)?.email;

  const values = {
    farm: "",
    document: "",
    name: "",
    phone: "",
    email: email ?? "",
    password: "",
    password_confirmation: "",
    agreedTermsOfUse: undefined
  };

  const formik = useFormik({
    initialValues: values,
    validationSchema: UserSchema,
    onSubmit: data => {
      handleSubmit(data);
    }
  });

  useEffect(() => {
    if (
      formik.isSubmitting &&
      (formik.errors.agreedTermsOfUse ||
        formik.values.agreedTermsOfUse ===
          formik.initialValues.agreedTermsOfUse)
    ) {
      ErrorHelper.notifyError({
        response: { data: Locale.errors.termsOfUse }
      });
    }
  }, [formik.isSubmitting]);

  return (
    <TwoColumns
      title={Locale.pages.userRegister.title}
      description={Locale.pages.userRegister.description}
    >
      <WrapperForm>
        <h1 className="heading-1">{Locale.pages.userRegister.form.title}</h1>
        <div className="margin--t-lg">
          <form onSubmit={formik.handleSubmit}>
            <fieldset disabled={formik.isSubmitting}>
              <div className="row margin--b-md">
                <div className=" col-12">
                  <Input
                    type="text"
                    name="farm"
                    label={Locale.fields.user.farmsName}
                    value={formik.values.farm}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.farm && formik.errors.farm}
                  />
                </div>
              </div>
              <div className="row margin--b-md">
                <div className="col-12">
                  <Input
                    type="tel"
                    name="document"
                    label={Locale.fields.user.ownersDocument}
                    value={MaskHelper.cpfCnpj(formik.values.document)}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.document && formik.errors.document}
                  />
                </div>
              </div>
              <div className="row margin--b-md">
                <div className="col-12">
                  <Input
                    type="text"
                    name="name"
                    label={Locale.fields.user.ownersName}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && formik.errors.name}
                  />
                </div>
              </div>
              <div className="row margin--b-md">
                <div className="col-12">
                  <Input
                    type="tel"
                    name="phone"
                    label={Locale.fields.user.mobilePhone}
                    value={MaskHelper.phone(formik.values.phone)}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.phone && formik.errors.phone}
                  />
                </div>
              </div>
              <div className="row margin--b-md">
                <div className="col-12">
                  <Input
                    type="email"
                    name="email"
                    label={Locale.fields.user.email}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && formik.errors.email}
                  />
                </div>
              </div>
              <div className="row margin--b-md">
                <div className="col-12">
                  <Input
                    type="password"
                    name="password"
                    label={Locale.fields.user.password}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.password && formik.errors.password}
                    hideErrorMessage
                  />
                </div>
              </div>
              <div className="row margin--b-md">
                <div className="col-12">
                  <Input
                    type="password"
                    name="password_confirmation"
                    label={Locale.fields.user.confirmPassword}
                    value={formik.values.password_confirmation}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.password_confirmation &&
                      formik.errors.password_confirmation
                    }
                  />
                </div>
              </div>
              <PasswordValidationChecklist textValue={formik.values.password} />
              <div className="wrapper-terms-of-use margin--b-lg d-flex align-items-center margin--t-md">
                <Checkbox
                  id="checkbox-terms-of-use"
                  name="agreedTermsOfUse"
                  onChange={formik.handleChange}
                  value={formik.values.agreedTermsOfUse ? "true" : "false"}
                  error={formik.errors.agreedTermsOfUse}
                />
                <span className="body-2 margin--l-xs">
                  {Locale.pages.userRegister.form.termsOfUse.first}
                  <a
                    className="body-2 color--brand-primary"
                    href="https://www.clinicadoleite.com.br/politica-de-privacidade?rq=Termos"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {Locale.actions.termsAndConditions}
                  </a>
                  {Locale.pages.userRegister.form.termsOfUse.last}
                </span>
              </div>
              <Button
                className="w-100 d-flex justify-content-center margin--b-lg"
                type="submit"
                title={Locale.actions.send}
                isLoading={formik.isSubmitting}
              />
            </fieldset>
          </form>
        </div>
        <div className="margin--x-xxl">
          <p className="body-2 text-center">
            {Locale.pages.userRegister.alreadyRegistered}
          </p>
          <p className="text-center">
            <Link className="body-2 color--brand-primary" to="/login">
              {Locale.pages.userRegister.logIn}
            </Link>
          </p>
        </div>
      </WrapperForm>
    </TwoColumns>
  );
};

export default User;
