import { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { IntlProvider } from "react-intl";

import { Main } from "components";
import { AuthProvider } from "contexts/auth";
import ScrollToTop from "utils/ScrollToTop";
import Locale from "./locale";
import Routers from "./router";

import "react-toastify/dist/ReactToastify.css";
import "./App.scss";

const App = () => {
  const [languageEnglish, setLanguageEnglish] = useState(
    Locale.getLanguage() === "en"
  );

  const handleSwitchLanguage = () => {
    const newLanguageIsEnglish = !languageEnglish;
    setLanguageEnglish(newLanguageIsEnglish);
    Locale.setLanguage(newLanguageIsEnglish ? "en" : "pt");
  };

  return (
    <IntlProvider locale="pt-BR" defaultLocale="pt-BR">
      <Router>
        <ScrollToTop>
          <AuthProvider>
            <Main>
              <Routers />
              <ToastContainer
                position="bottom-center"
                icon={false}
                closeButton
                theme="dark"
                limit={2}
              />
            </Main>
          </AuthProvider>
        </ScrollToTop>
      </Router>
    </IntlProvider>
  );
};

export default App;
