import { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";

import {
  BottomTag,
  Button,
  Card,
  ContainerMain,
  Icon,
  Image,
  Template,
  Modal
} from "components";
import { AuthContext } from "contexts/auth";

import "./styles.scss";
import Locale from "locale";
import NewMaterialContext, {
  initialContextValues
} from "pages/Material/New/NewMaterialContext";
import { useTimer } from "react-timer-hook";
import Service from "services";
import { ErrorHelper } from "helpers";
import LocalStorageService from "services/localstorage";
import priceTable from "assets/images/price-table.png";
import priceTableMobile from "assets/images/price-table-mobile.png";

const Portal = () => {
  const { getUserCurrent, user, isVerified } = useContext(AuthContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 770);
  const contextData = useContext(NewMaterialContext);
  const [isPriceTableModalOpen, setIsPriceTableModalOpen] = useState(false);

  useEffect(() => {
    getUserCurrent();
  }, []);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth <= 770;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  const time = new Date();
  time.setSeconds(time.getSeconds() + 90);
  const { seconds, minutes, isRunning, restart } = useTimer({
    expiryTimestamp: time,
    autoStart: false
  });

  const handleResend = (type: string) => {
    if (type === "email") {
      const time = new Date();
      time.setSeconds(time.getSeconds() + 90);
      window.localStorage.setItem(
        "RESEND_TIMER",
        JSON.stringify(time.getTime())
      );
      restart(time);
      Service.resendConfirmationEmail({
        email: String(user?.email)
      }).catch(error => ErrorHelper.notifyError(error));
    }
  };

  useEffect(() => {
    const milis: number = JSON.parse(
      window.localStorage.getItem("RESEND_TIMER") ?? "0"
    );
    const time = new Date();
    if (milis >= time.getTime()) {
      restart(new Date(milis));
    }
  }, []);

  const CardRequestKit = (
    <div className="card-request-kit">
      <Card>
        <Icon
          name="data"
          width={isMobile ? 48 : 80}
          height={isMobile ? 48 : 80}
        />
        <div className="card-title">{Locale.pages.portal.requestKit.title}</div>
        <div className="card-description">
          {Locale.pages.portal.requestKit.description}
        </div>
        <Button
          type="button"
          className="button-request-kit"
          onClick={() => {
            setIsPriceTableModalOpen(true);
          }}
        >
          {Locale.pages.portal.requestKit.button}
        </Button>
      </Card>
    </div>
  );

  const CardAwaitingConfimation = (
    <div className="card-request-kit">
      <Card>
        <h4 className="awaiting-confirmation">
          {Locale.pages.portal.awaitingConfirmation}
        </h4>
        <h5 className="awaiting-confirmation margin--y-sm">
          {Locale.pages.portal.registeredEmail}: {user?.email}
        </h5>
        <Button
          className="button-resend-email"
          size="small"
          variant="tertiary"
          disabled={isRunning}
          onClick={() => {
            handleResend("email");
          }}
        >
          {isRunning
            ? Locale.pages.verification.resend.wait
                .replace("{option}", "e-mail")
                .replace(
                  "{minutes}",
                  String(minutes >= 10 ? minutes : `0${minutes}`)
                )
                .replace(
                  "{seconds}",
                  String(seconds >= 10 ? seconds : `0${seconds}`)
                )
            : Locale.pages.verification.resend.ask.replace(
                "{option}",
                "e-mail"
              )}
        </Button>
      </Card>
    </div>
  );

  const banner = (
    <div className="bg-banner">
      <ContainerMain withContainer>
        <div className="banner-content">
          <div className="container-greetings">
            <div className="hello">{Locale.pages.portal.hello},</div>
            <div className="welcome">{user?.name}</div>
          </div>
          {isVerified() ? CardRequestKit : CardAwaitingConfimation}
        </div>
      </ContainerMain>
    </div>
  );

  const PriceImages = [];
  if (isMobile) {
    for (let i = 1; i <= 3; i += 1) {
      PriceImages.push(
        <Image
          className="margin--y-md"
          src={`${process.env.PUBLIC_URL}/assets/images/price-table/mobile/Slide${i}.png`}
          width={window.innerWidth * 0.95}
        />
      );
    }
  } else {
    for (let i = 1; i <= 3; i += 1) {
      PriceImages.push(
        <Image
          className="margin--y-md width--full"
          src={`${process.env.PUBLIC_URL}/assets/images/price-table/desktop/Slide${i}.PNG`}
        />
      );
    }
  }

  const PriceTableModal = (
    <Modal visible={isPriceTableModalOpen}>
      <div
        className={`price-table-modal-wrapper " + ${isMobile ? "mobile" : ""}`}
      >
        <div className="scrollable-content width--fit-content">
          <div className="container flex-row flex-justify--start flex-align--start heading-1 text-align--center">
            <span>{Locale.pages.portal.priceTable.title}</span>
          </div>
          {PriceImages}
          <div className="container attention-bg">
            <p className="heading-2 text-modal-primary margin--b-sm">
              {Locale.pages.portal.priceTable.attention}
            </p>
            {Locale.pages.portal.priceTable.attentionItems.map(item => (
              <p className="text-modal-primary">{item}</p>
            ))}
          </div>
          <div className="text-align--center">
            <Link to="/material/new/analysis">
              <Button
                type="button"
                className="button-request-kit"
                onClick={() => {
                  contextData.analysis = initialContextValues.analysis;
                  contextData.delivery = initialContextValues.delivery;
                  contextData.billing = initialContextValues.billing;
                  contextData.summary = initialContextValues.summary;
                  LocalStorageService.clearMaterialFormData();
                }}
              >
                Prosseguir
              </Button>
            </Link>
          </div>
        </div>
        <div className="container flex-row flex-justify--start flex-align--start text-modal-primary">
          <span>{Locale.pages.portal.priceTable.notes}</span>
        </div>
      </div>
    </Modal>
  );

  return (
    <div>
      <Template banner={banner}>
        {PriceTableModal}
        <div className="ignore-padding-mobile">
          <BottomTag title="Dúvidas?" detail="Fale com a gente" icon="chat" />
        </div>
      </Template>
    </div>
  );
};

export default Portal;
