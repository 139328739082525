import UserService from "./user";
import AuthService from "./auth";
import OnboardingService from "./onboarding";
import SupportLocationService from "./supportLocation";
import VerifyAdressService from "./verifyAddress";
import MaterialOrderService from "./materialorder";
import ClientDataService from "./clientdata";
import AdminService from "./admin";

const Service = {
  ...AuthService,
  ...UserService,
  ...OnboardingService,
  ...SupportLocationService,
  ...VerifyAdressService,
  ...MaterialOrderService,
  ...ClientDataService,
  ...AdminService
};

export default Service;
