import React, { useMemo, useState } from "react";
import clsx from "clsx";

import { Icon, Tooltip } from "components";

import InputProps from "./types";

import "./styles.scss";

const Input: React.FC<InputProps> = props => {
  const {
    label,
    name,
    type = "text",
    icon,
    value,
    placeholder,
    disabled = false,
    onBlur,
    onChange,
    help,
    error,
    hideErrorMessage = false,
    search,
    maxLength,
    ref,
    required = false,
    tooltip,
    ...rest
  } = props;
  const filled = !!value;
  const [reveal, setReveal] = useState<boolean>(false);
  const [isPassword] = useState<boolean>(reveal);

  return (
    <div className="wrapper-input">
      <div
        className={clsx("container-input", {
          disabled,
          error
        })}
      >
        <label
          htmlFor={name}
          className={clsx("label", {
            filled
          })}
        >
          {icon && <Icon name={icon} className="icon margin--r-sm" />}

          <div className="w-input">
            <input
              {...rest}
              ref={ref}
              id={name}
              name={name}
              type={reveal ? "text" : type}
              value={value}
              disabled={disabled}
              placeholder={placeholder}
              onChange={onChange}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                onBlur && onBlur(e);
              }}
              className={clsx({
                pass: reveal
              })}
              maxLength={maxLength}
            />

            <span className={clsx(tooltip && "reduced-width")}>
              {label}
              {required && <> &#8727;</>}
            </span>
            {tooltip && (
              <div className="tooltip-selector">
                <div className="tooltip-wrapper">
                  <Tooltip title={tooltip?.title} body={tooltip.body} />
                </div>
                <Icon name="question" className="margin--x-sm nofill" />
              </div>
            )}

            {type === "password" && (
              <button type="button" onClick={() => setReveal(!reveal)}>
                <Icon name={reveal ? "eye" : "eyeOff"} />
              </button>
            )}
            {search && <Icon name="search" />}
          </div>
        </label>
      </div>
      {error && !hideErrorMessage && <p className="text error">{error}</p>}
      {help && <p className="text helper">{help}</p>}
    </div>
  );
};
export default Input;
