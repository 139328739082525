import { TooltipProps } from "./types";
import "./styles.scss";

const Tooltip = (props: TooltipProps): JSX.Element => {
  const { title, body } = props;

  return (
    <div className="tooltip-container">
      {title && <span className="tooltip-title">{title}</span>}
      <span className="tooltip-body">{body}</span>
    </div>
  );
};

export default Tooltip;
