import { Banner, Button, Input, Template, WrapperForm } from "components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState, useCallback } from "react";
import bgImage from "assets/images/bg-banner.png";
import bgImageMobile from "assets/images/bg-banner-mobile.png";

import "swiper/css";
import "swiper/css/pagination";

import "./styles.scss";
import Locale from "locale";

import { EmailData } from "services/onboarding";
import { useNavigate } from "react-router-dom";

import Service from "services";
import { ErrorHelper } from "helpers";

const EmailSchema = Yup.object().shape({
  email: Yup.string()
    .email(Locale.errors.email)
    .required(Locale.errors.required)
});

const initialEmailValues = {
  email: ""
};

const LandingPage = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleWindowResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const [landingPageFooter, setLandingPageFooter] = useState("");

  useEffect(() => {
    Service.getGlobalValue("form_code").then(data => {
      setLandingPageFooter(data.value);
    });
  }, []);

  const navigate = useNavigate();

  const handleSubmit = useCallback((emailData: EmailData) => {
    Service.landingPageRedirect(emailData)
      .then(data => {
        navigate(`../${data.redirect_uri}`, {
          state: { email: emailData.email },
          replace: true
        });
      })
      .catch(e => {
        formik.setSubmitting(false);
        if (e.response.status === 301) {
          window.location.href = e.response.data.redirect_uri;
        } else {
          ErrorHelper.notifyError({
            response: { data: "Erro ao consultar email" }
          });
        }
      });
  }, []);

  const formik = useFormik({
    initialValues: initialEmailValues,
    validationSchema: EmailSchema,
    onSubmit: data => {
      handleSubmit(data);
    }
  });

  return (
    <Template
      banner={
        <Banner
          title={Locale.pages.services.farm}
          description={Locale.pages.services.mission}
          image={windowWidth > 770 ? bgImage : bgImageMobile}
        />
      }
    >
      <div className="landing-page-container">
        <WrapperForm>
          <h1 className="heading-1">{Locale.pages.landingPage.title}</h1>
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-12">
                <Input
                  type="email"
                  name="email"
                  label={Locale.fields.user.email}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && formik.errors.email}
                />
              </div>
              <div className="col-12">
                <div className="button-div">
                  <Button
                    className="button-begin"
                    title="Próximo"
                    type="submit"
                    isLoading={formik.isSubmitting}
                  />
                </div>
              </div>
            </div>
          </form>
        </WrapperForm>
        <p className="footer-form-code">{landingPageFooter}</p>
      </div>
    </Template>
  );
};

export default LandingPage;
