import { post } from "./axios";

export interface NewUserData {
  name: string;
  mobile: string;
  email: string;
}

interface DocumentsData {
  cpf: string;
  cnpj: string;
}

export interface NewClientData extends Partial<DocumentsData> {
  name: string;
}

export interface OnboardingData {
  user: NewUserData;
  client: NewClientData;
  password: string;
}

interface ResendEmailData {
  email: string;
}

export interface EmailData {
  email: string;
}

export interface CnpjData {
  id: string;
}

const OnboardingUrls = {
  onboardings: "onboardings/",
  resendEmail: "resend/email/",
  landingPageRedirect: "landing_page_redirect/",
  registerCnpjInfo: "cnpj_info/"
} as const;

const OnboardingService = {
  registerOnboarding(data: OnboardingData) {
    return post(OnboardingUrls.onboardings, data);
  },
  resendConfirmationEmail(data: ResendEmailData) {
    return post(OnboardingUrls.resendEmail, data);
  },
  landingPageRedirect(data: EmailData) {
    return post(OnboardingUrls.landingPageRedirect, data);
  },
  registerCnpjInfo(data: CnpjData) {
    return post(OnboardingUrls.registerCnpjInfo, data);
  }
};

export default OnboardingService;
