import { Option } from "components/Dropdown/types";
import { AddressFields } from "pages/Material/New/Address/types";

const stateOptionsMock: Option[] = [
  {
    label: "SP",
    value: "1"
  },
  {
    label: "RJ",
    value: "2"
  }
];

const cityOptionsMock: Record<string, Option[]> = {
  "1": [
    { label: "São Paulo", value: "1" },
    { label: "Osasco", value: "2" }
  ],
  "2": [
    { label: "Niterói", value: "3" },
    { label: "Rio de Janeiro", value: "4" }
  ]
};

const postalDataMock: Record<string, unknown> = {
  [AddressFields.address]: "Av. Enida",
  [AddressFields.addressNumber]: "123",
  [AddressFields.state]: "1",
  [AddressFields.city]: "1"
};

const supportLocationOptionMock = {
  label: "ClinicaLog",
  value: "1",
  detail: "Frete grátis (de 7 a 30 dias)",
  price: 0
};

const shippingOptionsMock: (Option & { detail: string; price: number })[] = [
  {
    label: "Correios - PAC",
    value: "2",
    detail: "",
    price: 20
  },
  {
    label: "Correios - SEDEX",
    value: "3",
    detail: "",
    price: 30
  }
];

const orderDataMock = {
  items: [
    {
      name: "50 fascos de amostra",
      value: "R$ 200,00"
    },
    {
      name: "Frete",
      value: "R$ 0,00"
    }
  ],
  total: "R$ 200,00"
};

const AddressMock = {
  stateOptionsMock,
  cityOptionsMock,
  postalDataMock,
  shippingOptionsMock,
  supportLocationOptionMock,
  orderDataMock
};

export default AddressMock;
