import clsx from "clsx";

import { ContainerMain } from "components";
import StickyFooterProps from "./types";

import "./styles.scss";

const StickyFooter = (props: StickyFooterProps) => {
  const { children, className, withContainer } = props;

  return (
    <footer className={clsx("wrapper-stick-footer", className)}>
      <ContainerMain
        className="padding--y-md mt-0"
        withContainer={withContainer}
      >
        {children}
      </ContainerMain>
    </footer>
  );
};
StickyFooter.defaultProps = {
  withContainer: true
};

export default StickyFooter;
