import clsx from "clsx";
import { useMemo } from "react";
import { GridLayoutProps } from "./types";

import "./styles.scss";

const GridLayout = ({
  as: _As,
  children,
  className,
  ...style
}: GridLayoutProps): JSX.Element => {
  const As = _As ?? "div";
  return (
    <As className={clsx("grid-layout", className)} style={style}>
      {children}
    </As>
  );
};

GridLayout.defaultProps = {
  as: "div",
  className: undefined
};
export default GridLayout;
