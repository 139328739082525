import { useEffect } from "react";
import clsx from "clsx";

import { ContainerMain, StickyFooter, Button } from "components";
import {
  formatCurrencyIfNotSponsored,
  cartSum,
  shouldShowShipping
} from "utils/functions";
import Locale from "locale";
import SummaryProps from "./types";

import "./styles.scss";

const Summary = (props: SummaryProps): JSX.Element => {
  const { isHeader, formik, children, onCloseSummary } = props;

  const getKey = (key: number) => `summary-${key}`;

  useEffect(() => {
    cartSum(formik);
  }, []);

  const isSponsored = (formik: any) =>
    formik.values.analysis.association.sponsor_pays === "Y";

  return (
    <div className={clsx("wrapper-summary", { "summary-header": isHeader })}>
      {formik.values.summary.items && (
        <div className="container-summary">
          <ContainerMain className="mt-0 pt-0" withContainer>
            <div className="container-title">
              <h4 className="caption-1 color--bw-black">
                {Locale.summary.title}
              </h4>
              {isHeader && (
                <Button
                  className="close-button"
                  icon="close"
                  variant="tertiary"
                  onClick={onCloseSummary}
                />
              )}
            </div>
            {formik.values.summary.amount === 0 ? (
              <div className="content-summary">
                <div className="body-2">{Locale.summary.empty}</div>
              </div>
            ) : (
              <div className="content-summary">
                {formik.values?.summary?.items?.map(
                  (item: any, key: number) => (
                    <p
                      className="body-2 flex-justify--between margin--t-sm"
                      key={getKey(key)}
                    >
                      <div className="flex--column">
                        {item?.subitems.map((subitem: string) => (
                          <span className="margin--y-sm">{subitem}</span>
                        ))}
                      </div>
                      <span className="margin--y-sm margin--l-md">
                        {formatCurrencyIfNotSponsored(
                          item.value,
                          isSponsored(formik)
                        )}
                      </span>
                    </p>
                  )
                )}
                <p className="body-2 flex-justify--between margin--t-sm">
                  <span>{Locale.summary.shipping}</span>
                  <span>
                    {!shouldShowShipping(formik) && !isSponsored(formik)
                      ? "A calcular"
                      : formatCurrencyIfNotSponsored(
                          formik.values.summary?.shipping,
                          isSponsored(formik)
                        )}
                  </span>
                </p>
                <p className="button-2 flex-justify--between margin--t-sm">
                  <span>{Locale.summary.total}</span>
                  <span>
                    {formatCurrencyIfNotSponsored(
                      formik.values.summary?.amount,
                      isSponsored(formik)
                    )}
                  </span>
                </p>
              </div>
            )}
          </ContainerMain>
        </div>
      )}
      {!isHeader && (
        <StickyFooter>
          <div className="col-12 my-0 flex--row flex-justify--center">
            {children}
          </div>
        </StickyFooter>
      )}
    </div>
  );
};

export default Summary;
