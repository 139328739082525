export enum AddressFields {
  zipCode = "zipCode",
  usePostalBox = "usePostalBox",
  address = "address",
  addressNumber = "addressNumber",
  complement = "complement",
  city = "city",
  state = "state",
  shippingMethod = "shippingMethod",
  supportLocation = "supportLocation"
}

export type AddressForm = {
  delivery: {
    zipcode: string;
    postalBox: string;
    usePostalBox: boolean;
    address: string;
    number: string;
    complement: string;
    city: string;
    state: string;
    shippingMethod: string;
    supportLocation: string;
  };
  summary: {
    items: {
      label: string;
      value: number;
    }[];
    shipping: number;
    amount: number;
  };
};

export type SupportPointLocation = {
  ponto: string;
  ponto_code: string;
};

export type SupportPointCity = {
  cidade: string;
  codigo: string;
};
