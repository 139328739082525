import clsx from "clsx";

import RadioProps from "./types";

import "./styles.scss";

const Radio = (props: RadioProps): JSX.Element => {
  const { id, label, name, value, disabled, error, onChange, ...args } = props;
  return (
    <div
      className={clsx("container-radio", {
        error
      })}
    >
      <input
        className="styled-radio"
        type="radio"
        id={id}
        name={name}
        value={value}
        disabled={disabled}
        onChange={onChange}
        {...args}
      />
      <label htmlFor={id} className="body-2">
        {label}
      </label>
    </div>
  );
};

export default Radio;
