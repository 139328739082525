import clsx from "clsx";
import "./styles.scss";

const Modal = (props: any): JSX.Element => {
  const { children, visible } = props;
  return (
    <div className={clsx("modal-wrapper", !visible && "hidden")}>
      <div className="modal-wrapper modal-bg" />
      <div className="modal-children-wrapper">{children}</div>
    </div>
  );
};

export default Modal;
