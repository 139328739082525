import { useContext } from "react";

import { Portal, Services } from "pages";
import { AuthContext } from "contexts/auth";

const Home = () => {
  const { isAuthenticated } = useContext(AuthContext);

  return isAuthenticated() ? <Portal /> : <Services />;
};

export default Home;
