import { useEffect, useMemo, useState, useContext, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import cep from "cep-promise";
import isEqual from "lodash/isEqual";

import Service from "services";
import {
  Dropdown,
  Input,
  Select,
  Template,
  Button,
  HeaderPage,
  Summary,
  WrapperForm
} from "components";
import { Option } from "components/Dropdown/types";

import { cartSum, getAnalysesFromContext } from "utils/functions";
import { MaskHelper, ErrorHelper } from "helpers";

import { AddressMock, ufMock } from "consts";

import "./styles.scss";
import notify from "components/Toast";
import Locale from "locale";
import { getMaxErrorValidation } from "utils/yup";
import { FieldStrings } from "locale/LocaleStrings";
import LocalStorageService from "services/localstorage";
import NewMaterialContext, {
  initialContextValues
} from "../NewMaterialContext";
import { SupportPointLocation, SupportPointCity } from "./types";

const getValidationSchema = (sponsorPays: boolean) =>
  Yup.object().shape({
    delivery: Yup.object().shape({
      shippingMethod: Yup.number().required(Locale.errors.required),
      zipcode: Yup.string().when("shippingMethod", {
        is: (shippingMethod: number) =>
          shippingMethod === 2 || shippingMethod === 3 || sponsorPays,
        then: Yup.string().required(Locale.errors.required)
      }),
      address: Yup.string()
        .when("usePostalBox", {
          is: false,
          then: Yup.string().when("shippingMethod", {
            is: (shippingMethod: number) =>
              shippingMethod === 2 || shippingMethod === 3 || sponsorPays,
            then: Yup.string().required(Locale.errors.required)
          })
        })
        .max(...getMaxErrorValidation(255)),
      number: Yup.string()
        .when("usePostalBox", {
          is: false,
          then: Yup.string().when("shippingMethod", {
            is: (shippingMethod: number) =>
              shippingMethod === 2 || shippingMethod === 3 || sponsorPays,
            then: Yup.string().required(Locale.errors.required)
          })
        })
        .max(...getMaxErrorValidation(20)),
      postalBox: Yup.string().when("usePostalBox", {
        is: true,
        then: Yup.string().required(Locale.errors.required)
      }),
      complement: Yup.string().max(...getMaxErrorValidation(30)),
      neighborhood: Yup.string()
        .when("usePostalBox", {
          is: false,
          then: Yup.string().when("shippingMethod", {
            is: (shippingMethod: number) =>
              shippingMethod === 2 || shippingMethod === 3 || sponsorPays,
            then: Yup.string().required(Locale.errors.required)
          })
        })
        .max(...getMaxErrorValidation(30)),
      city: Yup.string()
        .when("shippingMethod", {
          is: (shippingMethod: number) =>
            shippingMethod === 2 || shippingMethod === 3 || sponsorPays,
          then: Yup.string().required(Locale.errors.required)
        })
        .max(...getMaxErrorValidation(30)),
      state: Yup.string()
        .required("Campo obrigatório")
        .max(...getMaxErrorValidation(30)),
      supportLocationCity: Yup.string().when("shippingMethod", {
        is: 1,
        then: Yup.string().required(Locale.errors.required)
      }),
      supportLocationName: Yup.string().when("shippingMethod", {
        is: 1,
        then: Yup.string().required(Locale.errors.required)
      }),
      usePostalBox: Yup.boolean()
    })
  });

function getShippingOptions(): (Option & { detail: string; price: number })[] {
  return AddressMock.shippingOptionsMock;
}

function getSupportLocationOption(): Option & {
  detail: string;
  price: number;
} {
  return AddressMock.supportLocationOptionMock;
}

const Address = () => {
  const navigate = useNavigate();
  const contextData = useContext(NewMaterialContext);
  const sponsorPays = contextData.analysis.association.sponsor_pays === "Y";
  const validationSchema = getValidationSchema(sponsorPays);

  const clearMaterialSolicitationContextData = () => {
    contextData.analysis = initialContextValues.analysis;
    contextData.delivery = initialContextValues.delivery;
    contextData.billing = initialContextValues.billing;
    contextData.summary = initialContextValues.summary;
    LocalStorageService.clearMaterialFormData();
  };

  const sendForm = () => {
    if (sponsorPays) {
      const data = {
        address: contextData.delivery.zipcode
          ? {
              postcode: contextData.delivery.zipcode.replace(/\D/g, ""),
              street_name: !contextData.delivery.usePostalBox
                ? contextData.delivery.address
                : undefined,
              number: !contextData.delivery.usePostalBox
                ? contextData.delivery.number
                : undefined,
              complement: !contextData.delivery.usePostalBox
                ? contextData.delivery.complement
                : undefined,
              neighborhood: !contextData.delivery.usePostalBox
                ? contextData.delivery.neighborhood
                : undefined,
              city: contextData.delivery.city,
              state: contextData.delivery.state,
              mailbox: contextData.delivery.postalBox
            }
          : undefined,
        analysis_items: getAnalysesFromContext(contextData.analysis.analyses),
        lactation_animals_amount: Number(contextData.analysis.animalCount),
        tank_amount: Number(contextData.analysis.tankCount),
        shipping_method: contextData.delivery.shippingMethod,
        association: contextData.analysis.association.value,
        support_point: contextData.delivery.supportLocationName
      };

      Service.registerMaterial(data)
        .then(responseData => {
          const registerSponsorData = {
            material_order_id: responseData.material_order_id
          };
          Service.registerSponsored(registerSponsorData)
            .then(() => {
              clearMaterialSolicitationContextData();
              formik.setSubmitting(false);
              navigate("/material/new/success", {
                state: { sponsorPaid: true },
                replace: true
              });
            })
            .catch(err => {
              ErrorHelper.notifyError(err);
              formik.setSubmitting(false);
            });
        })
        .catch(err => {
          ErrorHelper.notifyError(err);
          formik.setSubmitting(false);
        });
    } else {
      formik.setSubmitting(false);
      navigate("/material/new/payment");
    }
  };

  const formik = useFormik({
    initialValues: contextData,
    validationSchema,
    onSubmit: data => {
      contextData.delivery = data.delivery;
      contextData.summary = data.summary;

      if (contextData.delivery.city && contextData.delivery.state) {
        Service.verifyCity(
          contextData.delivery.city,
          contextData.delivery.state
        )
          .then(() => sendForm())
          .catch(err => {
            ErrorHelper.notifyError(err);
            formik.setSubmitting(false);
          });
      } else {
        sendForm();
      }
    }
  });

  const supportLocationOption = useMemo(() => getSupportLocationOption(), []);
  const shippingOptions = useMemo(() => getShippingOptions(), []);

  useEffect(() => {
    cartSum(formik);
  }, [formik.values.delivery.shippingMethod]);

  const handleZipCodeChange = (zipCode: string) => {
    cep(zipCode)
      .then((response: any) => {
        formik.setValues((values: any) => ({
          ...values,
          delivery: {
            ...values.delivery,
            address: response.street,
            neighborhood: response.neighborhood,
            city: response.city,
            state: response.state,
            number: ""
          }
        }));
      })
      .catch(err => console.log(err));
  };

  const [supportLocationCities, setSupportLocationCities] = useState([]);
  const [cleanSupportLocationCities, setCleanSupportLocationCities] = useState(
    []
  );
  const tempSupportLocationCities: any = [];

  useEffect(() => {
    if (formik.values.delivery.state !== "") {
      Service.listCities(formik.values.delivery.state).then(jsonCities => {
        setSupportLocationCities(jsonCities);
      });
    }
  }, [formik.values.delivery.state]);

  useEffect(() => {
    if (Array.isArray(supportLocationCities)) {
      supportLocationCities.map((item: SupportPointCity) =>
        tempSupportLocationCities.push({
          label: item?.cidade,
          value: item?.codigo
        })
      );
      setCleanSupportLocationCities(tempSupportLocationCities);
    }
  }, [supportLocationCities]);

  const [supportLocationNames, setSupportLocationNames] = useState([]);
  const [cleanSupportLocationNames, SetCleanSupportLocationNames] = useState(
    []
  );
  const tempSupportLocationNames: any = [];

  useEffect(() => {
    if (
      formik.values.delivery.state !== "" &&
      formik.values.delivery.supportLocationCity !== ""
    ) {
      Service.supportPoints(
        formik.values.delivery.state,
        formik.values.delivery.supportLocationCity
      ).then(jsonPoints => {
        setSupportLocationNames(jsonPoints);
      });
    }
  }, [
    formik.values.delivery.state,
    formik.values.delivery.supportLocationCity
  ]);

  useEffect(() => {
    supportLocationNames.map((item: SupportPointLocation) =>
      tempSupportLocationNames.push({
        label: item?.ponto.replace(/\s*PT\s*-\s*/, ""),
        value: item?.ponto_code
      })
    );
    SetCleanSupportLocationNames(tempSupportLocationNames);
  }, [supportLocationNames]);

  const inputDeliveryNumber = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let notifyMessage = "";
    const labels: string[] = [];
    if (formik?.errors?.delivery) {
      (
        Object.keys(formik.errors.delivery) as (keyof FieldStrings["address"])[]
      ).forEach(key => {
        if (Locale.fields.address[key]) {
          labels.push(Locale.fields.address[key]);
        }
      });

      notifyMessage = labels.join(", ");
      if (notifyMessage) {
        notify(`Existe um erro nos seguintes campos: ${notifyMessage}`);
      }
    }
  }, [formik.submitCount]);

  const isSponsored = (formik: any) =>
    formik.values.analysis.association.sponsor_pays === "Y";

  useEffect(() => {
    const previousPagesAreEmpty = isEqual(
      contextData.analysis,
      initialContextValues.analysis
    );

    if (previousPagesAreEmpty) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <NewMaterialContext.Provider value={contextData}>
        <form onSubmit={formik.handleSubmit} className="address-form">
          <Template formik={formik} cart notification className="margin-y">
            <HeaderPage
              goBack={() => {
                contextData.delivery = formik?.values?.delivery;
                contextData.summary = formik?.values?.summary;
                navigate("/material/new/association");
              }}
              title={Locale.pages.newMaterial.address.title}
              description={
                isSponsored(formik)
                  ? Locale.pages.newMaterial.address.descriptionWhenSponsored
                  : Locale.pages.newMaterial.address.description
              }
            />
            <fieldset disabled={formik.isSubmitting}>
              <WrapperForm full className="margin--t-md">
                <h4 className="body-2 color--brand-primary">
                  {Locale.pages.newMaterial.address.shippingMethod} &#8727;
                </h4>
                <div className="container-form margin--t-md">
                  <div className="row">
                    <div className="col-12">
                      <Select
                        type="radio"
                        id="delivery.shippingMethod_pontoapoio"
                        name="delivery.shippingMethod"
                        label={supportLocationOption.label}
                        detail={supportLocationOption.detail}
                        value={supportLocationOption.value}
                        checked={
                          String(formik.values.delivery.shippingMethod) ===
                          String(supportLocationOption.value)
                        }
                        onChange={e => {
                          const { value } = e.currentTarget;
                          formik.setValues((values: any) => ({
                            ...values,
                            delivery: {
                              ...values.delivery,
                              shippingMethod: value
                            },
                            summary: {
                              ...values.summary,
                              shipping: 0
                            }
                          }));
                        }}
                      />
                    </div>
                    {formik.values.delivery.shippingMethod === "1" && (
                      <div className="container-support-location">
                        <Dropdown
                          name="delivery.supportLocationState"
                          value={formik.values.delivery.state}
                          onChange={e => {
                            formik.setValues((values: any) => ({
                              ...values,
                              delivery: {
                                ...values.delivery,
                                state: e,
                                supportLocationCity: "",
                                supportLocationName: ""
                              }
                            }));
                          }}
                          options={ufMock}
                          label="Estado"
                          autocomplete
                          error={
                            formik.touched.delivery?.state &&
                            formik.errors.delivery?.state
                          }
                          disabled={formik.isSubmitting}
                        />
                        <Dropdown
                          name="delivery.supportLocationCity"
                          value={formik.values.delivery.supportLocationCity}
                          onChange={e => {
                            formik.setValues((values: any) => ({
                              ...values,
                              delivery: {
                                ...values.delivery,
                                supportLocationCity: e,
                                supportLocationName: ""
                              }
                            }));
                          }}
                          options={cleanSupportLocationCities}
                          label={Locale.fields.address.city}
                          error={
                            formik.touched.delivery?.supportLocationCity &&
                            formik.errors.delivery?.supportLocationCity
                          }
                          autocomplete
                          disabled={formik.isSubmitting}
                        />
                        <p className="support-location">
                          {
                            Locale.pages.newMaterial.address
                              .chooseSupportLocation
                          }
                        </p>
                        <Dropdown
                          name="delivery.supportLocationName"
                          value={formik.values.delivery.supportLocationName}
                          onChange={e => {
                            formik.setValues((values: any) => ({
                              ...values,
                              delivery: {
                                ...values.delivery,
                                supportLocationName: e
                              }
                            }));
                          }}
                          options={cleanSupportLocationNames}
                          label={Locale.fields.address.supportLocation}
                          error={
                            formik.touched.delivery?.supportLocationName &&
                            formik.errors.delivery?.supportLocationName
                          }
                          disabled={formik.isSubmitting}
                        />
                      </div>
                    )}
                    {shippingOptions?.map(option => (
                      <div className="col-12" key={option.value}>
                        <Select
                          type="radio"
                          id={`deliver.shippingMethod_${option.value}`}
                          name="delivery.shippingMethod"
                          label={option.label}
                          detail={option.detail}
                          value={option.value}
                          checked={
                            String(formik.values.delivery.shippingMethod) ===
                            String(option.value)
                          }
                          onChange={e => {
                            const { value } = e.currentTarget;
                            const shipping = shippingOptions.find(
                              item => item.value === value
                            );
                            formik.setValues((values: any) => ({
                              ...values,
                              delivery: {
                                ...values.delivery,
                                shippingMethod: value
                              },
                              summary: {
                                ...values.summary,
                                shipping: shipping?.price
                              }
                            }));
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </WrapperForm>
              {(formik.values.delivery.shippingMethod === "2" ||
                formik.values.delivery.shippingMethod === "3" ||
                (isSponsored(formik) &&
                  formik.values.delivery.shippingMethod)) && (
                <WrapperForm full>
                  <h4 className="body-2 color--brand-primary">
                    {formik.values.delivery.shippingMethod === "1" &&
                    isSponsored(formik)
                      ? Locale.pages.newMaterial.address
                          .standardAddressDescription
                      : Locale.pages.newMaterial.address.deliveryDestination}
                  </h4>
                  <div className="container-form margin--t-md">
                    <div className="row">
                      <div className="col-12">
                        <Input
                          type="tel"
                          name="delivery.zipcode"
                          value={MaskHelper.cep(formik.values.delivery.zipcode)}
                          onChange={e => {
                            formik.setValues((values: any) => ({
                              ...values,
                              delivery: {
                                ...values.delivery,
                                zipcode: e.currentTarget.value
                              }
                            }));
                            if (
                              MaskHelper.removeMask(e.currentTarget.value)
                                .length === 8
                            ) {
                              handleZipCodeChange(
                                MaskHelper.removeMask(e.currentTarget.value)
                              );
                              inputDeliveryNumber.current?.focus();
                            }
                          }}
                          error={
                            formik.touched.delivery?.zipcode &&
                            formik.errors.delivery?.zipcode
                          }
                          onBlur={formik.handleBlur}
                          label={Locale.fields.address.zipcode}
                          search
                          maxLength={9}
                          required
                        />
                      </div>

                      <div className="col-12">
                        <Select
                          id="delivery.usePostalBox"
                          name="delivery.usePostalBox"
                          checked={formik.values.delivery.usePostalBox}
                          label={Locale.fields.address.useMailbox}
                          onChange={e => {
                            formik.setValues((values: any) => ({
                              ...values,
                              delivery: {
                                ...values.delivery,
                                usePostalBox: e.currentTarget.checked
                              }
                            }));
                          }}
                          type="checkbox"
                        />
                      </div>
                      {!formik.values.delivery.usePostalBox ? (
                        <>
                          <div className="col-12 col-md-9">
                            <Input
                              type="text"
                              label={Locale.fields.address.street}
                              name="delivery.address"
                              value={formik.values.delivery.address}
                              onChange={e => {
                                formik.setValues((values: any) => ({
                                  ...values,
                                  delivery: {
                                    ...values.delivery,
                                    address: e.currentTarget.value
                                  }
                                }));
                              }}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.delivery?.address &&
                                formik.errors.delivery?.address
                              }
                              required
                            />
                          </div>
                          <div className="col-12 col-md-3">
                            <Input
                              ref={inputDeliveryNumber}
                              type="text"
                              name="delivery.number"
                              value={formik.values.delivery.number}
                              onChange={e => {
                                formik.setValues((values: any) => ({
                                  ...values,
                                  delivery: {
                                    ...values.delivery,
                                    number: e.currentTarget.value
                                  }
                                }));
                              }}
                              label={Locale.fields.address.number}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.delivery?.number &&
                                formik.errors.delivery?.number
                              }
                              required
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <Input
                              type="text"
                              name="delivery.complement"
                              value={formik.values.delivery.complement}
                              onChange={e => {
                                formik.setValues((values: any) => ({
                                  ...values,
                                  delivery: {
                                    ...values.delivery,
                                    complement: e.currentTarget.value
                                  }
                                }));
                              }}
                              label={Locale.fields.address.complement}
                              error={
                                formik.touched.delivery?.complement &&
                                formik.errors.delivery?.complement
                              }
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <Input
                              type="text"
                              name="delivery.neighborhood"
                              value={formik.values.delivery.neighborhood}
                              onChange={e => {
                                formik.setValues((values: any) => ({
                                  ...values,
                                  delivery: {
                                    ...values.delivery,
                                    neighborhood: e.currentTarget.value
                                  }
                                }));
                              }}
                              label={Locale.fields.address.neighborhood}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.delivery?.neighborhood &&
                                formik.errors.delivery?.neighborhood
                              }
                              required
                            />
                          </div>
                        </>
                      ) : (
                        <div className="col-12">
                          <Input
                            type="text"
                            name="delivery.postalBox"
                            value={formik.values.delivery.postalBox}
                            onChange={e => {
                              formik.setValues((values: any) => ({
                                ...values,
                                delivery: {
                                  ...values.delivery,
                                  postalBox: e.currentTarget.value
                                }
                              }));
                            }}
                            label={Locale.fields.address.mailbox}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.delivery?.postalBox &&
                              formik.errors.delivery?.postalBox
                            }
                            required
                          />
                        </div>
                      )}
                      <div className="col-12 col-md-6">
                        <Input
                          type="text"
                          name="delivery.city"
                          value={formik.values.delivery.city}
                          onChange={e => {
                            formik.setValues((values: any) => ({
                              ...values,
                              delivery: {
                                ...values.delivery,
                                city: e.currentTarget.value
                              }
                            }));
                          }}
                          label={Locale.fields.address.city}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.delivery?.city &&
                            formik.errors.delivery?.city
                          }
                          required
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Dropdown
                          name="delivery.state"
                          value={formik.values.delivery.state}
                          onChange={e => {
                            formik.setValues((values: any) => ({
                              ...values,
                              delivery: {
                                ...values.delivery,
                                state: e
                              }
                            }));
                          }}
                          options={ufMock}
                          label={Locale.fields.address.state}
                          autocomplete
                          error={
                            formik.touched.delivery?.state &&
                            formik.errors.delivery?.state
                          }
                          dropup
                          disabled={formik.isSubmitting}
                        />
                      </div>
                    </div>
                  </div>
                </WrapperForm>
              )}
            </fieldset>
          </Template>
          <Summary formik={formik}>
            <div className="col-12 col-md-8">
              <Button
                type="submit"
                title={isSponsored(formik) ? "Concluir" : "Próxima etapa"}
                fullWidth
                isLoading={formik.isSubmitting}
              />
            </div>
          </Summary>
        </form>
      </NewMaterialContext.Provider>
    </>
  );
};

export default Address;
