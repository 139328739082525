import { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Service from "services";
import { ErrorHelper } from "helpers";
import notify from "components/Toast";

import { OnboardingData, CnpjData } from "services/onboarding";
import LocalStorageService from "services/localstorage";
import {
  UserProps,
  UserClientDataProps,
  LoginProps,
  AuthContextProps,
  AuthProviderProps
} from "./types";

export const AuthContext = createContext({} as AuthContextProps);

export const AuthProvider = (props: AuthProviderProps) => {
  const { children } = props;
  const [user, setUser] = useState<UserProps | null>(null);

  const [userClientData, setUserClientData] =
    useState<UserClientDataProps | null>(null);
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [verified, setVerified] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const recoveredUser = LocalStorageService.getUser();

  useEffect(() => {
    if (recoveredUser) {
      setAuthenticated(true);
      setVerified(recoveredUser?.status === 2);
      setUser(recoveredUser);
    } else {
      setAuthenticated(false);
      setVerified(false);
    }
  }, []);

  const login = (data: LoginProps) =>
    Service.login(data)
      .then(data => {
        setAuthenticated(true);
        LocalStorageService.setAccessToken(data.access);
        LocalStorageService.setRefreshToken(data.refresh);
        setLoading(true);
        navigate("/");
      })
      .catch(e => {
        setAuthenticated(false);
        setVerified(false);
        if (e.response.status === 301) {
          window.location.href = e.response.data.redirect_uri;
        } else {
          ErrorHelper.notifyError(e);
        }
      });

  const logout = () => {
    cleanStorage()
      .then(() => {
        setUser(null);
        setAuthenticated(false);
        setVerified(false);
        navigate("/");
      })
      .catch(() => {
        ErrorHelper.notifyError({
          response: { data: "Erro ao tentar deletar os Cookies" }
        });
      });
  };

  const getUserCurrent = () => {
    if (authenticated) {
      Service.getUserCurrent().then((data: UserProps) => {
        setVerified(data?.status === 2);
        LocalStorageService.setUser(data);
        setUser(data);

        Service.getUserRedirect().then((data: any) => {
          if (data.redirect_uri) {
            window.location.href = data.redirect_uri;
          }
        });
      });
      // .catch(e => {
      // TODO forcar ir para pag login - quando nao houver mais erro
      // setAuthenticated(false);
      // });
    }
  };

  const getUserClientData = () => {
    if (user) {
      Service.getUserClient(user.id)
        .then(data => setUserClientData(data))
        .catch(() => {
          ErrorHelper.notifyError({
            response: { data: "Erro ao tentar obter os dados do Cliente" }
          });
        });
    }
  };

  const isAuthenticated = () => authenticated;

  const isVerified = () => true; // TODO: retomar verificação quando fluxo de consultor estiver implementado

  const createUser = (onboardingData: OnboardingData) =>
    Service.registerOnboarding(onboardingData)
      .then((response: any) => {
        const { user, password } = onboardingData;
        const { email } = user;
        login({ email, password }).then(() => {
          // TODO: Retomar quando verificação voltar a ser obrigatória
          // navigate("/register/verification", {
          //   state: { onboardingData }
          // });
          const cnpjData: CnpjData = {
            id: response.client?.id
          };
          Service.registerCnpjInfo(cnpjData);
        });
        // TODO: Retomar quando verificação voltar a ser obrigatória
        // notify(
        //   "Para finalizar o seu cadastro, clique no link enviado por e-mail!"
        // );
      })
      .catch(error => {
        ErrorHelper.notifyError(error);
      });

  const cleanStorage = () =>
    new Promise<void>((resolve, reject) => {
      try {
        LocalStorageService.removeAll();
        resolve();
      } catch (e) {
        reject();
      }
    });

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        user,
        userClientData,
        login,
        logout,
        getUserCurrent,
        getUserClientData,
        isAuthenticated,
        isVerified,
        createUser,
        cleanStorage,
        loading
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
