import { useEffect } from "react";
import { useLocation } from "react-router-dom";

interface ScrollToTopProps {
  children: React.ReactNode;
}

const ScrollToTop = (props: ScrollToTopProps) => {
  const location = useLocation();
  const { children } = props;
  const { pathname } = location;
  useEffect(() => {
    document.body.scrollTo({ top: 0 });
  }, [pathname]);

  return <>{children}</>;
};

export default ScrollToTop;
