import { WrapperForm, Select, TextInfo, Input, Dropdown } from "components";
import { AuthContext } from "contexts/auth";

import { MaskHelper } from "helpers";
import Locale from "locale";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { BillingDataProps, BillingProps } from "./type";

const Billing = (props: BillingProps): JSX.Element => {
  const { user, getUserClientData, userClientData } = useContext(AuthContext);
  const { formik, autofill } = props;
  useEffect(() => {
    getUserClientData();
  }, [user]);

  const dataInitial: BillingDataProps = {
    document: "",
    name: "",
    email: "",
    stateSubscription: "",
    ruralProducerSubscription: "",
    billingType: "",
    phoneNumber: ""
  };

  const [pfBillingData, setPfBillingData] = useState(dataInitial);
  const [pjBillingData, setPjBillingData] = useState(dataInitial);

  const handleChangeBillingType = (e: ChangeEvent<HTMLInputElement>) => {
    switch (formik.values.billing.billingType) {
      case "pf":
        setPfBillingData(formik.values.billing);
        formik.setValues((values: any) => ({
          ...values,
          billing: {
            ...values.billing,
            ...pfBillingData,
            billingType: e.currentTarget?.value
          }
        }));
        break;
      case "pj":
        setPjBillingData(formik.values.billing);
        formik.setValues((values: any) => ({
          ...values,
          billing: {
            ...values.billing,
            ...pjBillingData,
            billingType: e.currentTarget?.value
          }
        }));
        break;
      default:
        formik.setValues((values: any) => ({
          ...values,
          billing: {
            ...values.billing,
            ...dataInitial,
            billingType: e.currentTarget?.value
          }
        }));
    }
  };

  const getBillingData = () => ({
    ...dataInitial,
    document: userClientData?.cpf || userClientData?.cnpj,
    name: user?.name,
    email: user?.email,
    phoneNumber: user?.mobile
  });

  const handleUseBillingDataChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;
    let data = dataInitial;

    if (checked) {
      data = getBillingData();
    }

    formik.setValues((values: any) => ({
      ...values,
      billing: {
        ...values.billing,
        useBillingData: e.currentTarget.checked,
        ...data
      }
    }));
  };
  const [isLegalEntity, setIsLegalEntity] = useState(false);
  useEffect(() => {
    if (MaskHelper.removeMask(formik.values.billing.document).length === 14) {
      setIsLegalEntity(true);
      formik.values.billing.billingType = "pj";
    } else if (
      MaskHelper.removeMask(formik.values.billing.document).length === 11
    ) {
      setIsLegalEntity(false);
      formik.values.billing.billingType = "pf";
    }
  }, [formik.values.billing.document]);

  return (
    <WrapperForm full className="margin--t-md">
      <h4 className="body-2 color--brand-primary">
        {Locale.pages.newMaterial.payment.billing}
      </h4>
      <div className="container-form margin--t-md">
        <div className="row">
          <div className="col-12">
            {autofill && (
              <Select
                type="checkbox"
                name="useBillingData"
                id="useBillingData"
                label={Locale.fields.payment.useBillingData}
                checked={formik.values.billing.useBillingData}
                onChange={handleUseBillingDataChange}
              />
            )}
            {formik.values.billing.useBillingData && (
              <>
                <div className="container-details-billing padding--t-md background-color--bw-gray-5">
                  <div className="row">
                    {isLegalEntity ? (
                      <>
                        <div className="col-12 padding--x-md">
                          <TextInfo
                            title={Locale.fields.user.ownersCNPJ}
                            description={MaskHelper.cnpj(
                              formik.values.billing.document
                            )}
                            className="padding--x-md"
                          />
                        </div>
                        <div className="col-12">
                          <TextInfo
                            title={Locale.fields.user.companyName}
                            description={formik.values.billing.name}
                            className="padding--x-md"
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-12 padding--x-md">
                          <TextInfo
                            title={Locale.fields.user.CPF}
                            description={MaskHelper.cpf(
                              formik.values.billing.document
                            )}
                            className="padding--x-md"
                          />
                        </div>
                        <div className="col-12">
                          <TextInfo
                            title="Nome completo"
                            description={formik.values.billing.name}
                            className="padding--x-md"
                          />
                        </div>
                      </>
                    )}

                    <div className="col-12">
                      <TextInfo
                        title={Locale.fields.user.invoiceEmail}
                        description={formik.values.billing.email}
                        className="padding--x-md"
                      />
                    </div>
                    <div className="col-12">
                      <TextInfo
                        title={Locale.fields.user.mobilePhone}
                        description={MaskHelper.phone(
                          formik.values.billing.phoneNumber
                        )}
                        className="padding--x-md"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="margin--t-md">
              {!formik.values.billing.useBillingData && (
                <>
                  <div className="margin--t-md">
                    <div className="row">
                      <div className="col-12">
                        <Select
                          type="radio"
                          name="billingType"
                          id="billingPF"
                          label={Locale.fields.user.pf}
                          value="pf"
                          checked={formik.values.billing.billingType === "pf"}
                          onChange={e => {
                            handleChangeBillingType(e);
                          }}
                          error={
                            formik?.touched?.billing?.billingType &&
                            formik?.errors?.billing?.billingType
                          }
                        />
                      </div>
                      <div className="col-12">
                        <Select
                          type="radio"
                          name="billingType"
                          id="billingPJ"
                          label={Locale.fields.user.pj}
                          value="pj"
                          checked={formik.values.billing.billingType === "pj"}
                          onChange={e => {
                            handleChangeBillingType(e);
                          }}
                          error={
                            formik?.touched?.billing?.billingType &&
                            formik?.errors?.billing?.billingType
                          }
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {formik.values.billing.billingType && (
                <div className="margin--t-xs">
                  <div className="row">
                    {!formik.values.billing.useBillingData && (
                      <>
                        <div className="col-12">
                          <Input
                            type="tel"
                            name="billing.document"
                            label={
                              formik.values.billing.billingType === "pj"
                                ? Locale.fields.user.ownersCNPJ
                                : Locale.fields.user.CPF
                            }
                            value={
                              formik.values.billing.billingType === "pj"
                                ? MaskHelper.cnpj(
                                    formik.values.billing.document
                                  )
                                : MaskHelper.cpf(formik.values.billing.document)
                            }
                            onChange={e => {
                              formik.setValues((values: any) => ({
                                ...values,
                                billing: {
                                  ...values.billing,
                                  document: e.currentTarget.value
                                }
                              }));
                            }}
                            onBlur={formik.handleBlur}
                            error={
                              formik?.touched?.billing?.document &&
                              formik?.errors?.billing?.document
                            }
                            required
                          />
                        </div>
                        <div className="col-12">
                          <Input
                            type="text"
                            name="billing.name"
                            label={
                              formik.values.billing.billingType === "pj"
                                ? Locale.fields.user.companyName
                                : Locale.fields.user.fullName
                            }
                            value={formik.values.billing.name}
                            onChange={e =>
                              formik.setValues((values: any) => ({
                                ...values,
                                billing: {
                                  ...values.billing,
                                  name: e.currentTarget.value
                                }
                              }))
                            }
                            onBlur={formik.handleBlur}
                            error={
                              formik?.touched?.billing?.name &&
                              formik?.errors?.billing?.name
                            }
                            required
                          />
                        </div>
                        <div className="col-12">
                          <Input
                            type="email"
                            name="billing.email"
                            label={Locale.fields.user.invoiceEmail}
                            value={formik.values.billing.email}
                            onChange={e =>
                              formik.setValues((values: any) => ({
                                ...values,
                                billing: {
                                  ...values.billing,
                                  email: e.currentTarget.value
                                }
                              }))
                            }
                            onBlur={formik.handleBlur}
                            error={
                              formik?.touched?.billing?.email &&
                              formik?.errors?.billing?.email
                            }
                            required
                          />
                        </div>
                        <div className="col-12">
                          <Input
                            type="tel"
                            name="billing.phoneNumber"
                            label={Locale.fields.user.mobilePhone}
                            value={MaskHelper.phone(
                              formik.values.billing.phoneNumber
                            )}
                            onChange={e =>
                              formik.setValues((values: any) => ({
                                ...values,
                                billing: {
                                  ...values.billing,
                                  phoneNumber: e.currentTarget.value
                                }
                              }))
                            }
                            onBlur={formik.handleBlur}
                            error={
                              formik?.touched?.billing?.phoneNumber &&
                              formik?.errors?.billing?.phoneNumber
                            }
                          />
                        </div>
                      </>
                    )}
                    <div className="col-12">
                      <Input
                        type="text"
                        name="ruralProducerSubscription"
                        label={Locale.fields.user.ruralProducerSubscription}
                        value={formik.values.billing.ruralProducerSubscription}
                        onChange={e =>
                          formik.setValues((values: any) => ({
                            ...values,
                            billing: {
                              ...values.billing,
                              ruralProducerSubscription: e.currentTarget.value
                            }
                          }))
                        }
                        onBlur={formik.handleBlur}
                        error={
                          formik?.touched?.billing?.ruralProducerSubscription &&
                          formik?.errors?.billing?.ruralProducerSubscription
                        }
                      />
                    </div>
                    {formik.values.billing.billingType === "pj" && (
                      <>
                        <div className="col-12">
                          <Input
                            type="text"
                            name="stateSubscription"
                            label={Locale.fields.user.stateSubscription}
                            value={formik.values.billing.stateSubscription}
                            onChange={e =>
                              formik.setValues((values: any) => ({
                                ...values,
                                billing: {
                                  ...values.billing,
                                  stateSubscription: e.currentTarget.value
                                }
                              }))
                            }
                            onBlur={formik.handleBlur}
                            error={
                              formik?.touched?.billing?.stateSubscription &&
                              formik?.errors?.billing?.stateSubscription
                            }
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </WrapperForm>
  );
};

export default Billing;
