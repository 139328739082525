const MaskHelper = {
  cpfCnpj(cpfCnpj: string) {
    if (!cpfCnpj) {
      return cpfCnpj;
    }
    let value = cpfCnpj.replace(/\D/g, "").slice(0, 14);

    if (value.length <= 11) {
      value = value.replace(/(\d{3})(\d)/, "$1.$2");
      value = value.replace(/(\d{3})(\d)/, "$1.$2");
      value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    } else {
      value = value.replace(/^(\d{2})(\d)/, "$1.$2");
      value = value.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
      value = value.replace(/\.(\d{3})(\d)/, ".$1/$2");
      value = value.replace(/(\d{4})(\d)/, "$1-$2");
    }
    return value;
  },

  removeMask(data: string) {
    return data.replace(/\D/g, "");
  },

  cnpj(cnpj: string) {
    if (!cnpj) {
      return cnpj;
    }

    let value = cnpj.replace(/\D/g, "").slice(0, 14);
    value = value.replace(/^(\d{2})(\d)/, "$1.$2");
    value = value.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
    value = value.replace(/\.(\d{3})(\d)/, ".$1/$2");
    value = value.replace(/(\d{4})(\d)/, "$1-$2");
    return value;
  },

  cpf(cpf: string) {
    if (!cpf) {
      return cpf;
    }

    let value = cpf.replace(/\D/g, "").slice(0, 14);
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

    return value;
  },

  cep(cep: string) {
    if (!cep) {
      return cep;
    }
    return cep.replace(/(\d{5})(\d)/, "$1-$2");
  },

  phone(phone: string) {
    if (!phone) {
      return phone;
    }
    let value = phone.replace(/\D/g, ""); // Remove tudo o que não é dígito
    value = value.replace(/^(\d{2})(\d)/g, "($1) $2"); // Coloca parênteses em volta dos dois primeiros dígitos
    value = value.replace(/(\d)(\d{4})$/, "$1-$2"); // Coloca hífen entre o quarto e o quinto dígitos
    return value;
  }
};

export default MaskHelper;
