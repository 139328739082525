import clsx from "clsx";

import CheckboxProps from "./types";

import "./styles.scss";

const Checkbox = (props: CheckboxProps): JSX.Element => {
  const { id, label, name, value, disabled, error, onChange, onBlur, ...args } =
    props;
  return (
    <div
      className={clsx("container-checkbox", {
        error
      })}
    >
      <input
        className="styled-checkbox"
        type="checkbox"
        id={id}
        name={name}
        value={value}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        {...args}
      />
      <label htmlFor={id} className="body-2">
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
