import { toast } from "react-toastify";
import { close } from "assets/icons/index";
import { Button, Image } from "..";

import "./styles.scss";

interface ToastWithButtonProps {
  closeToast?: () => void;
  message: string;
  buttonText: string;
  buttonOnClick: () => void;
}

interface GenericToastProps {
  message: string;
}

const defaultProps = {
  closeToast: undefined
};

const ToastWithButton = ({
  closeToast,
  message,
  buttonText,
  buttonOnClick
}: ToastWithButtonProps) => (
  <div className="flex-justify--between flex-items--center">
    <div className="flex--1 margin--r-sm">{message}</div>
    <Button
      onClick={() => {
        buttonOnClick();
        closeToast && closeToast();
      }}
    >
      {buttonText}
    </Button>
  </div>
);

ToastWithButton.defaultProps = defaultProps;

const GenericToast = ({ message }: GenericToastProps) => (
  <div className="flex--row align-items-start my-1">
    <div>{message}</div>
  </div>
);

export default function notify(
  message: string,
  buttonText?: string,
  buttonOnClick?: () => void
) {
  if (!!buttonText && !!buttonOnClick) {
    toast(
      <ToastWithButton
        message={message}
        buttonOnClick={buttonOnClick}
        buttonText={buttonText}
      />,
      { autoClose: 15000 }
    );
  } else {
    toast(<GenericToast message={message} />, { autoClose: 15000 });
  }
}

function clearAllToasts() {
  toast.dismiss();
  toast.clearWaitingQueue();
}

export { clearAllToasts };
