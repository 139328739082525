import Locale from "locale";
import { Image } from "components";
import { checkPrimary, closeRed } from "assets/icons/index";
import { PasswordHelper } from "helpers";
import { ChecklistItemProps, ChecklistProps } from "./types";

import "./styles.scss";

const ChecklistItem = (props: ChecklistItemProps) => {
  const { label, isChecked } = props;

  return (
    <div className="body-2 d-flex align-items-center">
      <Image
        className="margin--r-sm"
        src={isChecked ? checkPrimary : closeRed}
      />
      <p className={!isChecked ? "checked" : ""}>{label}</p>
    </div>
  );
};

const PasswordValidationChecklist = (props: ChecklistProps) => {
  const { textValue } = props;

  return (
    <div className="stack--md">
      <ChecklistItem
        label={Locale.pages.registerPassword.securityLength}
        isChecked={PasswordHelper.validateLength(textValue)}
      />
      <ChecklistItem
        label={Locale.pages.registerPassword.securityUppercase}
        isChecked={PasswordHelper.validateUppercase(textValue)}
      />
      <ChecklistItem
        label={Locale.pages.registerPassword.securityNumber}
        isChecked={PasswordHelper.validateDigit(textValue)}
      />
      <ChecklistItem
        label={Locale.pages.registerPassword.securitySpecial}
        isChecked={PasswordHelper.validateSpecialChar(textValue)}
      />
    </div>
  );
};

export default PasswordValidationChecklist;
