import { get } from "./axios";

const VerifyAdressUrls = {
  verifyCity: (cityName: string, uf: string) =>
    `verify_address/city/${uf}/${cityName}`
};

const VerifyAdressService = {
  verifyCity(cityName: string, uf: string) {
    return get(VerifyAdressUrls.verifyCity(cityName, uf));
  }
};

export default VerifyAdressService;
