import { createContext } from "react";

const initialContextValues = {
  analysis: {
    animalCount: "",
    association: {
      value: undefined,
      sponsor_pays: undefined
    },
    tankCount: "",
    analyses: {
      cbt: false,
      atb: false
    }
  },
  delivery: {
    zipcode: "",
    postalBox: "",
    address: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "",
    state: "",
    shippingMethod: "",
    supportLocation: "",
    usePostalBox: false,
    supportLocationCity: "",
    supportLocationName: "",
    showShippingMethod: false
  },
  billing: {
    formPayment: "",
    billingType: "",
    useBillingData: false,
    useDeliveryAsBillingAddress: false,
    // CARTAO DE CREDITO
    cardNumber: "",
    cardName: "",
    cardExpiring: "",
    cardCVC: "",
    parcelas: "",
    // FATURAMENTO
    document: "",
    name: "",
    email: "",
    stateSubscription: "",
    ruralProducerSubscription: "",
    phoneNumber: "",
    // ENDERECO COBRANCA
    zipcode: "",
    address: "",
    number: "",
    neighborhood: "",
    complement: "",
    city: "",
    state: "",
    countryCode: "BR"
  },
  financialResponsible: {
    name: "",
    email: "",
    mobilePhone: ""
  },
  summary: {
    items: [],
    shipping: 0,
    amount: 0
  }
};

const NewMaterialContext = createContext({
  analysis: {
    animalCount: "",
    association: {
      value: undefined,
      sponsor_pays: undefined
    },
    tankCount: "",
    analyses: {
      cbt: false,
      atb: false
    }
  },
  delivery: {
    zipcode: "",
    postalBox: "",
    address: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "",
    state: "",
    shippingMethod: "",
    supportLocation: "",
    usePostalBox: false,
    supportLocationCity: "",
    supportLocationName: "",
    showShippingMethod: false
  },
  billing: {
    formPayment: "",
    billingType: "",
    useBillingData: false,
    useDeliveryAsBillingAddress: false,
    // CARTAO DE CREDITO
    cardNumber: "",
    cardName: "",
    cardExpiring: "",
    cardCVC: "",
    parcelas: "",
    // FATURAMENTO
    document: "",
    name: "",
    email: "",
    stateSubscription: "",
    ruralProducerSubscription: "",
    phoneNumber: "",
    // ENDERECO COBRANCA
    zipcode: "",
    address: "",
    number: "",
    neighborhood: "",
    complement: "",
    city: "",
    state: "",
    countryCode: "BR"
  },
  financialResponsible: {
    name: "",
    email: "",
    mobilePhone: ""
  },
  summary: {
    items: [],
    shipping: 0,
    amount: 0
  }
});

export { initialContextValues };
export default NewMaterialContext;
