import clsx from "clsx";
import ChipProps from "./types";
import "./styles.scss";

const Chip = (props: ChipProps): JSX.Element => {
  const { label, variant, className, ...rest }: ChipProps = props;

  const classes = clsx("chip", `chip-${variant}`, { className });

  return (
    <span className={classes} {...rest}>
      {label}
    </span>
  );
};

Chip.defaultProps = {
  variant: "neutral"
};

export default Chip;
