import {
  Button,
  Dropdown,
  HeaderPage,
  StickyFooter,
  Template,
  WrapperForm
} from "components";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import Locale from "locale";
import { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import isEqual from "lodash/isEqual";
import notify from "components/Toast";
import Service from "services";
import { getAssociationObjectByValue } from "utils/functions";
import { FieldStrings } from "locale/LocaleStrings";
import { AssociationType } from "./types";
import NewMaterialContext, {
  initialContextValues
} from "../NewMaterialContext";

const Association = () => {
  const navigate = useNavigate();

  const contextData = useContext(NewMaterialContext);
  const [associationOptions, setAssociationOptions] = useState<
    AssociationType[]
  >([]);
  const validationSchema = Yup.object().shape({
    analysis: Yup.object().shape({
      association: Yup.object().shape({
        value: Yup.string().required(Locale.errors.required),
        sponsor_pays: Yup.string()
      })
    })
  });

  const formik = useFormik({
    initialValues: contextData,
    validationSchema,
    onSubmit: data => {
      contextData.analysis = data.analysis;
      navigate("/material/new/address");
    }
  });

  useEffect(() => {
    let notifyMessage = "";
    const labels: string[] = [];
    if (formik.errors?.analysis) {
      (
        Object.keys(
          formik.errors.analysis
        ) as (keyof FieldStrings["analysis"])[]
      ).forEach(key => {
        if (Locale.fields.analysis[key]) {
          labels.push(Locale.fields.analysis[key]);
        }
      });
    }

    notifyMessage = labels.join(", ");
    if (notifyMessage) {
      notify(`Existe um erro nos seguintes campos: ${notifyMessage}`);
    }
  }, [formik.submitCount]);

  useEffect(() => {
    Service.listAssociationOptions().then(response => {
      const options: AssociationType[] = [];
      response.forEach((item: any) => {
        if (item.name === "Sem Convênio") {
          options.unshift({
            label: item.name,
            value: item.rowid,
            sponsor_pays: item.sponsor_pays
          });
        } else {
          options.push({
            label: item.name,
            value: item.rowid,
            sponsor_pays: item.sponsor_pays
          });
        }
      });
      setAssociationOptions(options);
    });
  }, []);

  useEffect(() => {
    const previousPagesAreEmpty =
      isEqual(
        contextData.analysis.animalCount,
        initialContextValues.analysis.animalCount
      ) &&
      isEqual(
        contextData.analysis.tankCount,
        initialContextValues.analysis.tankCount
      ) &&
      isEqual(
        contextData.analysis.analyses,
        initialContextValues.analysis.analyses
      );

    if (previousPagesAreEmpty) {
      navigate("/");
    }
  }, []);

  return (
    <NewMaterialContext.Provider value={contextData}>
      <Template
        formik={formik}
        cart
        notification
        className="margin-y template-association"
      >
        <HeaderPage
          title={Locale.pages.newMaterial.association.title}
          description={Locale.pages.newMaterial.association.description}
          goBack={() => {
            contextData.analysis = formik?.values?.analysis;
            navigate("/material/new/analysis");
          }}
        />
        <form onSubmit={formik.handleSubmit} className="width--full">
          <fieldset disabled={formik.isSubmitting}>
            <WrapperForm full className="stack--md width--full ">
              <Dropdown
                className="dropdown-wrapper dropdown-association"
                id="association"
                name="association"
                label={Locale.actions.select}
                options={associationOptions}
                value={formik.values.analysis.association.value}
                error={formik.errors.analysis?.association?.value}
                onChange={e => {
                  const newAssociation = getAssociationObjectByValue(
                    e,
                    associationOptions
                  );
                  formik.setValues((values: any) => ({
                    ...values,
                    analysis: {
                      ...values.analysis,
                      association: {
                        value: newAssociation.value,
                        sponsor_pays: newAssociation.sponsor_pays
                      }
                    }
                  }));
                }}
                autocomplete
                disabled={formik.isSubmitting}
              />
            </WrapperForm>
          </fieldset>
          <StickyFooter>
            <div className="flex-justify--center">
              <div className="col-12 col-md-8">
                <Button
                  title={Locale.actions.nextStep}
                  fullWidth
                  type="submit"
                />
              </div>
            </div>
          </StickyFooter>
        </form>
      </Template>
    </NewMaterialContext.Provider>
  );
};

export default Association;
