import { WrapperForm, Select } from "components";
import Locale from "locale";

const FormPayment = (props: any): JSX.Element => {
  const { formik } = props;

  const handleChangeFormPayment = (e: any) => {
    formik.setValues((values: any) => ({
      ...values,
      billing: {
        ...values.billing,
        formPayment: e.currentTarget?.value,
        cardNumber: "",
        cardName: "",
        cardExpiring: "",
        cardCVC: "",
        parcelas: ""
      }
    }));
  };

  return (
    <WrapperForm full>
      <h4 className="body-2 color--brand-primary">
        {Locale.pages.newMaterial.payment.formPayment} &#8727;
      </h4>
      <div className="container-form margin--t-md">
        <div className="row">
          <div className="col-12">
            <Select
              type="radio"
              name="billing.formPayment"
              id="formCredt"
              label={Locale.fields.payment.creditCard}
              value="credit_card"
              checked={formik?.values?.billing?.formPayment === "credit_card"}
              onChange={e => {
                handleChangeFormPayment(e);
              }}
              detail={Locale.fields.payment.oneLumpSum}
              error={
                formik?.touched?.billing?.formPayment &&
                formik?.errors?.billing?.formPayment
              }
            />
          </div>
          <div className="col-12">
            <Select
              type="radio"
              name="billing.formPayment"
              id="formBoleto"
              label={Locale.fields.payment.bankSlip}
              value="boleto"
              checked={formik?.values?.billing?.formPayment === "boleto"}
              onChange={e => {
                handleChangeFormPayment(e);
              }}
              detail={Locale.fields.payment.oneLumpSum}
              error={
                formik.touched?.billing?.formPayment &&
                formik?.errors?.billing?.formPayment
              }
            />
          </div>
        </div>
      </div>
    </WrapperForm>
  );
};

export default FormPayment;
